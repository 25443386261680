import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class EpicsBarChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this
    const statusColorMap = {
      done: controller.darkMode
        ? Highcharts.color(controller.tailwindColors.teal[500]).setOpacity(0.5).get('rgba')
        : controller.tailwindColors.teal[300],
      in_progress: controller.darkMode
        ? Highcharts.color(controller.tailwindColors.blue[500]).setOpacity(0.5).get('rgba')
        : controller.tailwindColors.blue[300],
      not_started: controller.darkMode
        ? Highcharts.color(controller.tailwindColors.gray[500]).setOpacity(0.5).get('rgba')
        : controller.tailwindColors.gray[300]
    }

    let total = 0
    const series = [{
      name: 'Epics',
      data: controller.parsedGraphData.data.map((data) => {
        total += data.y
        return {
          ...data,
          color: statusColorMap[data.status_key]
        }
      }),
      type: 'column',
      yAxis: 0,
      zIndex: 1,
      marker: {
        enabled: false
      },
      stacking: 'normal',
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5,
      pointPadding: 0.08,
      groupPadding: 0
    }]
    let plotLines = []
    if (total > 0) {
      series.push(
        {
          color: this.darkMode ? this.tailwindColors.gray[400] : this.tailwindColors.gray[500],
          name: 'Avg',
          type: 'spline',
          dashStyle: 'shortdash',
          marker: {
            enabled: false
          }
        }
      )
      plotLines = [{
        color: this.darkMode ? this.tailwindColors.gray[400] : this.tailwindColors.gray[500],
        width: 2,
        name: 'Avg',
        dashStyle: 'shortdash',
        value: controller.parsedGraphData.benchmark
      }]
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 3,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: controller.parsedGraphData.data.map((data) => data.category),
        title: {
          text: controller.optionsValue.x_axis_label,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        tickLength: 0,
        labels: {
          enabled: controller.parsedGraphData.data.length < 20,
          align: 'right',
          x: 0,
          y: 10,
          rotation: -60,
          steps: 1,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: {
        title: {
          text: controller.optionsValue.y_axis_label,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && total === 0) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        plotLines,
        ...maxSettings
      },
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.025,
          groupPadding: 0,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          pointPlacement: 'on',
          maxPointWidth: 30
        },
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          states: {
            hover: {
              color: controller.darkMode
                ? controller.tailwindColors.blue[900]
                : controller.tailwindColors.blue[300]
            }
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          return card({
            date: this.point.category,
            breakDownValues: this.points.map((p) => {
              let secondary = ''
              if (this.point.options.secondary_value) {
                secondary = ` <span class="text-gray-500 dark:text-gray-400">(${this.point.options.secondary_value})</span>`
              }
              return breakDownValue({
                name: controller.optionsValue.tooltip_label,
                style: `background-color: ${p.color};`,
                value: [p.y.toFixed(1), controller.optionsValue.y_axis_unit, secondary].join(''),
                type: 'box'
              })
            })
          })
        }
      }
    }
  }
}
