import { Controller } from '@hotwired/stimulus'

export default class AccountController extends Controller {
  static targets = ['deleteAccountModal']

  showDeleteAccountModal () {
    this.deleteAccountModalTarget.classList.remove('hidden')
  }

  hideDeleteAccountModal () {
    this.deleteAccountModalTarget.classList.add('hidden')
  }
}
