import BaseGraph from './base_graph_controller'
import { card, primaryValue } from '../helpers/tooltip_helper'

export default class PortfolioOverviewTrendChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    // get the max y value
    let total = controller.parsedGraphData.graph_data.reduce((acc, point) => acc + point.y, 0)
    total += controller.parsedGraphData.portfolio_median

    const plotLines = [
      {
        name: 'Avg',
        color: controller.tailwindColors.blue[500],
        value: controller.parsedGraphData.portfolio_median === 0 ? null : controller.parsedGraphData.portfolio_median,
        width: 2,
        dashStyle: 'Dash'
      }
    ]

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 0,
        spacingBottom: 0,
        height: 225
      },
      ...this.baseConfigOptions,
      xAxis: {
        title: { text: null },
        // Adding distinct categories prevents labels from disappearing when the chart is resized.
        categories: controller.parsedGraphData.graph_data.map((point) => point.org_id),
        labels: {
          useHTML: true,
          allowOverlap: true,
          autoRotation: 0,
          formatter: function () {
            const point = controller.parsedGraphData.graph_data[this.pos]
            const imageUrl = point.image_url
            const nameAbbr = point.name_abbreviation
            let imageHtml = ''
            if (imageUrl) {
              imageHtml = `<div class="hidden md:block md:h-4 md:w-4 lg:h-5 lg:w-5"><img src="${imageUrl}" class="rounded-md hidden md:block md:h-4 md:w-4 lg:h-5 lg:w-5" /></div>`
            } else if (nameAbbr) {
              imageHtml = `<div class="items-center justify-center hidden md:flex md:h-4 md:w-4 lg:h-5 lg:w-5 font-black text-[10px] text-center text-white align-middle bg-blue-500 rounded-md shrink-0">${nameAbbr}</div> `
            }
            return imageHtml
          }
        },
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        tickWidth: 0
      },
      yAxis: {
        showEmpty: true,
        title: { text: null },
        labels: {
          style: {
            color: controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[600]
          },
          formatter: function () {
            if (this.value !== 0 && total === 0) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        plotLines,
        ...maxSettings
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          minPointLength: 3,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          maxPointWidth: 100
        }
      },
      series: [
        {
          data: controller.parsedGraphData.graph_data,
          color: controller.tailwindColors.blue[200],
          states: {
            hover: {
              color: controller.tailwindColors.blue[400]
            }
          }
        }
      ],
      tooltip: {
        ...controller.baseToolTipConfig,
        outside: false,
        style: { pointerEvents: 'auto' },
        formatter: function () {
          const imageUrl = this.point.options.image_url
          const nameAbbr = this.point.options.name_abbreviation
          let imageHtml = ''
          if (imageUrl) {
            imageHtml = `<div class="h-4 w-4"><img src="${imageUrl}" class="h-4 w-4 rounded-md" /></div> `
          } else if (nameAbbr) {
            imageHtml = `<div class="flex items-center justify-center w-4 h-4 font-black text-[10px] text-center text-white align-middle bg-blue-500 rounded-md shrink-0">${nameAbbr}</div> `
          }

          const header = `<div class="flex items-center gap-2 text-xs mb-1">
            ${imageHtml}
            <h3 class="font-semibold text-gray-600 dark:text-gray-400">${this.point.options.name || 'Anonymous'}</h3>
          </div>`

          let trendCss = 'text-gray-500 bg-gray-50'
          let percentDisplay = `${this.point.options.difference_percent}%`
          if (this.point.options.difference_percent < 0) {
            if (this.point.options.flipped) {
              trendCss = 'text-teal-500 bg-teal-50'
            } else {
              trendCss = 'text-red-600 bg-red-50'
            }
          } else if (this.point.options.difference_percent > 0) {
            if (this.point.options.flipped) {
              trendCss = 'text-red-600 bg-red-50'
            } else {
              trendCss = 'text-teal-500 bg-teal-50'
            }
            percentDisplay = `+${this.point.options.difference_percent}%`
          }

          const primaryValues = [primaryValue({
            name: controller.parsedGraphData.tooltip_metric_title,
            value: `<span class="flex items-center gap-1 ml-auto">${this.point.y_display}
              <span class="px-2 py-1 font-medium rounded-md w-fit ${trendCss}">${percentDisplay}</span>
            </span>`
          })]

          const footer = `<a data-turbo-frame="_top" class="mt-3 btn-tertiary w-full" href="/companies/${this.point.options.slug}/executive_report">View report</a>`

          return card({
            primaryValues,
            header,
            footer
          })
        }
      }
    }
  }

  legendMouseOver (orgId) {
    const series = this.chart.series[0].data.find(series => series.org_id === orgId)
    series?.setState('hover')
    if (series) this.chart.tooltip.refresh(series)
  }

  legendMouseOut (orgId) {
    const series = this.chart.series[0].data.find(series => series.org_id === orgId)
    series?.setState('normal')
    this.chart.tooltip.hide()
  }
}
