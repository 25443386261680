import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'protectCodeTimeModal',
    'deleteEventModal',
    'showEventTitle',
    'eventStart',
    'eventEnd',
    'showEventTimes'
  ]

  openModal (event) {
    if (event.currentTarget.dataset.eventSource === 'suggested_code_time') {
      const startTime = event.currentTarget.dataset.eventStart
      const endTime = event.currentTarget.dataset.eventEnd

      this.protectCodeTimeModalTarget.classList.remove('hidden')
      this.eventStartTarget.value = startTime
      this.eventEndTarget.value = endTime
    } else {
      const deleteUrl = event.currentTarget.dataset.eventDeleteUrl

      this.deleteEventModalTarget.classList.remove('hidden')
      if (deleteUrl) {
        this.deleteEventModalTarget.querySelector('form').setAttribute('action', deleteUrl)
        this.deleteEventModalTarget.querySelector('form #event_delete').classList.remove('hidden')
      }
      this.showEventTitleTarget.innerHTML = event.currentTarget.dataset.eventTitle
      this.showEventTimesTarget.innerHTML = event.currentTarget.dataset.eventDisplayTime || ''
    }
  }

  closeProtectCodeTimeModal () {
    this.protectCodeTimeModalTarget.classList.add('hidden')
  }

  closeEventModal () {
    this.deleteEventModalTarget.classList.add('hidden')
    this.deleteEventModalTarget.querySelector('form').setAttribute('action', '')
    this.deleteEventModalTarget.querySelector('form #event_delete').classList.add('hidden')
  }
}
