export function configureAnnotations (controller, chartConfig) {
  chartConfig.chart.events.click = function (event) {
    if (controller.hasAnnotationsOutlet) controller.annotationsOutlet.showNewAnnotationForm(event)
  }

  chartConfig.chart.events.render = function () {
    if (controller.hasAnnotationsOutlet) setupAnnotations(controller)
  }

  chartConfig.plotOptions.series.events.click = function (event) {
    if (controller.hasAnnotationsOutlet) controller.annotationsOutlet.showNewAnnotationForm(event)
  }
  return chartConfig
}

function setupAnnotations (controller) {
  setTimeout(() => {
    controller.element.addEventListener('mouseout', (event) => {
      if (event.target.nodeName.toLowerCase() === 'path') return
      controller.annotationsOutlet.categoryMouseOut(event)
    })

    setAnnotationPoints(controller)
  }, 500)
}

function setAnnotationPoints (controller) {
  if (controller.hasAnnotationsOutlet && controller.chart.series.length) {
    const xAxis = controller.chart.series[0].xAxis
    controller.annotationsOutlet.setChartData({
      chart: controller.chart,
      chartBBox: controller.chart.plotBackground.getBBox(),
      xPoints: controller.chart.series[0].processedXData.map((value) => ({
        value,
        pixels: xAxis.toPixels(value)
      }))
    })
  }
}

export function newAnnotation (controller) {}
export function setAnnotations (controller) {}
