import { Controller } from '@hotwired/stimulus'
import theme from '../helpers/tailwind_theme'

export default class ColorPalette extends Controller {
  connect () {
    const shades = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]

    Object.keys(theme.tailwind).forEach((color) => {
      // create a row for the color
      const row = document.createElement('div')
      row.classList.add('flex', 'flex-row', 'items-center', 'justify-center', 'my-8', 'w-full', 'gap-8')
      this.element.appendChild(row)

      for (const shade of shades) {
        if (theme.tailwind[color][shade]) {
          const wrapper = document.createElement('div')
          wrapper.classList.add('flex', 'flex-col', 'items-center', 'justify-center', 'gap-1')

          const hex = theme.tailwind[color][shade]

          const colorBox = document.createElement('div')
          colorBox.classList.add('w-24', 'h-24', 'rounded-md')
          colorBox.style.backgroundColor = hex

          const bgClassText = document.createElement('div')
          bgClassText.classList.add('text-sm')
          bgClassText.textContent = `bg-${color}-${shade}`

          const hexText = document.createElement('div')
          hexText.classList.add('text-sm')
          hexText.textContent = hex

          wrapper.appendChild(bgClassText)
          wrapper.appendChild(hexText)
          wrapper.appendChild(colorBox)

          row.appendChild(wrapper)
        }
      }
    })
  }
}
