import { Controller } from '@hotwired/stimulus'
import { useIntersection, useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = [
    'modalForm',
    'autoDetectedDeployment',
    'deploymentInput',
    'unsavedText',
    'formFooter'
  ]

  connect () {
    this.originalFormString = this.formToString()
    useIntersection(this)
    useClickOutside(this)
  }

  clickOutside () {
    this.modalFormTarget.reset()
  }

  // Provided by useIntersection
  appear () {
    this.formFooterTarget.classList.toggle('hidden', true)
  }

  formToString () {
    return Array.from(new FormData(this.modalFormTarget)).toSorted().toString()
  }

  checkForChanges () {
    if (this.originalFormString === this.formToString()) {
      this.formFooterTarget.classList.add('hidden')
      this.unsavedTextTarget.innerText = ''
    } else {
      this.formFooterTarget.classList.remove('hidden')
      this.unsavedTextTarget.innerText = 'You have unsaved changes.'
    }
  }

  enableOptions (event) {
    // radio button selection change
    this.selectedDeploymentType = event.currentTarget.value

    if (this.selectedDeploymentType === 'auto_detect') {
      this.autoDetectedDeploymentTarget.classList.remove('text-gray-400', 'dark:text-gray-600')
    } else {
      this.autoDetectedDeploymentTarget.classList.add('text-gray-400', 'dark:text-gray-600')
    }

    this.deploymentInputTargets.forEach(b => {
      if (b.dataset.parent === this.selectedDeploymentType) {
        b.classList.remove('bg-gray-50', 'dark:bg-gray-800/50')
        b.classList.add('bg-white', 'dark:bg-gray-800')
        b.removeAttribute('disabled')
      } else {
        b.classList.add('bg-gray-50', 'dark:bg-gray-800/50')
        b.classList.remove('bg-white', 'dark:bg-gray-800')
        b.setAttribute('disabled', 'disabled')
      }
    })
  }
}
