import { Controller } from '@hotwired/stimulus'
export default class GitHubStatusController extends Controller {
  connect () {
    fetch('https://status.gitlab.com/1.0/status/5b36dc6502d06804c08349f7')
      .then(response => response.json())
      .then(data => {
        const outageComponents = data.result.status.find(({ name, status }) => {
          return ['API', 'Background Processing'].includes(name) && status.toLowerCase() !== 'operational'
        })
        if (outageComponents) {
          this.element.classList.remove('hidden')
        }
      })
  }
}
