import { Controller } from '@hotwired/stimulus'
import { datadogLogs } from '@datadog/browser-logs'

export default class DatadogLogsController extends Controller {
  static values = {
    env: String,
    version: String,
    userId: Number
  }

  initialize () {
    datadogLogs.init({
      clientToken: 'pub30be0f5d9451221aa1cbfdeec293770e',
      site: 'datadoghq.com',
      service: 'software-app',
      env: this.envValue,
      version: this.versionValue,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: [],
      sampleRate: 100,
      silentMultipleInit: true
    })

    if (this.userIdValue) {
      datadogLogs.setUser({
        id: this.userIdValue
      })
    };
  }
}
