import { Controller } from '@hotwired/stimulus'
export default class BitbucketStatusController extends Controller {
  connect () {
    fetch('https://bitbucket.status.atlassian.com/api/v2/components.json')
      .then(response => response.json())
      .then(data => {
        const outageComponent = data.components.find(({ name, status }) => {
          return ['API', 'Authentication and user management', 'Webhooks'].includes(name) && status !== 'operational'
        })
        if (outageComponent) {
          this.element.classList.remove('hidden')
        }
      })
  }
}
