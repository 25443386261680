import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="turbo-false-button-disable"
export default class extends Controller {
  initialize () {
    document.addEventListener('turbo:load', (event) => this.disableFormElements(event))
    document.addEventListener('turbo:frame-load', (event) => this.disableFormElements(event))
  }

  disableFormElements (event) {
    const turboFalseSelector = '[data-turbo="false"]:not([data-turbo-false-button-disabled]):not([data-disable-with="false"])'
    event.target.querySelectorAll(
      `input[type="submit"]${turboFalseSelector}, button[type="submit"]${turboFalseSelector}`
    ).forEach(this.disableFormElement)
  }

  disableFormElement (element) {
    if (element.dataset.turboFalseButtonDisabled === 'true') {
      return
    }

    element.form?.addEventListener('submit', (event) => {
      const disableWith = element.dataset.disableWith
      if (disableWith != null) {
        const elementType = element.tagName
        if (elementType === 'INPUT') {
          element.value = disableWith
        } else if (elementType === 'BUTTON') {
          element.innerHTML = disableWith
        }
      }
      element.setAttribute('disabled', '')
    })

    // Mark it so that the event listener will not be added again
    element.dataset.turboFalseButtonDisabled = true
  }
}
