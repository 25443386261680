import { Controller } from '@hotwired/stimulus'

export default class CopyController extends Controller {
  static targets = ['paragraph']
  static values = { link: String }

  copyLink (e) {
    navigator.clipboard.writeText(this.linkValue)
    this.showCopied(e)
  }

  copyParagraph (e) {
    navigator.clipboard.writeText(this.paragraphTarget.innerText)
    this.showCopied(e)
  }

  showCopied (e) {
    const originalText = e.target.innerHTML
    e.target.innerHTML = 'Copied!'
    setTimeout(() => {
      e.target.innerHTML = originalText
    }, 1000)
  }

  toggleParagraph (e) {
    this.paragraphTarget.classList.toggle('hidden')
    const text = this.paragraphTarget.classList.contains('hidden') ? 'Show' : 'Hide'
    e.target.innerHTML = `${text} ${e.target.dataset.for}`
  }
}
