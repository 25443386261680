import { Controller } from '@hotwired/stimulus'

export default class ManageOrgPlanController extends Controller {
  static values = {
    basePricingTiers: Array,
    deploymentInsightsPricingTiers: Array,
    planningInsightsPricingTiers: Array
  }

  static targets = [
    'priceDisplay',
    'priceSlider',
    'basePlatformPrice',
    'deploymentsPrice',
    'deploymentsCheckbox',
    'deploymentsBoundingBox',
    'planningPrice',
    'planningCheckbox',
    'planningBoundingBox',
    'perMonthLabel',
    'makeADeal',
    'submitButton',
    'contactUsButton'
  ]

  connect () {
    this.handlePriceChanges()
  }

  handlePriceChanges () {
    this.handleMakeADeal()

    if (this.priceSliderTarget.value === '3') {
      this.setCustomPricing()
      return
    }

    this.perMonthLabelTarget.classList.remove('hidden')
    this.contactUsButtonTarget.classList.add('hidden')
    this.submitButtonTarget.classList.remove('hidden')

    this.setBasePrice()
    this.setAddonPrices()
    this.setTotalPrice()
  }

  setAddonPrices () {
    this.setPlanningPrice()
    this.setDeploymentsPrice()
  }

  handleMakeADeal () {
    if (this.priceSliderTarget.value === '0') {
      this.makeADealTarget.classList.remove('invisible')
    } else {
      this.makeADealTarget.classList.add('invisible')
    }
  }

  setCustomPricing () {
    this.priceDisplayTarget.innerHTML = 'Contact us'
    this.basePlatformPriceTarget.innerHTML = 'Custom'
    this.deploymentsPriceTarget.innerHTML = 'Custom'
    this.planningPriceTarget.innerHTML = 'Custom'
    this.perMonthLabelTarget.classList.add('hidden')
    this.submitButtonTarget.classList.add('hidden')
    this.contactUsButtonTarget.classList.remove('hidden')
  }

  setDeploymentsPrice () {
    // pricing is in cents
    this.deploymentsPrice = this.deploymentInsightsPricingTiersValue[this.priceSliderTarget.value] / 100
    this.deploymentsPriceTarget.innerHTML = `$${this.deploymentsPrice}`
  }

  addDeplomentsAddOn () {
    if (this.deploymentsCheckboxTarget.checked) {
      this.totalPrice += this.deploymentsPrice
      this.deploymentsBoundingBoxTarget.classList.remove('bg-gray-100', 'dark:bg-gray-700')
      this.deploymentsBoundingBoxTarget.classList.add('bg-blue-500', 'bg-opacity-20')
    } else {
      this.deploymentsBoundingBoxTarget.classList.remove('bg-blue-500', 'bg-opacity-20')
      this.deploymentsBoundingBoxTarget.classList.add('bg-gray-100', 'dark:bg-gray-700')
    }
  }

  setPlanningPrice () {
    // pricing is in cents
    this.planningPrice = this.planningInsightsPricingTiersValue[this.priceSliderTarget.value] / 100
    this.planningPriceTarget.innerHTML = `$${this.planningPrice}`
  }

  addPlanningAddOn () {
    if (this.planningCheckboxTarget.checked) {
      this.totalPrice += this.planningPrice
      this.planningBoundingBoxTarget.classList.remove('bg-gray-100', 'dark:bg-gray-700')
      this.planningBoundingBoxTarget.classList.add('bg-blue-500', 'bg-opacity-20')
    } else {
      this.planningBoundingBoxTarget.classList.remove('bg-blue-500', 'bg-opacity-20')
      this.planningBoundingBoxTarget.classList.add('bg-gray-100', 'dark:bg-gray-700')
    }
  }

  setBasePrice () {
    // pricing is in cents
    this.basePrice = this.basePricingTiersValue[this.priceSliderTarget.value] / 100
    this.basePlatformPriceTarget.innerHTML = `$${this.basePrice}`
  }

  setTotalPrice () {
    this.totalPrice = this.basePrice
    this.addDeplomentsAddOn()
    this.addPlanningAddOn()
    this.priceDisplayTarget.innerHTML = `$${this.totalPrice}`
  }
}
