import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { deepLinkUrl: String }

  connect () {
    if (this.hasDeepLinkUrlValue && this.deepLinkUrlValue.includes('vscode://softwaredotcom.swdc-vscode')) {
      window.location.href = this.deepLinkUrlValue
    }
  }
}
