import { Controller } from '@hotwired/stimulus'

export default class DarkModeController extends Controller {
  static targets = ['darkModeCheckbox']

  connect () {
    // Script in head tag sets the initial state. Match the toggle to that state.
    if (document.documentElement.classList.contains('dark')) {
      this.darkModeCheckboxTarget.checked = true
    }

    // toggle dark mode when Ctrl+0 is pressed
    document.addEventListener('keydown', (event) => {
      if (event.code === 'Digit0' && event.ctrlKey) {
        this.toggleDarkMode()
      }
    })
  }

  disconnect () {
    document.removeEventListener('keydown', this.toggleDarkMode)
  }

  currentlyDark () {
    if ('theme' in localStorage) {
      return localStorage.theme === 'dark'
    } else {
      return window.matchMedia('(prefers-color-scheme: dark)').matches
    }
  }

  toggleDarkMode () {
    this.currentlyDark() ? this.setLightMode() : this.setDarkMode()
  }

  setDarkMode () {
    document.documentElement.classList.toggle('dark')
    this.darkModeCheckboxTarget.checked = true
    localStorage.theme = 'dark'
    document.documentElement.dispatchEvent(new CustomEvent('theme-change', {}))
  }

  setLightMode () {
    document.documentElement.classList.toggle('dark')
    this.darkModeCheckboxTarget.checked = false
    localStorage.theme = 'light'
    document.documentElement.dispatchEvent(new CustomEvent('theme-change', {}))
  }
}
