import { Controller } from '@hotwired/stimulus'
export default class KpiCustomDateRangePickerController extends Controller {
  static values = {
    isVisible: { type: Boolean, default: false }
  }

  handleClickOutside (e) {
    if (this.isVisibleValue) {
      this.disable()
    }
  }

  enable () {
    this.isVisibleValue = true
    const disabledInputs = document.querySelectorAll('select')
    disabledInputs.forEach((element) => {
      if (element.dataset.rangePickerSelect) {
        element.disabled = false
      }
    })
  }

  disable () {
    this.isVisibleValue = false
    const disabledInputs = document.querySelectorAll('select')
    disabledInputs.forEach((element) => {
      if (element.dataset.rangePickerSelect) {
        element.disabled = true
      }
    })
  }

  preventSubmit (e) {
    e.preventDefault()
  }

  selectCustom (e) {
    const customDateRangeSelection = document.querySelector('[id="date_range_custom"]')
    customDateRangeSelection.checked = true
  }
}
