import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {
  static classes = ['active']
  static targets = ['btn', 'tab']
  static values = { defaultTab: String, url: String }

  connect () {
    this.hideAllTabs()

    // then, show the default tab
    const selectedTab = this.tabTargets.find(element => element.id === this.defaultTabValue)
    this.showTab(selectedTab)

    // and activate the selected button
    const selectedBtn = this.btnTargets.find(element => element.id === this.defaultTabValue)
    this.activateBtn(selectedBtn)
  }

  // switch between tabs
  // add to your buttons: data-action='click->content-tabs#select'
  select (event) {
    this.hideAllTabs()
    this.deactivateAllBtns()
    // find tab matching (with same id as) the clicked btn
    const selectedTab = this.tabTargets.find(element => element.id === event.currentTarget.id)
    if (selectedTab.hidden) {
      this.showTab(selectedTab)
      this.activateBtn(event.currentTarget)
    }
    this.setPortfolioOverviewTab(event)

    if (event.currentTarget.dataset.trendId) {
      const tableBtn = document.querySelector(`[id*="${event.currentTarget.dataset.trendId}"]`)
      if (tableBtn) {
        tableBtn.click()
      }
    }
  }

  hideAllTabs () {
    this.tabTargets.forEach((x) => {
      if (!x.hidden) {
        x.hidden = true
      }
    })
  }

  showTab (tab) {
    tab.classList.remove('hidden')
    // then show selected
    tab.hidden = false // show current tab
  }

  deactivateAllBtns () {
    this.btnTargets.map(x => x.classList.remove('border-b-2', 'border-solid', 'border-blue-500', 'dark:text-white'))
  }

  activateBtn (btn) {
    btn.classList.remove('text-gray-400', 'transition-colors', 'duration-200', 'border-transparent')
    btn.classList.add('border-b-2', 'border-solid', 'border-blue-500', 'dark:text-white')
  }

  setPortfolioOverviewTab (event) {
    const query = this.getFetchRequestQuery({ content_tab: event.currentTarget.id })
    const request = new FetchRequest('get', this.urlValue, { responseKind: 'turbo-stream', query })
    request.perform()
  }

  getFetchRequestQuery (params) {
    return Object.keys(params).reduce((hash, key) => {
      if (params[key]) hash[key] = params[key]
      return hash
    }, {})
  }
}
