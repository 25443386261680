import { Controller } from '@hotwired/stimulus'

export default class DateRangeController extends Controller {
  static targets = ['startDate', 'endDate', 'applyButton', 'dateRange']

  showPicker (event) {
    try {
      event.target.showPicker()
    } catch (e) {
      // Safari does not support showPicker(), it automatically opens the picker when the input is focused
      console.warn(e)
    }
  }

  selectRange (event) {
    const endDate = new Date()
    let startDate
    // both the start and end dates are inclusive
    switch (event.currentTarget.value) {
      case 'last_14_days':
        startDate = new Date(new Date().setDate(endDate.getDate() - 14))
        break
      case 'last_30_days':
        startDate = this.getMonthsBackDate(1)
        break
      case 'last_90_days':
      case 'last_12_weeks':
      case 'last_3_months':
        startDate = this.getMonthsBackDate(3)
        break
      case 'last_6_months':
        startDate = this.getMonthsBackDate(6)
        break
      case 'last_12_months':
        startDate = this.getMonthsBackDate(12)
        break
      case 'last_24_months':
        startDate = this.getMonthsBackDate(12)
        break
      default:
        startDate = null
    }

    if (!this.hasStartDateTarget) {
      return
    }

    if (startDate) {
      this.startDateTarget.value = startDate.toISOString().split('T')[0]
      this.endDateTarget.value = endDate.toISOString().split('T')[0]
      this.submitForm()
    } else {
      // safari does not support showPicker()
      this.startDateTarget.showPicker() || this.startDateTarget.focus()
    }
  }

  showApplyButton () {
    this.applyButtonTarget.classList.remove('hidden')
  }

  customChange () {
    this.setCustomDateRangeValueOnSelect()
    this.showApplyButton()
  }

  newDateSelect (startDate, endDate) {
    this.setCustomDateRangeValueOnSelect()
    this.startDateTarget.value = startDate
    this.endDateTarget.value = endDate
    this.submitForm()
  }

  setCustomDateRangeValueOnSelect () {
    const endDate = new Date(this.endDateTarget.value)
    endDate.setUTCHours(0, 0, 0, 0)
    const startDate = new Date(this.startDateTarget.value)
    startDate.setUTCHours(0, 0, 0, 0)
    const today = new Date()
    today.setUTCHours(0, 0, 0, 0)
    const daysDiff = this.getDaysDiff(startDate, endDate)

    if (endDate.toUTCString() !== today.toUTCString()) {
      this.dateRangeTarget.value = 'custom'
    } else {
      switch (daysDiff) {
        case 14:
          this.dateRangeTarget.value = 'last_14_days'
          break
        case this.getDaysDiff(this.getMonthsBackDate(1), today):
          this.dateRangeTarget.value = 'last_30_days'
          break
        case this.getDaysDiff(this.getMonthsBackDate(3), today):
          this.dateRangeTarget.value = 'last_3_months'
          break
        case this.getDaysDiff(this.getMonthsBackDate(6), today):
          this.dateRangeTarget.value = 'last_6_months'
          break
        case this.getDaysDiff(this.getMonthsBackDate(12), today):
          this.dateRangeTarget.value = 'last_12_months'
          break
        case this.getDaysDiff(this.getMonthsBackDate(24), today):
          this.dateRangeTarget.value = 'last_24_months'
          break
        default:
          this.dateRangeTarget.value = 'custom'
      }
    }
  }

  getDaysDiff (startDate, endDate) {
    return Math.round((endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24))
  }

  getMonthsBackDate (monthsBack) {
    const d = new Date(new Date().setMonth(new Date().getMonth() - monthsBack))
    d.setUTCHours(0, 0, 0, 0)
    return d
  }

  submitForm () {
    this.applyButtonTarget.click()
  }
}
