import { Controller } from '@hotwired/stimulus'

export default class StaleDataController extends Controller {
  connect () {
    this.showModal()
    window.addEventListener('closed-modal-stale-data', this.setAsSeen.bind(this))
  }

  disconnect () {
    window.removeEventListener('closed-modal-stale-data', this.setAsSeen.bind(this))
  }

  setAsSeen (e) {
    localStorage.setItem('stale_data_modal_viewed_at', Date.now())
  }

  showModal () {
    const sevenDaysInMs = 604800000
    const staleDataModalViewedAt = Number(localStorage.getItem('stale_data_modal_viewed_at') || 0)

    // Only show this modal once in seven days. The org flag for `use_real_time_data` will
    // be flipped to `true` within 30 minutes of them seeing this modal. They can not go
    // back to stale until after 60 days of viewing this modal.
    if (Date.now() - staleDataModalViewedAt > sevenDaysInMs) {
      const evt = new CustomEvent('open-modal', {
        detail: {
          modalId: 'stale-data'
        }
      })
      window.dispatchEvent(evt)
    }
  }
}
