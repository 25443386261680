import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class Selector extends Controller {
  static targets = [
    'button',
    'dropDown',
    'chevron',
    'selectedOption',
    'selectorContainer',
    'search',
    'listOptions',
    'templateOption',
    'autoSubmitForm',
    'autoSubmitInput',
    'submitOnSelect'
  ]

  // "options" value can be an array of values or array of tuples,
  // all options will be normalized to tuples
  // i.e. ['name1', 'name2'] or [['name1', 'val1'],['name2', 'val2']]
  static values = {
    name: String,
    eventHandle: String,
    multiple: Boolean,
    options: Array,
    selected: Array,
    allOption: Boolean,
    asTags: Boolean,
    abbreviateTags: Boolean,
    submitOnChange: Boolean,
    onChangeFormId: String,
    onChangeFormFieldId: String,
    submitOnSelect: Boolean,
    modalOnChangeId: String,
    loader: String,
    loadingView: String,
    withFormField: Boolean
  }

  initialize () {
    // dirty tracks if the value has changed
    this.dirty = false
    this.selectingOption = false
    this.normalizeOptionsAndSelected()
    this.selected = this.selectedValue
    this.dropDownOpen = false
    this.maxSelectedTags = 1

    if (this.asTagsValue) {
      this.selectedOptionTarget.classList.remove('block')
      this.buttonTarget.classList.remove('py-2')
      this.buttonTarget.classList.remove('pl-3')
      this.selectedOptionTarget.classList.add('flex')
      this.selectedOptionTarget.classList.add('flex-wrap')
    }

    this.setSelectedOptionsValue()
    this.resetOptions()
  }

  connect () {
    useClickOutside(this)
  }

  // This normalizes a single value into a tuple value
  normalizeOptionsAndSelected () {
    this.optionsValue = this.optionsValue.map(options => {
      if (!Array.isArray(options)) {
        return [options, options]
      }
      return options
    })

    if (this.allOptionValue) {
      const existing = this.optionsValue.find(
        option => option[0] === `All ${this.nameValue}`
      )
      if (!existing) {
        this.optionsValue = [
          [`All ${this.nameValue}`, `All ${this.nameValue}`]
        ].concat(this.optionsValue.sort())
      }
    }

    // normalize to an array of tuples
    if (this.selectedValue?.length && !Array.isArray(this.selectedValue[0])) {
      // the incoming selected value is a non-tuple array, normalize it
      if (!this.multipleValue) {
        this.selectedValue = [this.selectedValue]
      } else {
        if (this.selectedValue.length === 2 && this.isAllValue(this.selectedValue)) {
          this.selectedValue = [this.selectedValue]
        } else {
          this.selectedValue = this.selectedValue.map(val => {
            return [val, val]
          })
        }
      }
    }
  }

  clickOutside (event) {
    const isValueSelect = !!event.currentTarget?.dataset?.value || false
    const isActionSelect = !!event.target?.dataset?.action || false
    const hasTargetValue = !!event.target?.dataset?.selectorTarget || false

    if (isValueSelect || isActionSelect || hasTargetValue || this.selectingOption) return

    if (this.dropDownOpen) {
      this.dropDownOpen = false
      this.renderDropDown()
    }
  }

  toggleDropDown () {
    this.dropDownOpen = !this.dropDownOpen
    this.renderDropDown()
  }

  renderDropDown () {
    if (this.dropDownOpen) {
      this.dropDownTarget.classList.remove('hidden')
      this.chevronTarget.classList.add('rotate-180')
      this.buttonTarget.classList.add('rounded-b-none')

      this.selectorContainerTarget.classList.remove('z-10')
      this.selectorContainerTarget.classList.add('z-50')
      this.hasSearchTarget && this.searchTarget.focus()
    } else {
      this.dropDownTarget.classList.add('hidden')
      this.chevronTarget.classList.remove('rotate-180')
      this.buttonTarget.classList.remove('rounded-b-none')

      this.selectorContainerTarget.classList.remove('z-50')
      this.selectorContainerTarget.classList.add('z-10')
    }

    // send js event when dropdown is closed
    if (this.dropDownOpen === false && this.dirty === true) {
      const optionValues = this.selected.map(n => n[1])
      const selectedOptionsEvent = new CustomEvent(
        `selected-${this.eventHandleValue}-options`,
        { detail: optionValues }
      )
      document.documentElement.dispatchEvent(selectedOptionsEvent)
      if (this.submitOnChangeValue) {
        this.autoSubmitFormTarget.submit()
      } else if (this.onChangeFormFieldIdValue) {
        if (this.onChangeFormIdValue) {
          const formElement = document.getElementById(this.onChangeFormIdValue).elements[this.onChangeFormFieldIdValue]
          formElement.value = optionValues
          if (this.submitOnSelectValue) {
            document.getElementById(this.onChangeFormIdValue).submit()
          }
        } else {
          document.getElementById(this.onChangeFormFieldIdValue).value = optionValues
        }
      }
    }
    // after each render, set dirty false again since we've done the work already
    this.dirty = false
  }

  selectOption (event) {
    // since we are changing the selection, set dirty true
    this.dirty = true
    // toggle the check mark indicating selected
    if (this.multipleValue) {
      this.selectingOption = true
      event.currentTarget.getElementsByTagName('svg')[0].classList.toggle('hidden')
    }

    const dataset = event.currentTarget.dataset
    if (!this.multipleValue && dataset.selected === 'true') {
      // already selected for a single selector, prevent removing the option
      return
    }
    // remove when the option is already selected
    if (dataset.selected === 'true') {
      dataset.selected = 'false'
      this.removeOption(dataset.value)
    } else {
      // add when the option is not already selected
      dataset.selected = 'true'
      this.addOption(dataset.name, dataset.value)
    }

    if (!this.multipleValue) {
      this.dropDownOpen = false
      this.renderDropDown()
      if (this.hasLoadingViewValue && this.hasLoaderValue && this.submitOnSelectValue) {
        const evt = new CustomEvent('show-loader', {
          detail: {
            loadingView: this.loadingViewValue,
            loader: this.loaderValue
          }
        })
        window.dispatchEvent(evt)
      }
      if (this.submitOnChangeValue) {
        this.autoSubmitFormTarget.submit()
        this.dirty = false
      } else if (this.onChangeFormIdValue) {
        if (this.onChangeFormFieldId) {
          document.getElementById(this.onChangeFormFieldIdValue).value = this.selected.map(n => n[1])
        }
        if (this.submitOnSelectValue) {
          document.getElementById(this.onChangeFormIdValue).submit()
          this.dirty = false
        }
      } else if (this.modalOnChangeIdValue) {
        // show the modal
        const evt = new CustomEvent('open-modal', {
          detail: {
            modalId: this.modalOnChangeIdValue
          }
        })
        window.dispatchEvent(evt)
        this.dirty = false
      } else {
        this.setSelectedOptionsValue()
      }
    }

    setTimeout(() => {
      if (this.selectingOption) {
        this.selectingOption = false
      }
    }, 500)
  }

  addOption (name, value) {
    let resetOptions = false
    if (!this.selected.find(option => option[1].toString() === value.toString())) {
      // value isn't found in the selected options, add it
      if (this.multipleValue) {
        if (this.allOptionValue && value === `All ${this.nameValue}`) {
          // add the "named" all option
          this.selected = [[`All ${this.nameValue}`, `All ${this.nameValue}`]]
          resetOptions = true
        } else if (value === 'all') {
          // add the all option (this is the 1st element in the optionsValue list)
          this.selected = [this.optionsValue[0]]
          resetOptions = true
        } else {
          // remove the all option selection
          const allOptionIndex = this.selected.findIndex(
            option => this.isAllValue(option)
          )
          if (allOptionIndex > -1) {
            this.selected.splice(allOptionIndex, 1)
            resetOptions = true
          }
          this.selected.push([name, value])
        }
      } else {
        this.selected = [[name, value]]
      }
    }
    this.setSelectedOptionsValue()
    this.searchOptions()
    if (resetOptions && !this.hasSearchTarget) {
      this.resetOptions()
    }
  }

  removeOption (value) {
    this.dirty = true

    this.selected = this.selected.filter(
      selectedOption => selectedOption[1].toString() !== value.toString()
    )
    this.setSelectedOptionsValue()
  }

  removeTagOption (event) {
    this.removeOption(event.currentTarget.dataset.value)
  }

  searchOptions () {
    // get the search term
    if (this.hasSearchTarget) {
      const searchTerm = this.searchTarget.value.replace(/\s+/g, '')
      // if the search term is empty, reset the options
      // else, filter the options based on the search term
      if (searchTerm.length > 0) {
        // generate the options
        const pattern = new RegExp(`${searchTerm}`, 'gi')
        const matches = this.optionsValue.map((option) => {
          if (option[0].match(pattern)) {
            return {
              name: option[0],
              value: option[1],
              selected: !!this.selected.find(n => n[1].toString() === option[1].toString())
            }
          } else {
            return false
          }
        }).filter(a => a)
        this.generateOptions(matches)
      } else {
        // show all options and reset the options
        this.resetOptions()
      }
    }
  }

  setSelectedOptionsValue () {
    if (this.asTagsValue) {
      this.renderTagOptions()
      this.setAutoSubmitField(
        this.selected.map(selected => selected[1]).join(',')
      )
    } else if (this.selected.length === 0) {
      this.selectedOptionTarget.innerText = 'None selected'
    } else if (!this.multipleValue || this.selected.length === 1) {
      // since no multiple selections, flatten to a tuple and take the name
      this.selectedOptionTarget.innerText = this.selected.flat()[0]
      this.setAutoSubmitField(this.selected.flat()[1])
    } else {
      this.selectedOptionTarget.innerText = `${this.selected.length} ${this.nameValue} selected`
      // set to a comma separated string of values
      this.setAutoSubmitField(
        this.selected.map(selected => selected[1]).join(',')
      )
    }
  }

  setAutoSubmitField (value) {
    if (this.hasAutoSubmitInputTarget && (this.submitOnChangeValue || this.withFormFieldValue)) {
      this.autoSubmitInputTarget.value = value
    }
  }

  renderTagOptions () {
    if (this.selected.length === 0) {
      this.selectedOptionTarget.innerHTML = '<span class="py-1 px-2 text-sm m-1">None selected</span>'
    } else {
      if (this.selected.length > this.maxSelectedTags) {
        const displayVals = []
        for (let i = 0; i < this.maxSelectedTags; i++) {
          displayVals.push(this.selectedOptionHtml(this.selected[i]))
        }
        displayVals.push(this.moreSelectedHtml(this.selected.length - this.maxSelectedTags))
        this.selectedOptionTarget.innerHTML = displayVals.join('')
      } else {
        this.selectedOptionTarget.innerHTML = this.selected
          .map(
            option => this.selectedOptionHtml(option)
          ).join('')
      }
    }
  }

  tagName (option) {
    const optionTag = option[0].trim()
    if (!this.isAllValue(option) && this.abbreviateTagsValue) {
      // 'A-team' will return as 'A'
      // 'Production team' will return as 'Pr'
      if (optionTag.match(/^[\w]{2}/)) {
        return optionTag.slice(0, 2)
      } else {
        return optionTag.slice(0, 1)
      }
    }
    return optionTag
  }

  secondaryTagName (option) {
    if (!this.isAllValue(option) && this.abbreviateTagsValue) {
      return `<span class="text-sm">${option[0]}</span>`
    }
    return ''
  }

  tagRemoveHtml (option) {
    return this.isAllValue(option) || this.multipleValue === false ? '' : this.removeButtonHtml(option)
  }

  removeButtonHtml (option) {
    return `<span data-action="click->selector#removeTagOption:capture" title="Remove ${option[0]}" data-name="${option[0]}" data-value="${option[1]}" class="inline-block w-4 h-4 rounded-full p-0 ml-2 text-xs hover:text-red-500 bg-white dark:bg-gray-800 align-middle"><svg class="w-4 h-4 p-0.5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" /></svg></span>`
  }

  selectedOptionHtml (option) {
    return `<div class="flex items-center">
        <span title="${option[0]}" class="py-1 px-2 bg-gray-100 dark:bg-gray-900 dark:text-gray-400 text-gray-500 text-sm rounded-md m-1 truncate text-ellipsis">
          ${this.tagName(option)}
        </span>
        ${this.secondaryTagName(option)}
      </div>`
  }

  moreSelectedHtml (count) {
    return `<div class="flex items-center">
        <span class="py-1 px-2 bg-gray-100 dark:bg-gray-900 dark:text-gray-400 text-gray-500 text-sm rounded-md m-1 truncate text-ellipsis">
          +${count} more
        </span>
      </div>`
  }

  isAllValue (option) {
    return !!(option[1] === `All ${this.nameValue}` || option[1] === 'all')
  }

  resetOptions () {
    // reset the options
    this.generateOptions(
      this.optionsValue.map(options => {
        return {
          name: options[0],
          value: options[1],
          selected: !!this.selected.find(n => n[1].toString() === options[1].toString())
        }
      })
    )
  }

  sortOptions (options) {
    if (this.multipleValue) {
      const allOptions = [options[0]]
      const subOptions = options.slice(1)
      // gets selected options on top
      subOptions.sort((x, y) => y.selected - x.selected)
      options = allOptions.concat(subOptions)
    } else {
      // gets selected options on top
      options.sort((x, y) => y.selected - x.selected)
    }
    return options
  }

  listItemHtml (option) {
    const tagOptionCss = this.abbreviateTagsValue ? 'bg-gray-100 dark:bg-gray-900 dark:text-gray-400 text-gray-500' : 'dark:text-gray-100 text-gray-800'
    return `<div class="flex items-center">
        <span title="${option.name}" class="py-1 px-2 ${tagOptionCss} text-sm rounded-md m-1 truncate text-ellipsis">
          ${this.tagName([option.name, option.value])}
        </span>
        ${this.secondaryTagName([option.name, option.value])}
      </div>`
  }

  generateOptions (options) {
    options = this.sortOptions(options)
    // generate the options
    this.listOptionsTarget.innerHTML = ''
    options.forEach(option => {
      const hiddenValue = option.selected ? '' : 'hidden'
      const optionHtml = this.templateOptionTarget.content.cloneNode(true)
      const listElement = optionHtml.children[0] // li element

      // set the option attributes
      listElement.setAttribute('data-selected', option.selected)
      listElement.setAttribute('data-name', option.name)
      listElement.setAttribute('data-value', option.value)

      // set the option text
      listElement.innerHTML = listElement.innerHTML.replace(
        'nameValue',
        this.listItemHtml(option)
      )

      // set the svg to hidden or not
      listElement.innerHTML = listElement.innerHTML.replace(
        'hiddenValue',
        hiddenValue
      )
      this.listOptionsTarget.appendChild(optionHtml)
    })
  }
}
