import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'sendPreviewButton',
    'noChannelMessage',
    'loadingMessage',
    'successMessage',
    'errorMessage'
  ]

  static values = {
    formUrl: String,
    type: String
  }

  static outlets = ['select']

  connect () {
    this.sentStatus = 'ready'
    this.authenticityToken = document.getElementsByName('csrf-token')[0].content
  }

  selectOutletConnected (_outlet, element) {
    this.selectedChannel = element.value
    this.render()

    element.addEventListener('change', (event) => {
      this.selectedChannel = event.target.value
      this.render()
    })
  }

  render () {
    if (this.selectedChannel) {
      this.noChannelMessageTarget.classList.add('hidden')
      this.sendPreviewButtonTarget.classList.remove('hidden')
    } else {
      this.sendPreviewButtonTarget.classList.add('hidden')
      this.noChannelMessageTarget.classList.remove('hidden')
      return
    }

    switch (this.sentStatus) {
      case 'ready':
        this.loadingMessageTarget.classList.add('hidden')
        this.sendPreviewButtonTarget.classList.remove('hidden')
        this.successMessageTarget.classList.add('hidden')
        this.errorMessageTarget.classList.add('hidden')
        break
      case 'loading':
        this.loadingMessageTarget.classList.remove('hidden')
        this.sendPreviewButtonTarget.classList.add('hidden')
        this.successMessageTarget.classList.add('hidden')
        this.errorMessageTarget.classList.add('hidden')
        break
      case 'sent':
        this.loadingMessageTarget.classList.add('hidden')
        this.errorMessageTarget.classList.add('hidden')
        this.sendPreviewButtonTarget.classList.add('hidden')
        this.successMessageTarget.classList.remove('hidden')
        setTimeout(() => {
          this.sentStatus = 'ready'
          this.render()
        }, 5000)
        break
      case 'error':
        this.loadingMessageTarget.classList.add('hidden')
        this.sendPreviewButtonTarget.classList.add('hidden')
        this.errorMessageTarget.classList.remove('hidden')
        this.successMessageTarget.classList.add('hidden')
        setTimeout(() => {
          this.sentStatus = 'ready'
          this.render()
        }, 5000)
        break
    }
  }

  sendPreview (e) {
    if (!this.selectedChannel) return

    this.sentStatus = 'loading'
    this.render()

    fetch(this.formUrlValue, {
      method: 'POST',
      headers: {
        'x-csrf-token': this.authenticityToken,
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        type: this.typeValue,
        slack_channel_id: this.selectedChannel
      })
    }).then((result) => {
      if (result.status < 400) {
        this.sentStatus = 'sent'
      } else {
        this.sentStatus = 'error'
      }
      this.render()
    }).catch(() => {
      this.sentStatus = 'error'
      this.render()
    })
  }
}
