import { Controller } from '@hotwired/stimulus'
import { datadogRum } from '@datadog/browser-rum'

export default class DatadogController extends Controller {
  static values = {
    env: String,
    version: String,
    userId: Number
  }

  initialize () {
    datadogRum.init({
      applicationId: '5d6904ee-bb9f-40e3-9a92-022748fb6899',
      clientToken: 'pub30be0f5d9451221aa1cbfdeec293770e',
      site: 'datadoghq.com',
      service: 'software-app',
      env: this.envValue,
      version: this.versionValue,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      // Match all software.com subdomains, except for snow.software.com
      allowedTracingUrls: [/https:\/\/(?!snow\.).*\.software\.com/],
      silentMultipleInit: true,
      useSecureSessionCookie: true
    })

    if (this.userIdValue) {
      datadogRum.setUser({
        id: this.userIdValue
      })
    };
  }
}
