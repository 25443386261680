import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['deactivateModalSubmitButton']

  static values = {
    organizationDisplayName: String
  }

  validateDeactivateName (event) {
    if (event.target.value === this.organizationDisplayNameValue) {
      this.deactivateModalSubmitButtonTarget.disabled = false
    } else {
      this.deactivateModalSubmitButtonTarget.disabled = true
    }
  }
}
