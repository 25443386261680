import { Controller } from '@hotwired/stimulus'

export default class DataPageSelectSearchController extends Controller {
  static targets = ['option', 'selectedOption']

  findMatchingData (event) {
    const searchValue = event.currentTarget.value
    const splitNames = searchValue ? searchValue.split(/[\s_-]/).join('').toLowerCase() : ''

    this.optionTargets.forEach((option) => {
      if (this.normalizeValue(option.dataset.searchValue).includes(splitNames)) {
        option.classList.remove('hidden')
      } else {
        option.classList.add('hidden')
      }
    })
  }

  normalizeValue (name) {
    return name.split(/[\s_-]/).join('').toLowerCase()
  }

  replaceSelectedOption (event) {
    const selectedOption = event.currentTarget
    const selectedOptionText = selectedOption.parentNode.dataset.searchValue
    this.selectedOptionTarget.innerText = selectedOptionText
  }
}
