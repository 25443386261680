import { Controller } from '@hotwired/stimulus'

export default class CharacterCounterController extends Controller {
  static targets = [
    'displayWrapper',
    'charCount',
    'textInput'
  ]

  connect () {
    this.limit = 140
    this.calculateValidity()
  }

  updateCount () {
    // Add the invalid class only after the input has been touched, so we don't show invalid pristine forms
    this.textInputTarget.classList.add('invalid:border-red-500')
    this.calculateValidity()
  }

  calculateValidity () {
    const count = this.textInputTarget.value.length
    this.charCountTarget.innerText = count

    if (count > 140) {
      this.textInputTarget.setCustomValidity('Message too long.')
      this.displayWrapperTarget.classList.add('text-red-500')
    } else {
      this.textInputTarget.setCustomValidity('')
      this.displayWrapperTarget.classList.remove('text-red-500')
    }
  }
}
