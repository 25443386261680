import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="manage-groups"
export default class extends Controller {
  static targets = ['checkbox', 'footer', 'countSelected']

  initialize () {
    this.checkedCount = 0
    this.checkboxes = null
  }

  connect () {
    this.checkboxes = this.checkboxTargets
    this.newGroups = []
  }

  checkboxTargetConnected (element) {
    if (element.checked) {
      this.checkedCount += 1
      this.setCountSelectedText()
    }

    if (this.checkboxes !== null && !this.checkboxes.includes(element)) {
      this.newGroups.push(element)
      this.checkboxes.push(element)
      this.showFooter()
    }
  }

  afterResetForm () {
    setTimeout(() => {
      this.checkedCount = 0

      this.checkboxTargets.forEach((checkbox) => {
        if (checkbox.checked) {
          this.checkedCount += 1
        }
      })

      this.setCountSelectedText()
    }, 200)

    this.newGroups.forEach((group) => {
      group.removeAttribute('checked')
    })

    this.hideFooter()
  }

  hideFooter () {
    if (this.hasFooterTarget) {
      const showClasses = ['py-4', 'visible', 'max-h-screen', 'opacity-100']
      const hideClasses = ['py-0', 'invisible', 'max-h-0', 'opacity-0']
      if (this.footerTarget.classList.contains('visible')) {
        this.footerTarget.classList.remove(...showClasses)
        this.footerTarget.classList.add(...hideClasses)
      }
    }
  }

  showFooter () {
    if (this.hasFooterTarget) {
      const showClasses = ['py-4', 'visible', 'max-h-screen', 'opacity-100']
      const hideClasses = ['py-0', 'invisible', 'max-h-0', 'opacity-0']
      if (this.footerTarget.classList.contains('invisible')) {
        this.footerTarget.classList.remove(...hideClasses)
        this.footerTarget.classList.add(...showClasses)
      }
    }
  }

  selectAllClicked (event) {
    this.checkedCount = event.currentTarget.checked ? this.checkboxTargets.length : 0
    this.checkboxTargets.forEach(checkbox => { checkbox.checked = event.currentTarget.checked })

    this.setCountSelectedText()
    this.showFooter()
  }

  checkBoxClicked (event) {
    if (event.currentTarget.checked) {
      this.checkedCount++
    } else {
      this.checkedCount--
    }

    this.setCountSelectedText()
    this.showFooter()
  }

  setCountSelectedText () {
    this.countSelectedTarget.innerText = `${this.checkedCount} selected`
  }
}
