import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['button', 'dropDown', 'chevronDown', 'chevronUp']

  connect () {
    useClickOutside(this)
  }

  clickOutside (event) {
    if (this.hasChevronUpTarget) {
      if (!this.chevronUpTarget.classList.contains('hidden')) {
        event.preventDefault()
        this.toggleDropDown()
      }
    }
  }

  toggleDropDown () {
    this.dropDownTarget.classList.toggle('hidden')
    this.chevronDownTarget.classList.toggle('hidden')
    this.chevronUpTarget.classList.toggle('hidden')
    this.buttonTarget.classList.toggle('rounded-b-none')
  }
}
