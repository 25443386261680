import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['selected', 'unselected']
  static targets = ['tab', 'selectedTab', 'content', 'selectedContent']

  connect () {
    this.selectedTabTarget.classList.add(...this.selectedClasses)

    this.tabTargets.forEach(tab => {
      tab.classList.add(...this.unselectedClasses)
    })
  }

  selectTab (event) {
    const target = event.currentTarget.dataset.tabsTarget
    event.preventDefault()

    // return if selectedTab is clicked again
    if (target === 'selectedTab') return

    // Unselect currently selected tab and hide content
    this.selectedTabTarget.classList.remove(...this.selectedClasses)
    this.selectedTabTarget.classList.add(...this.unselectedClasses)
    this.selectedContentTarget.classList.add('hidden')
    this.selectedTabTarget.dataset.tabsTarget = target
    this.selectedContentTarget.dataset.tabsTarget = 'content'

    // Select tab that was clicked on and show content
    event.currentTarget.classList.add(...this.selectedClasses)
    event.currentTarget.classList.remove(...this.unselectedClasses)
    const content = this.contentTargets.find(element => element.id === event.params.contentTargetId)
    content.classList.remove('hidden')
    event.currentTarget.dataset.tabsTarget = 'selectedTab'
    content.dataset.tabsTarget = 'selectedContent'
  }
}
