import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = ['active', 'inactive']
  static targets = ['btn', 'tab']
  static values = { defaultTab: String, url: String }

  connect () {
    this.hideAllTabs()
    this.deactivateAllBtns()
    // then, show the default tab
    const selectedTab = this.tabTargets.find(element => element.id === this.defaultTabValue)
    this.showTab(selectedTab)

    // and activate the selected button
    const selectedBtn = this.btnTargets.find(element => element.id === this.defaultTabValue)
    this.activateBtn(selectedBtn)
  }

  select (event) {
    this.hideAllTabs()
    this.deactivateAllBtns()
    // find tab matching (with same id as) the clicked btn
    const selectedTab = this.tabTargets.find(element => element.id === event.currentTarget.id)
    if (selectedTab.hidden) {
      this.showTab(selectedTab)
      this.activateBtn(event.currentTarget)
    }
  }

  hideAllTabs () {
    this.tabTargets.forEach((x) => {
      if (!x.hidden) {
        x.hidden = true
      }
    })
  }

  showTab (tab) {
    tab.classList.remove('hidden')
    // then show selected
    tab.hidden = false // show current tab
  }

  deactivateAllBtns () {
    this.btnTargets.forEach((btn) => {
      btn.classList.remove(...this.activeClasses)
      btn.classList.add(...this.inactiveClasses)
    })
  }

  activateBtn (btn) {
    btn.classList.remove(...this.inactiveClasses)
    btn.classList.add(...this.activeClasses)
  }
}
