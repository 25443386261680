import { Controller } from '@hotwired/stimulus'

export default class CurrencyInputController extends Controller {
  static values = { max: Number }

  formatAmount () {
    let amount = this.element.value.replace(/[^0-9]/g, '') // Remove non-numeric characters
    let amountNumber = Number(amount)
    if (this.maxValue && (amountNumber > this.maxValue)) {
      amountNumber = this.maxValue
    }
    amount = amountNumber.toLocaleString('en-US') // Add commas
    this.element.value = amount
  }
}
