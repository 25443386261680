import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['dropDown', 'chevron']

  connect () {
    useClickOutside(this)
    this.isOpen = false
  }

  clickOutside (event) {
    if (this.isOpen) {
      event.preventDefault()
      this.toggleDropDown()
    }
  }

  toggleDropDown () {
    this.isOpen = !this.isOpen
    this.updateUI()
  }

  updateUI () {
    if (this.isOpen) {
      this.dropDownTarget.classList.remove('hidden')
      this.chevronTarget.classList.add('rotate-180')
    } else {
      this.dropDownTarget.classList.add('hidden')
      this.chevronTarget.classList.remove('rotate-180')
    }
  }
}
