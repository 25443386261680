import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="cost-efficiency-table"
export default class extends Controller {
  static outlets = ['cost-efficiency-chart']

  initialize () {
    this.allOptionChecked = true
  }

  legendMouseOver (event) {
    const name = event.currentTarget.dataset.name
    if (!name) return

    if (this.hasCostEfficiencyChartOutlet) {
      this.costEfficiencyChartOutlet.legendMouseOver(name)
    }
  }

  legendMouseOut (event) {
    const name = event.currentTarget.dataset.name
    if (!name) return

    if (this.hasCostEfficiencyChartOutlet) {
      this.costEfficiencyChartOutlet.legendMouseOut(name)
    }
  }

  legendAllOptionClicked (event) {
    if (this.hasCostEfficiencyChartOutlet) {
      const controller = this
      this.allOptionChecked = !this.allOptionChecked
      controller.costEfficiencyChartOutlet.legendAllOptionClicked(this.allOptionChecked)

      const currentTarget = event.currentTarget
      currentTarget.classList.remove('cursor-pointer')
      currentTarget.classList.add('cursor-wait')

      setTimeout(function () {
        document.querySelectorAll('.legend-checkbox').forEach(function (divSquare) {
          if (controller.allOptionChecked) {
            divSquare.querySelector('svg')?.classList?.remove('hidden')
            divSquare.dataset.bgColor.split(' ').forEach(htmlClass => {
              divSquare.classList.add(htmlClass)
            })
            divSquare.dataset.disabledClasses.split(' ').forEach(htmlClass => {
              divSquare.classList.remove(htmlClass)
            })
          } else {
            divSquare.querySelector('svg')?.classList?.add('hidden')
            divSquare.dataset.bgColor.split(' ').forEach(htmlClass => {
              divSquare.classList.remove(htmlClass)
            })
            divSquare.dataset.disabledClasses.split(' ').forEach(htmlClass => {
              divSquare.classList.add(htmlClass)
            })
          }
        })

        currentTarget.querySelector('svg')?.classList?.toggle('hidden')

        currentTarget.classList.remove('cursor-wait')
        currentTarget.classList.add('cursor-pointer')
      }, 0)
    }
  }

  legendClicked (event) {
    const name = event.currentTarget.dataset.name
    if (!name) return

    if (this.hasCostEfficiencyChartOutlet) {
      const divSquare = event.currentTarget.querySelector('.legend-checkbox')
      divSquare.dataset.bgColor.split(' ').forEach(htmlClass => {
        divSquare.classList.toggle(htmlClass)
      })
      divSquare.dataset.disabledClasses.split(' ').forEach(htmlClass => {
        divSquare.classList.toggle(htmlClass)
      })
      divSquare.querySelector('svg')?.classList?.toggle('hidden')

      this.costEfficiencyChartOutlet.legendClicked(name)
    }
  }
}
