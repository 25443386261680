// NOTE: this is not for a strategic event comparison chart
import BaseGraph from './base_graph_controller'
import { card, breakDownValue, exclamationSvg } from '../helpers/tooltip_helper'

// Connects to data-controller="comparison-chart"
export default class extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const dateGranularity = controller.optionsValue.date_granularity || 'quarter'

    this.USDFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    })

    this.hasData = controller.parsedGraphData.data.length
    if (!controller.parsedGraphData.data.length) {
      if (controller.optionsValue.type === 'spline') {
        // empty spline chart
        controller.parsedGraphData.data = [
          { name: '', data: new Array(controller.parsedGraphData.categories.length).fill(0) }
        ]
      } else {
        // empty column chart
        controller.parsedGraphData.data = [
          { name: '', y: 0 }
        ]
      }
    }

    if (!this.seriesVisibility) {
      this.seriesVisibility = {}

      controller.parsedGraphData.data.forEach(dataPoint => { controller.seriesVisibility[dataPoint.name] = true })
    }

    let series
    if (controller.optionsValue.type === 'spline') {
      series = controller.formatSplineSeries(
        controller.parsedGraphData.data,
        controller.parsedGraphData.categories
      )
    } else if (controller.optionsValue.type === 'column') {
      series = controller.formatColumnSeries(controller.parsedGraphData.data)
    }

    let type = controller.optionsValue.type
    // for active engineers chart
    if (controller.optionsValue.format === 'step' && type === 'spline') type = 'line'

    let total = 0
    if (controller.optionsValue.type === 'spline') {
      controller.parsedGraphData.data.forEach((item) => {
        total += item.data.reduce((acc, value) => acc + value, 0)
      })
    } else {
      controller.parsedGraphData.data.forEach((item) => { total += item.y })
    }
    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    // for spline chart, we want to highlight the last complete period
    let plotBands = []
    if (controller.optionsValue.type === 'spline') {
      plotBands = [
        {
          color: Highcharts.color(controller.tailwindColors.gray[200])
            .setOpacity(controller.darkMode ? 0.05 : 0.5)
            .get('rgba'),
          from: controller.parsedGraphData.categories.length - 2.5,
          to: controller.parsedGraphData.categories.length - 1.5,
          label: {
            align: 'center',
            y: -30,
            useHtml: true,
            formatter: function () {
              const shownInTableText = controller.hasData ? '(shown in table)' : ''
              return `
                <p class="text-xs" style="color: ${controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500]};">Last complete ${dateGranularity}</p>
                <br><p class="text-xs" style="color: ${controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500]};">${shownInTableText}</p>
              `
            }
          }
        }
      ]
    }

    return {
      chart: {
        type,
        backgroundColor: controller.colorTheme.backgroundColor,
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 30,
        spacingBottom: 10,
        height: 250
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'category',
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        lineWidth: 0,
        plotBands
      },
      yAxis: {
        title: { text: null },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        softMax: 1,
        labels: {
          enabled: true,
          // add $ in front of y-axis labels
          formatter: function () {
            if (total === 0) {
              return '--'
            } else if (controller.optionsValue.format === 'days') {
              return `${this.value}d`
            } else {
              return controller.formatValue(this.value)
            }
          },
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        ...maxSettings
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          groupPadding: 0.1,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          borderColor: 'transparent',
          minPointLength: 5,
          colorByPoint: true,
          maxPointWidth: 150,
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'justify',
            formatter: function () {
              return controller.formatValue(this.y > 0 ? this.y : '--')
            },
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.white
                : controller.tailwindColors.black
            }
          }
        },
        spline: {
          marker: {
            enabled: false,
            symbol: 'circle'
          }
        },
        line: {
          marker: {
            enabled: false,
            symbol: 'circle'
          },
          step: 'center'
        }
      },
      colors: this.breakdownColors,
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        outside: false,
        formatter: function () {
          if (controller.optionsValue.type === 'spline') {
            let quarterInProgressHtml = ''
            if (this.point.index === 4) quarterInProgressHtml = `<div class="mt-2 flex items-center gap-1 p-1 rounded text-yellow-500 bg-yellow-500/10"> <p>${exclamationSvg}</p><p>Quarter is in progress</p></div>`

            return card({
              date: this.x,
              header: `${quarterInProgressHtml}<div class="font-medium mt-2 text-gray-700 dark:text-gray-300">${controller.optionsValue.title}</div>`,
              breakDownValues: [breakDownValue({
                name: this.series.name,
                value: controller.formatValue(this.y, true),
                style: `background-color:${this.series.color};`,
                type: 'box'
              })]
            })
          } else {
            return `<div class="shadow-lg p-3 rounded-md bg-white dark:bg-gray-700 min-w-[13rem] z-50">
            <div class="font-medium text-gray-700 dark:text-gray-300">${controller.optionsValue.title}</div>
            <div class="flex justify-between items-center gap-2 mt-3">
              <div class="flex items-center gap-1">
                <div class="w-3 h-3 rounded-sm" style="background-color:${this.point.color}"></div>
                <div class="font-medium text-black dark:text-white">${this.point.name}</div>
              </div>
              <div class="font-semibold text-black dark:text-white">${controller.formatValue(this.y, true)}</div>
            </div>
          </div>
          `
          }
        }
      }
    }
  }

  formatValue (value, includeSuffix = false) {
    let valueText = '--'

    if (value != null) {
      if (this.optionsValue.format === '$') {
        valueText = (value === '--') ? '$--' : this.USDFormatter.format(value)
      } else if (value !== '--') {
        // round to 1 decimal place. we send the value with 2 decimal places for lead time for better granularity
        valueText = (Math.round(value * 10) / 10).toString()
      }
    }

    // Determine suffix based on options or include '%' for percentage format
    const suffix = includeSuffix ? this.optionsValue.suffix : (this.optionsValue.format === '%' ? '%' : '')

    return `${valueText}${suffix}` // Use template literal for concatenation
  }

  formatColumnSeries (data) {
    data.forEach((item, index) => {
      if (item.y === 0) {
        item.color = this.darkMode ? this.tailwindColors.gray[700] : this.tailwindColors.gray[300]
      } else {
        item.color = this.breakdownColors[index % 9]
      }
    })

    data = data.filter(item => this.seriesVisibility[item.name])

    data.sort((a, b) => {
      // Treat 0 as greater than any other number
      if (a.y === 0) return 1
      if (b.y === 0) return -1

      return this.optionsValue.sort === 'desc' ? b.y - a.y : a.y - b.y
    })

    return [{ data }]
  }

  formatSplineSeries (series, categories) {
    if (!series.length) return [{}]

    const zoneIndex = categories.length - 2
    series.forEach((item) => {
      item.zoneAxis = 'x'
      item.zones = [
        { value: zoneIndex }, // represents the first n periods
        { dashStyle: 'ShortDash' } // represents the last (current) period
      ]
      item.visible = this.seriesVisibility[item.name]
    })

    return series
  }

  legendMouseOver (name) {
    if (this.optionsValue.type === 'spline') {
      this.chart.series.forEach(series => {
        if (series.name === name) { return }
        series.setState('inactive')
      })
    } else {
      const point = this.chart.series[0].data.find(dataPoint => dataPoint.name === name)
      point?.setState('hover')
      if (point) this.chart.tooltip.refresh(point)
    }
  }

  legendMouseOut () {
    if (this.optionsValue.type === 'spline') {
      this.chart.series.forEach(series => series.setState(''))
    } else {
      this.chart.series[0].data.forEach(point => { point.setState('normal') })
      this.chart.tooltip.hide()
    }
  }

  legendClicked (name) {
    if (this.optionsValue.type === 'spline') {
      this.chart.series.forEach(series => {
        if (series.name === name) {
          series.setVisible(!series.visible)
        }
      })
    } else {
      // can also use this for spline but the visibility offers a nice animation
      this.seriesVisibility[name] = !this.seriesVisibility[name]
      this.chart.update(this.prepareGraphSchema())
    }
  }

  legendAllOptionClicked (allOptionChecked) {
    if (this.optionsValue.type === 'spline') {
      this.chart.series.forEach(series => { series.setVisible(allOptionChecked) })
    } else {
      Object.keys(this.seriesVisibility).forEach(series => { this.seriesVisibility[series] = allOptionChecked })
      this.chart.update(this.prepareGraphSchema())
    }
  }
}
