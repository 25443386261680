import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="turbo-graph-error-handler"
export default class extends Controller {
  static targets = ['frame']

  // Add the following data attributes to the turbo_frame_tag to
  // display an error message in the graph when the request fails.
  // An instance of the controller is already instantiated on the
  // application <body>, so you only need to set the target on the
  // turbo_frame_tag
  //
  // <%= turbo_frame_tag(
  //   ...,
  //   data: {
  //     turbo_graph_error_handler_target: 'frame',
  //     error_handler_message_value: 'An optional message to overwrite the default',
  //     error_handler_unauthorized_message_value: 'An optional message to overwrite the default',
  //   }
  // ) do %>

  frameTargetConnected (turboFrameElement) {
    let message = 'There was an error fetching your data.'
    if ('errorHandlerMessageValue' in turboFrameElement.dataset) {
      message = turboFrameElement.dataset.errorHandlerMessageValue
    }
    let unauthorizedMessage = 'Pro plan required'
    if ('errorHandlerUnauthorizedMessageValue' in turboFrameElement.dataset) {
      unauthorizedMessage = turboFrameElement.dataset.errorHandlerUnauthorizedMessageValue
    }
    const targetClasses = turboFrameElement.dataset.errorHandlerTargetClasses?.split(' ') || []

    turboFrameElement.addEventListener('turbo:before-fetch-response', (event) => {
      if (event.detail.fetchResponse.statusCode === 403) {
        event.preventDefault()

        this.renderDataFetchErrorMessage(event.target, unauthorizedMessage, targetClasses)
      }
    })

    turboFrameElement.addEventListener('turbo:frame-missing', (event) => {
      if (event.detail.response.status >= 400) {
        event.preventDefault()

        this.renderDataFetchErrorMessage(event.target, message, targetClasses)
      }
    })
  }

  renderDataFetchErrorMessage (target, message, targetClasses) {
    target.classList.add(...targetClasses)
    target.innerHTML = `<span class="m-auto text-gray-500">${message}</span>`
  }
}
