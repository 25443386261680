import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class RelatedMetricsColumnChartController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const series = [
      {
        data: controller.parsedGraphData.data,
        color: Highcharts.color(controller.tailwindColors.blue[400]).setOpacity(0.50).get('rgba')
      }
    ]

    const maxIndex = controller.parsedGraphData.data.length - 1

    // mark the last data point as in progress
    series[0].data[maxIndex] = {
      ...series[0].data[maxIndex],
      color: {
        pattern: {
          path: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
          width: 4,
          height: 4,
          color: Highcharts.color(controller.tailwindColors.blue[400]).setOpacity(0.50).get('rgba')
        }
      }
    }

    let total = 0
    if (controller.parsedGraphData.data.length > 0) {
      total = controller.parsedGraphData.data.reduce((acc, val) => acc + val.y, 0)
    }

    let plotLines = 0
    if (total > 0) {
      plotLines = [
        {
          color: controller.darkMode ? controller.tailwindColors.blue[300] : controller.tailwindColors.blue[800],
          value: controller.optionsValue.average === 0 ? null : controller.optionsValue.average,
          width: 2,
          dashStyle: 'ShortDash',
          zIndex: 5
        }
      ]
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        title: { text: null },
        tickLength: 0,
        labels: {
          enabled: false
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[200],
        maxPadding: 0
      },
      yAxis: {
        title: { text: null },
        gridLineColor: 'transparent',
        labels: {
          enabled: false
        },
        plotLines,
        ...maxSettings
      },
      plotOptions: {
        column: {
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 1,
          borderColor: controller.darkMode ? controller.tailwindColors.gray[800] : controller.tailwindColors.white,
          connectNulls: true,
          dataLabels: {
            enabled: false
          },
          borderRadiusTopLeft: 2,
          borderRadiusTopRight: 2
        }
      },
      series,
      tooltip: {
        ...controller.baseToolTipConfig,
        formatter: function () {
          const startDate = new Date(this.x) // get current date
          let formattedDate = ''

          if (controller.optionsValue.date_granularity === 'Week') {
            const endOfWeek = new Date(this.x)
            endOfWeek.setDate(startDate.getDate() + 6)
            formattedDate = `${Highcharts.dateFormat('%b %e', startDate)} - ${Highcharts.dateFormat('%b %e', endOfWeek)}`
          } else { // Month
            formattedDate = Highcharts.dateFormat("%b '%y", startDate)
          }

          return card({
            date: formattedDate,
            breakDownValues: [
              breakDownValue({
                name: 'Value',
                value: `${this.y.toFixed(1)} ${controller.optionsValue.unit}`,
                cssClass: 'bg-blue-400/50',
                type: 'box'
              }),
              breakDownValue({
                name: `${controller.optionsValue.date_granularity}ly avg`,
                value: `${controller.optionsValue.average.toFixed(1)} ${controller.optionsValue.unit}`,
                cssClass: 'text-blue-800 dark:text-blue-300',
                type: 'dash'
              })
            ]
          })
        }
      }
    }
  }
}
