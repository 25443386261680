import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'

export default class CompaniesSearchController extends Controller {
  static targets = ['item']
  static debounces = [
    {
      name: 'search',
      wait: 500
    }
  ]

  connect () {
    useDebounce(this)
  }

  search (event) {
    const searchText = event.target.value

    if (searchText.length === 0) {
      //  if search text is empty, remove hidden class from all rows
      for (const row of this.itemTargets) {
        if (row.classList.contains('hidden')) row.classList.remove('hidden')
      }
      return
    }

    //  loop through rows
    for (const row of this.itemTargets) {
      //  if row contains search text, remove hidden class
      if (row.dataset.companyName?.toLowerCase().includes(searchText.toLowerCase())) {
        if (row.classList.contains('hidden')) row.classList.remove('hidden')
      } else if (!row.classList.contains('hidden')) {
        row.classList.add('hidden')
      }
    }
  }
}
