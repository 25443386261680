import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize () {
    this.allOptionChecked = true
  }

  legendAllOptionClicked (event) {
    const controller = this
    this.allOptionChecked = !this.allOptionChecked
    const currentTarget = event.currentTarget
    currentTarget.classList.remove('cursor-pointer')
    currentTarget.classList.add('cursor-wait')
    const chartController = event.currentTarget.dataset.chartController

    // need the timeout to show the loading cursor without waiting for the rest of the function to execute
    setTimeout(function () {
      const evt = new CustomEvent(`${chartController}-legend-all-option-select`, {
        detail: {
          state: controller.allOptionChecked
        }
      })
      window.dispatchEvent(evt)

      document.querySelectorAll('.legend-checkbox').forEach(function (divSquare) {
        if (controller.allOptionChecked) {
          divSquare.querySelector('svg')?.classList?.remove('hidden')
          divSquare.dataset.bgColor.split(' ').forEach(htmlClass => {
            divSquare.classList.add(htmlClass)
          })
          divSquare.dataset.disabledClasses.split(' ').forEach(htmlClass => {
            divSquare.classList.remove(htmlClass)
          })
        } else {
          divSquare.querySelector('svg')?.classList?.add('hidden')
          divSquare.dataset.bgColor.split(' ').forEach(htmlClass => {
            divSquare.classList.remove(htmlClass)
          })
          divSquare.dataset.disabledClasses.split(' ').forEach(htmlClass => {
            divSquare.classList.add(htmlClass)
          })
        }
      })

      currentTarget.querySelector('svg')?.classList?.toggle('hidden')

      currentTarget.classList.remove('cursor-wait')
      currentTarget.classList.add('cursor-pointer')
    }, 0)
  }

  legendClicked (event) {
    const divSquare = event.currentTarget.querySelector('.legend-checkbox')
    divSquare.dataset.bgColor.split(' ').forEach(htmlClass => {
      divSquare.classList.toggle(htmlClass)
    })
    divSquare.dataset.disabledClasses.split(' ').forEach(htmlClass => {
      divSquare.classList.toggle(htmlClass)
    })
    divSquare.querySelector('svg')?.classList?.toggle('hidden')

    const evt = new CustomEvent(`${event.currentTarget.dataset.chartController}-legend-select`, {
      detail: {
        name: event.currentTarget.dataset.name
      }
    })
    window.dispatchEvent(evt)
  }

  legendMouseOver (event) {
    const evt = new CustomEvent(`${event.currentTarget.dataset.chartController}-legend-mouse-over`, {
      detail: {
        name: event.currentTarget.dataset.name
      }
    })
    window.dispatchEvent(evt)
  }

  legendMouseOut (event) {
    const evt = new CustomEvent(`${event.currentTarget.dataset.chartController}-legend-mouse-out`, {})
    window.dispatchEvent(evt)
  }
}
