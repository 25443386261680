import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { enabled: Boolean }
  static targets = ['toggle', 'start', 'end']

  initialize () {
    this.dayToggle = this.toggleTarget.querySelector('input[type="checkbox"]')

    if (this.enabledValue) {
      this.enableDay()
    } else {
      this.disableDay()
    }
    this.attachListener()
  }

  attachListener () {
    this.dayToggle.addEventListener('change', (evt) => {
      evt.target.checked ? this.enableDay() : this.disableDay()
    })
  }

  disableDay () {
    this.enabledValue = false
    this.disableTimeControls()
  }

  disableTimeControls () {
    this.startTarget.disabled = true
    this.endTarget.disabled = true
  }

  enableDay () {
    this.enabledValue = true
    // set min and max to constrain the time options
    this.startTarget.setAttribute('max', this.endTarget.value)
    this.endTarget.setAttribute('min', this.startTarget.value)
    // enable the time controls
    this.startTarget.disabled = false
    this.endTarget.disabled = false
  }
}
