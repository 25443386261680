import { Controller } from '@hotwired/stimulus'

export default class ModalController extends Controller {
  static targets = ['title', 'innerTurbo']
  static values = { name: String, open: Boolean }

  connect () {
    if (this.openValue) {
      this.element.showModal()
    }
  }

  openModal (event) {
    if (event?.detail?.modalId === this.nameValue) {
      this.element.showModal()

      if (this.hasTitleTarget && event.detail.title) {
        this.titleTarget.innerText = event.detail.title
      }

      if (this.hasInnerTurboTarget && event.detail.params) {
        let params = {}
        try {
          params = JSON.parse(event.detail.params)
          const urlObj = new URL(this.innerTurboTarget.src)
          Object.keys(params).forEach((key) => {
            urlObj.searchParams.set(key, params[key])
          })
          this.innerTurboTarget.src = urlObj.href
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  closeModal () {
    this.element.close()
  }

  handleClickOutside (event) {
    // the backdrop is the element itself. Clicking inside the dialog will have a target of the inner element
    if (event.target === this.element) {
      this.element.close()
    }
  }
}
