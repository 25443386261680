import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="labor-cost-edit-form"
export default class extends Controller {
  static targets = ['form', 'costInput', 'submitButton', 'footer', 'cancelButton']
  static values = { cost: Array }

  connect () {
    if (this.hasSubmitButtonTarget) { this.submitButtonTarget.disabled = true }
  }

  updateCost (event) {
    event.preventDefault()
    const cost = this.strToNumber(event.currentTarget.value)
    event.currentTarget.value = cost.toLocaleString()

    this.showFooter()
  }

  incrementCost (event) {
    event.preventDefault()
    const cost = this.strToNumber(event.currentTarget.value) + 1
    event.currentTarget.value = cost.toLocaleString()
    this.showFooter()
  }

  decrementCost (event) {
    event.preventDefault()
    const cost = this.strToNumber(event.currentTarget.value) - 1
    event.currentTarget.value = cost === -1 ? '0' : cost.toLocaleString()
    this.showFooter()
  }

  showFooter () {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = false
      this.submitButtonTarget.classList.remove('hidden')
    }

    if (this.hasCancelButtonTarget) {
      this.cancelButtonTarget.classList.remove('hidden')
    }

    if (this.hasFooterTarget) {
      const showClasses = ['py-4', 'visible', 'max-h-screen', 'opacity-100']
      const hideClasses = ['py-0', 'invisible', 'max-h-0', 'opacity-0']
      if (this.footerTarget.classList.contains('invisible')) {
        this.footerTarget.classList.remove(...hideClasses)
        this.footerTarget.classList.add(...showClasses)
      }
    }
  }

  discardChanges () {
    this.costInputTargets.forEach((target, index) => {
      target.value = this.costValue[index].toLocaleString()
    })
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true
    }
  }

  strToNumber (numberStr) {
    if (numberStr === '') return ''
    numberStr = numberStr.replaceAll(/[\D]/g, '')
    if (numberStr === '') return ''
    return Number(numberStr)
  }

  submit () {
    // had to add this since disable with data-disable-with was not working
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.innerText = 'Saving...'
      this.submitButtonTarget.disabled = true
    }
    this.formTarget.submit()
  }
}
