import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="labor-cost-inline-form"
export default class extends Controller {
  static targets = ['tooltip']

  connect () {
    this.tableHeight = this.element.closest('table')?.getBoundingClientRect()?.height
    this.tooltipHeight = (this.tooltipTarget.children.length * 27) + 32

    this.element.addEventListener('mouseover', this.hover(this))
    this.element.addEventListener('mouseout', this.hoverOut(this))
  }

  disconnect () {
    this.element.removeEventListener('mouseover', this.hover(this))
    this.element.removeEventListener('mouseout', this.hoverOut(this))
  }

  hover (controller) {
    return () => {
      controller.tooltipTarget.classList.remove('hidden')
      controller.setTooltipPosition(controller)
    }
  }

  hoverOut (controller) {
    return () => {
      controller.tooltipTarget.classList.add('hidden')
    }
  }

  setTooltipPosition (controller) {
    const { height, top, x, y } = controller.element.getBoundingClientRect()

    const fixToBottom = controller.tableHeight - (top + height) < controller.tooltipHeight && top > controller.tooltipHeight

    if (fixToBottom) {
      controller.tooltipTarget.style.top = `${y - controller.tooltipHeight + height}px`
      controller.tooltipTarget.style.left = `${x + 120}px`
    } else {
      controller.tooltipTarget.style.top = `${y}px`
      controller.tooltipTarget.style.left = `${x + 120}px`
    }
  }
}
