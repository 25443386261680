import { Controller } from '@hotwired/stimulus'
export default class GitHubStatusController extends Controller {
  connect () {
    fetch('https://www.githubstatus.com/api/v2/components.json')
      .then(response => response.json())
      .then(data => {
        const outageComponent = data.components.find(({ name, status }) => {
          return ['Pull Requests', 'API Requests', 'Actions', 'Webhooks'].includes(name) && status !== 'operational'
        })
        if (outageComponent) {
          this.element.classList.remove('hidden')
        }
      })
  }
}
