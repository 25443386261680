import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggle', 'loadMoreButton']
  static classes = ['addRemove', 'toggle', 'toggleButton']
  static values = {
    isVisible: { type: Boolean, default: false },
    toggleLoadMoreButton: { type: Boolean, default: false }
  }

  toggle (event) {
    this.isVisibleValue = !this.isVisibleValue
    this.render(event)
  }

  hide (event) {
    this.isVisibleValue = false
    this.render(event)
  }

  show (event) {
    this.isVisibleValue = true
    this.render(event)
  }

  render (event) {
    if (this.isVisibleValue) {
      this.toggleTarget.classList.remove(...this.toggleClasses)
      this.toggleTarget.classList.remove(...this.addRemoveClasses)
    } else {
      this.toggleTarget.classList.add(...this.toggleClasses)
      this.toggleTarget.classList.add(...this.addRemoveClasses)
    }

    if (this.toggleLoadMoreButtonValue && this.hasLoadMoreButtonTarget) {
      this.loadMoreButtonTarget.classList.toggle('hidden')
    }

    if (this.hasToggleButtonClasses) event.currentTarget.classList.toggle(...this.toggleButtonClasses)
  }
}
