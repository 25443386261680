import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['outlook', 'google']

  updateSelection (e) {
    switch (e.target.value) {
      case 'Microsoft Outlook':
        this.outlookTarget.classList.remove('hidden')
        this.googleTarget.classList.add('hidden')
        break
      case 'Google Calendar':
        this.outlookTarget.classList.add('hidden')
        this.googleTarget.classList.remove('hidden')
        break
      default:
        break
    }
  }
}
