import BaseGraph from './base_graph_controller'
import { card, primaryValue } from '../helpers/tooltip_helper'

export default class DeploymentRankedBarGraph extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    let total = 0
    const series = [{ data: [] }]
    if (controller.parsedGraphData.series.length) {
      series[0].data = controller.parsedGraphData.series
      total = controller.parsedGraphData.series.reduce((acc, curr) => acc + curr.y, 0)
    }

    // horizontal bar chart max settings
    const maxSettings = {
      min: 0,
      max: controller.parsedGraphData.max || undefined,
      tickAmount: controller.parsedGraphData.unit !== '%' ? 4 : undefined
    }

    return {
      chart: {
        type: 'bar',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        events: {
          click: function (e) {
            if (e.target.dataset.name === 'drilldown' && controller.optionsValue.metrics_url) {
              controller.openTurboStream(controller.optionsValue.metrics_url, {
                git_user_group_id: controller.optionsValue.git_user_group_id,
                repository_names: e.target.dataset.value,
                metric: controller.optionsValue.metric,
                date_range: controller.optionsValue.date_range,
                date_granularity: controller.optionsValue.date_granularity
              })
            }
          }
        }
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: controller.parsedGraphData.categories,
        lineWidth: 0,
        gridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
        labels: {
          overflow: 'justify',
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (total === 0) {
              return ''
            }

            return this.value
          }
        }
      },
      yAxis: {
        title: {
          enabled: false
        },
        labels: {
          overflow: 'allow',
          y: 38,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          useHTML: true,
          formatter: function () {
            if (controller.parsedGraphData.unit !== '%' && this.value !== 0 && total === 0) {
              return '--'
            }
            let axisLabel = `${this.value} ${controller.parsedGraphData.unit}`
            if (controller.parsedGraphData.unit === '%') {
              axisLabel = `${this.value}${controller.parsedGraphData.unit}`
            }
            return axisLabel
          }
        },
        lineWidth: 0,
        gridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
        ...maxSettings
      },
      plotOptions: {
        gridLineWidth: 0,
        bar: {
          grouping: false,
          pointWidth: 20,
          dataLabels: {
            enabled: false
          },
          minPointLength: 6,
          color: controller.darkMode ? controller.tailwindColors.blue[700] : controller.tailwindColors.blue[200],
          states: {
            hover: {
              color: controller.darkMode ? controller.tailwindColors.blue[900] : controller.tailwindColors.blue[300]
            }
          }
        },
        series: {
          stickyTracking: true,
          borderRadius: 4,
          borderWidth: 0,
          cursor: 'pointer',
          point: {
            events: {
              click: function () {
                controller.openTurboStream(controller.optionsValue.metrics_url, {
                  git_user_group_id: controller.optionsValue.git_user_group_id,
                  repository_names: this.category,
                  metric: controller.optionsValue.metric,
                  date_range: controller.optionsValue.date_range,
                  date_granularity: controller.optionsValue.date_granularity
                })
              }
            }
          }
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: true,
        formatter: function () {
          if (total === 0) {
            return null
          }

          let value = `${this.y} ${controller.parsedGraphData.unit}`
          if (controller.parsedGraphData.unit === '%') {
            value = `${this.y}${controller.parsedGraphData.unit}`
          }
          return card({
            header: `<div class="text-gray-600 dark:text-gray-400">${this.key}</div>`,
            footer: controller.optionsValue.metrics_url && `<button data-value="${this.key}" data-name="drilldown" class="btn-tertiary text-xs mt-2 w-full">View deployments</button>`,
            primaryValues: [
              primaryValue({ name: controller.parsedGraphData.name, value })
            ]
          })
        }
      },
      series
    }
  }
}
