import { Controller } from '@hotwired/stimulus'

export default class PortfolioTableHoverController extends Controller {
  static outlets = ['portfolio-overview-chart']

  rowMouseOver (event) {
    const orgId = parseInt(event.currentTarget.dataset.orgId)
    if (!orgId) return

    if (this.hasPortfolioOverviewChartOutlet) {
      this.portfolioOverviewChartOutlet.legendMouseOver(orgId)
    }
  }

  rowMouseOut (event) {
    const orgId = parseInt(event.currentTarget.dataset.orgId)
    if (!orgId) return

    if (this.hasPortfolioOverviewChartOutlet) {
      this.portfolioOverviewChartOutlet.legendMouseOut(orgId)
    }
  }
}
