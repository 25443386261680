import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class KpiReportFeaturesDeliveredGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const seriesKeys = [
      {
        key: 'features',
        name: 'Features',
        color: this.categoricalColors[1],
        type: 'column',
        yAxis: 0
      },
      {
        key: 'features_per_engineer',
        name: 'Features per developer',
        color: controller.tailwindColors.blue[500],
        type: 'spline',
        yAxis: 1
      }
    ]

    controller.tooltipValues = {}

    controller.parsedGraphData.categories.forEach((category, i) => {
      controller.tooltipValues[category] = { ...controller.parsedGraphData.data[i] }
    })
    const lastBarColorConfig = {
      bg: this.categoricalColors[1],
      hover: controller.darkMode ? controller.tailwindColors.blue[900] : controller.tailwindColors.blue[300]
    }

    let fpeTotal = 0
    let totalFeaturesTotal = 0
    const barConfig = {
      stacking: 'normal',
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5
    }
    const series = seriesKeys
      .map((config, i) => {
        const seriesData = controller.parsedGraphData.data.map((data) => data.series[config.key])
        let seriesConfig = {}
        if (config.key === 'features_per_engineer') {
          fpeTotal = seriesData.reduce((acc, curr) => acc + curr, 0)
        } else {
          totalFeaturesTotal = seriesData.reduce((acc, curr) => acc + curr, 0)
          if (controller.parsedGraphData.partial_end_data) {
            seriesData[seriesData.length - 1] = { y: seriesData[seriesData.length - 1], ...this.getLastDataPointPattern(lastBarColorConfig) }
          }
          seriesConfig = {
            ...barConfig
          }
        }

        return {
          data: seriesData,
          states: { hover: { color: config.color } },
          lineColor: config.color,
          zIndex: 1,
          marker: {
            enabled: false
          },
          stacking: 'normal',
          ...seriesConfig,
          ...config
        }
      })

    const fpeMaxSettings = this.getYAxisMaxAndTickAmountSettings(fpeTotal)
    const totalFeaturesMaxSettings = this.getYAxisMaxAndTickAmountSettings(totalFeaturesTotal)
    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 30,
        spacingRight: 3,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: controller.parsedGraphData.categories,
        title: { text: null },
        tickLength: 0,
        tickPositions: this.getTickPositions(controller.parsedGraphData.categories?.length || 0),
        labels: {
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: [
        {
          title: { text: null },
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && totalFeaturesTotal === 0) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          ...totalFeaturesMaxSettings
        },
        {
          title: { text: null },
          gridLineWidth: 0,
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && fpeTotal === 0) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          opposite: true,
          ...fpeMaxSettings
        }
      ],
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.025,
          groupPadding: 0,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5
        },
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          states: {
            hover: {
              color: controller.darkMode
                ? controller.tailwindColors.blue[900]
                : controller.tailwindColors.blue[300]
            }
          },
          stickyTracking: false,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(new Date(controller.tooltipValues[this.name]).toISOString().split('T')[0])
                }
              }
            }
          }
        }
      },
      colors: [
        controller.tailwindColors.blue[200],
        controller.tailwindColors.orange[200],
        controller.tailwindColors.teal[200]
      ],
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: true,
        shared: true,
        formatter: function () {
          const showPercentage = this.points.length > 2
          const breakDownValues = this.points.reverse().map((p) => {
            const color = p.color.pattern ? p.color.pattern.color : p.color
            if (p.series.name === 'No data') {
              return null
            }
            const percentDisplay = showPercentage ? ` (${p.percentage.toFixed(1)}%)` : ''
            return breakDownValue({
              name: p.series.name,
              style: `background-color: ${color};`,
              value: p.series.name === 'Features per developer' ? p.y.toFixed(1) : `${p.y}${percentDisplay}`,
              type: p.series.name === 'Features per developer' ? 'line' : 'box'
            })
          }).reverse().filter(p => p)

          return card({
            date: controller.tooltipValues[this.x].series.date_range_display,
            breakDownValues
          })
        }
      }
    }
  }

  getTickPositions (categoryLength) {
    let tickPositions
    if (categoryLength >= 12) {
      // show only 5 labels
      categoryLength -= 1
      tickPositions = [0, Math.round(categoryLength * 0.25), Math.round(categoryLength * 0.50), Math.round(categoryLength * 0.75), categoryLength]
    }
    return tickPositions
  }
}
