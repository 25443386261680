import { Controller } from '@hotwired/stimulus'

export default class PortfolioSetPrivacyController extends Controller {
  static targets = [
    'visibleOption',
    'anonymousOption',
    'form',
    'visibleHero',
    'anonymousHero'
  ]

  static values = { requiresShareData: Boolean }

  connect () {
    if (this.requiresShareDataValue) {
      this.setVisible(false)
    } else {
      this.setAnonymous(false)
    }
  }

  setVisible (submit = true) {
    this.visibleOptionTarget.classList.add('border-blue-500')
    this.anonymousOptionTarget.classList.remove('border-blue-500')
    this.visibleHeroTarget.classList.remove('hidden')
    this.anonymousHeroTarget.classList.add('hidden')
    if (submit) {
      this.submitForm()
    }
  }

  setAnonymous (submit = true) {
    this.visibleOptionTarget.classList.remove('border-blue-500')
    this.anonymousOptionTarget.classList.add('border-blue-500')
    this.visibleHeroTarget.classList.add('hidden')
    this.anonymousHeroTarget.classList.remove('hidden')

    if (submit) {
      this.submitForm()
    }
  }

  submitForm () {
    this.formTarget.requestSubmit()
  }
}
