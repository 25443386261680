import { Controller } from '@hotwired/stimulus'
import { setUrlParams } from '../helpers/url_helper'

// Connects to data-controller="comparison-show"
export default class extends Controller {
  static targets = ['tab', 'chartView', 'table']
  static values = {
    tabName: { type: String, default: 'features_per_developer' }
  }

  static classes = ['activeTab', 'inactiveTab', 'activeButton', 'inactiveButton']

  initialize () {
    // check dataset tabName for each chart and store it in a dictionary
    this.tabNameToChartView = {}
    this.tabNameToTable = {}
  }

  tabTargetConnected (tab) {
    if (tab.dataset.tabName === this.tabNameValue) {
      tab.classList.remove(...this.inactiveTabClasses)
      tab.classList.add(...this.activeTabClasses)
    } else {
      tab.classList.remove(...this.activeTabClasses)
      tab.classList.add(...this.inactiveTabClasses)
    }
  }

  chartViewTargetConnected (chart) {
    const tabName = chart.dataset.tabName
    chart.classList.toggle('hidden', tabName !== this.tabNameValue)
    this.tabNameToChartView[tabName] = chart
  }

  tableTargetConnected (table) {
    const tabName = table.dataset.tabName
    table.classList.toggle('hidden', tabName !== this.tabNameValue)
    this.tabNameToTable[table.dataset.tabName] = table
  }

  tabClicked (event) {
    event.preventDefault()
    // deactivate all tabs
    this.tabTargets.forEach(tab => {
      tab.classList.remove(...this.activeTabClasses)
      tab.classList.add(...this.inactiveTabClasses)
    })
    // activate the clicked tab
    event.target.classList.remove(...this.inactiveTabClasses)
    event.target.classList.add(...this.activeTabClasses)

    // show the tab view that corresponds to the clicked tab
    this.tabNameValue = event.target.dataset.tabName

    // hide all tab views
    this.chartViewTargets?.forEach(chart => { chart.classList.add('hidden') })
    this.tableTargets?.forEach(table => { table.classList.add('hidden') })

    // show the tab view that corresponds to the selected tab
    this.tabNameToChartView[this.tabNameValue]?.classList?.remove('hidden')
    this.tabNameToTable[this.tabNameValue]?.classList?.remove('hidden')

    // update the url
    setUrlParams('breakdown', this.tabNameValue)
  }
}
