import BaseGraph from './base_graph_controller'
import { card, primaryValue, breakDownValue } from '../helpers/tooltip_helper'

export default class TeamLeadTimeGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    controller.company_report = controller.optionsValue.company_report === true
    controller.dateFormat = controller.company_report ? '%b \'%y' : '%b %e'

    const sequenceColors = controller.darkMode ? controller.tailwindColors['sequence-dark'] : controller.tailwindColors['sequence-light']

    controller.seriesKeys = [
      {
        key: 'development',
        name: 'Development',
        color: sequenceColors[1]
      },
      {
        key: 'first_review',
        name: 'First Review',
        color: sequenceColors[2]
      },
      {
        key: 'first_approval',
        name: 'First Approval',
        color: sequenceColors[3]
      },
      {
        key: 'merge',
        name: 'Merge',
        color: sequenceColors[4]
      },
      {
        key: 'deploy',
        name: 'Deploy',
        color: sequenceColors[5]
      }
    ]

    const series = controller.seriesKeys.map((config, i) => {
      const data = controller.parsedGraphData[config.key]
      const zones = []
      if (!controller.company_report) {
        zones.push(
          {
            value: data.length > 2 ? data[data.length - 2][0] : null,
            fillColor: config.color,
            color: controller.darkMode ? controller.tailwindColors.gray[800] : 'white'
          },
          {
            color: controller.darkMode ? controller.tailwindColors.gray[800] : 'white',
            fillColor: {
              pattern: {
                path: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
                width: 4,
                height: 4,
                color: config.color
              }
            }
          }
        )
      }
      return {
        data,
        name: config.name,
        fillColor: config.color,
        color: controller.darkMode ? controller.tailwindColors.gray[800] : 'white',
        lineWidth: 2,
        zIndex: 1,
        marker: {
          enabled: false,
          symbol: 'circle',
          fillColor: config.color,
          lineColor: controller.darkMode ? controller.tailwindColors.gray[800] : 'white',
          lineWidth: 1
        },
        stacking: 'normal',
        legendIndex: i,
        zoneAxis: 'x',
        zones
      }
    })

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(controller.parsedGraphData.max)

    return {
      chart: {
        type: 'areaspline',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        type: 'datetime',
        title: { text: null },
        tickLength: 0,
        labels: {
          autoRotation: false,
          padding: 0,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () { return Highcharts.dateFormat(controller.dateFormat, this.value) }
        },
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300]
      },
      yAxis: [
        {
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          title: { text: null },
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && !controller.parsedGraphData.max) {
                return '--'
              } else {
                return `${this.value}d`
              }
            }
          },
          reversedStacks: false,
          ...maxSettings
        }
      ],
      plotOptions: {
        series: {
          stickyTracking: true,
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          stacking: 'stream',
          cursor: controller.optionsValue.metrics_url ? 'pointer' : undefined,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(new Date(this.x).toISOString().split('T')[0])
                }
              }
            }
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        stickOnContact: true,
        formatter: function () {
          const breakDownValues = this.points.map((p) => {
            const type = p.series.name === 'Weekly avg' ? 'dash' : 'box'
            const color = type === 'dash' ? p.color : p.series.options.fillColor

            return breakDownValue({
              name: p.series.name,
              value: p.y === null ? '--' : controller.daysOrHoursString(p.y),
              style: type === 'box' ? `background-color: ${color};` : `color: ${color}`,
              type
            })
          })
          const total = this.points.reduce((acc, p) => {
            if (p.series.name !== 'Weekly avg') {
              return acc + p.y
            }
            return acc
          }, 0)
          return card({
            date: controller.tooltipDateDisplay(this.x),
            primaryValues: [
              primaryValue({ name: 'Lead Time', value: controller.daysOrHoursString(total) })
            ],
            incomplete: !controller.company_report && this.points[0].point.index === this.points[0].series.data.length - 1,
            footer: controller.optionsValue.metrics_url && `<button data-value="${this.x}" data-action="click->team-lead-time-graph#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View pull requests</button>`,
            breakDownValues
          })
        }
      }
    }
  }

  tooltipDateDisplay (date) {
    if (!this.company_report) {
      const endDate = (date + (6 * 24 * 60 * 60 * 1000))
      return `${Highcharts.dateFormat(this.dateFormat, date)}` + ' - ' + `${Highcharts.dateFormat(this.dateFormat, endDate)}`
    } else {
      return Highcharts.dateFormat(this.dateFormat, date)
    }
  }
}
