import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['search']

  connect () {
    this.rows = Array.from(this.element.getElementsByClassName('table-row'))
    this.searchTimeout = null
  }

  search () {
    clearTimeout(this.searchTimeout)

    this.searchTimeout = setTimeout(() => {
      this.rows.forEach((r) => {
        if (r.textContent.toLowerCase().includes(this.searchTarget.value.toLowerCase())) {
          r.classList.remove('hidden')
        } else {
          r.classList.add('hidden')
        }
      })
    }, 400)
  }
}
