import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'reloadButton',
    'loadingMessage'
  ]

  reloadChannels () {
    this.loadingMessageTarget.classList.remove('hidden')
    this.loadingMessageTarget.classList.add('flex', 'items-center')
    this.reloadButtonTarget.classList.add('hidden')
  }
}
