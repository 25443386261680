import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    timeout: Number,
    link: String,
    linkText: String,
    persistenceHandle: String
  }

  connect () {
    const controller = this
    this.derivePersistedVisibility()

    if (this.timeoutValue && this.timeoutValue !== 0) {
      setTimeout(() => {
        this.close.apply(controller)
      }, this.timeoutValue * 1000)
    }

    if (this.linkValue) {
      this.element.addEventListener('click', this.followLink.bind(this))
    }
  }

  derivePersistedVisibility () {
    if (!this.hasPersistenceHandleValue || !!this.persistenceHandleValue === false) return

    if (localStorage.getItem(this.makeLSKey(this.persistenceHandleValue))) {
      this.element.classList.add('hidden')
    }
  }

  persistClose () {
    if (!this.hasPersistenceHandleValue || !!this.persistenceHandleValue === false) return
    localStorage.setItem(this.makeLSKey(this.persistenceHandleValue), true)
  }

  makeLSKey (value) {
    return `flash_dismissed:${value}`
  }

  close (evt) {
    // cancel the bubble so we dont trigger a link click
    if (evt) evt.stopPropagation()
    this.persistClose()
    this.element.classList.add('opacity-0')
    const closeDuration = evt?.params?.closeDuration || 500
    setTimeout(() => { this.element.classList.add('hidden') }, closeDuration)
  }

  followLink () {
    // If linkText is set, it is an external link and
    // should open in a new page (target: "_blank")

    // If linkText is not set and linkValue is set, redirect to the new page.
    if (this.linkTextValue) {
      window.open(this.linkValue)
    } else {
      window.location.href = this.linkValue
    }
  }
}
