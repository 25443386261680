import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="manage-git-user-group-repositories"
export default class extends Controller {
  static targets = [
    'checkbox',
    'checkboxAll',
    'countSelected',
    'filteredCount',
    'filteredCountSelected',
    'table',
    'noSearchResultsRow'
  ]

  initialize () {
    this.rowCount = 0
    this.checkedCount = 0
    this.filteredAllCount = 0
    this.filteredCheckedCount = 0
    this.initCheckedCount = 0
    this.showAllRowsState = true
    this.searchText = ''
    this.rowStates = {}
  }

  // main table setup and update methods
  tableTargetConnected () {
    const rows = Array.from(this.tableTarget.rows).slice(1) // slice(1) to remove the header row
    this.rowCount = rows.length - 1 // -1 for the no search results row
    this.filteredAllCount = this.rowCount

    rows.forEach(row => {
      const dataset = row.dataset
      if (!dataset.repositoryId) return // no data row

      const checked = dataset.checked === 'true'

      // Store the state of the row in the rowStates object
      this.rowStates[dataset.repositoryId] = {
        repositoryId: dataset.repositoryId,
        visible: true,
        searchable: dataset.searchable.toLowerCase(),
        checked,
        row
      }

      if (checked) this.checkedCount++
    })

    this.initCheckedCount = this.checkedCount
    this.filteredCheckedCount = this.checkedCount

    this.setCountText()
  }

  setCountText () {
    if (this.hasCountSelectedTarget) this.countSelectedTargets.forEach(t => { t.innerText = this.checkedCount })
    if (this.hasFilteredCountTarget) this.filteredCountTarget.innerText = this.filteredAllCount
    if (this.hasFilteredCountSelectedTarget) this.filteredCountSelectedTarget.innerText = this.filteredCheckedCount
  }

  resetCheckedCount () {
    this.checkedCount = this.initCheckedCount
    this.filteredCheckedCount = this.initCheckedCount
    this.filteredAllCount = this.rowCount
    this.setCountText()
  }

  resetCountsToZeros () {
    this.rowCount = 0
    this.checkedCount = 0
    this.filteredAllCount = 0
    this.filteredCheckedCount = 0
    this.initCheckedCount = 0
    this.showAllRowsState = true
  }

  checkBoxClicked (event) {
    const checked = event.currentTarget.checked

    // If the checkbox is checked, increment the checkedCount and filteredCheckedCount else decrement them
    this.checkedCount += checked ? 1 : -1
    this.filteredCheckedCount += checked ? 1 : -1

    // checkbox.value is the gitUserId
    this.rowStates[event.currentTarget.value].checked = checked

    this.setCountText()
  }

  selectAllClicked (event) {
    const checkAll = event.currentTarget.checked
    for (const checkbox of this.checkboxTargets) {
      // checkbox.value is the gitUserId
      const rowState = this.rowStates[checkbox.value]
      // only update visible rows
      if (!rowState.visible) continue

      // only update the checked count and state if the checkbox state is different from the checkAll state
      if (checkbox.checked !== checkAll) {
        checkbox.checked = checkAll
        rowState.checked = checkAll
        this.checkedCount += checkAll ? 1 : -1
        this.filteredCheckedCount += checkAll ? 1 : -1
      }
    }

    this.setCountText()
  }

  // filter methods
  searchFilter (event) {
    this.searchText = event.target.value.toLowerCase()
    this.applyFilters()
  }

  setStateFilter (event) {
    this.showAllRowsState = event.target.value === 'all'
    this.checkboxAllTarget.checked = !this.showAllRowsState
    this.applyFilters()
  }

  applyFilters () {
    this.filteredCheckedCount = 0
    this.filteredAllCount = 0

    let hiddenCount = 0
    for (const [, rowState] of Object.entries(this.rowStates)) {
      // If the row is checked or showAllRowsState is true, show the row
      const stateBasedDecision = this.showAllRowsState || rowState.checked
      // If there's no search text, or if the row's searchable text includes the search text, show the row
      const searchBasedDecision = this.searchText.length === 0 || rowState.searchable.includes(this.searchText)

      const show = stateBasedDecision && searchBasedDecision

      rowState.visible = show

      // add hidden if show is false
      rowState.row.classList.toggle('hidden', !show)

      if (searchBasedDecision) this.filteredAllCount++

      if (show && rowState.checked) {
        // If the row is shown and checked, increment the count of selected rows
        this.filteredCheckedCount++
      } else if (!show) {
        // If the row is not shown, increment the count of hidden rows
        hiddenCount++
      }
    }

    // If all rows are hidden, show the no search results row
    this.noSearchResultsRowTarget.classList.toggle('hidden', hiddenCount !== this.rowCount)

    this.setCountText()
  }

  resetFilters () {
    for (const checkbox of this.checkboxTargets) {
      checkbox.checked = false
      this.rowStates[checkbox.value].checked = false
    }
    this.showAllRowsState = true
    this.resetCheckedCount()

    this.applyFilters()
  }
}
