import BaseGraph from './base_graph_controller'
import { card, primaryValue } from '../helpers/tooltip_helper'

export default class ChangeSizeGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const borderRadius = 5
    const maxIndex = controller.parsedGraphData.data.length - 1
    // sort the data by the time value
    this.parsedGraphData.data.sort((a, b) => a[0] - b[0])
    const tickInterval = this.getTickInterval(controller.parsedGraphData.data)

    const total = this.parsedGraphData.data.reduce((acc, curr) => {
      return acc + curr[1]
    }, 0)
    const seriesData = controller.parsedGraphData.data.map((data, i) => {
      if (i === maxIndex) {
        return {
          x: data[0],
          y: data[1],
          color: {
            pattern: {
              path: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
              width: 4,
              height: 4,
              color: controller.darkMode ? controller.tailwindColors.blue[800] : controller.tailwindColors.blue[200]
            }
          },
          states: {
            hover: {
              color: controller.darkMode ? controller.tailwindColors.blue[800] : controller.tailwindColors.blue[200]
            }
          }
        }
      } else {
        return {
          x: data[0],
          y: data[1]
        }
      }
    })

    const series = [
      {
        name: 'Change Size',
        data: seriesData,
        zIndex: 1,
        borderRadiusTopLeft: borderRadius,
        borderRadiusTopRight: borderRadius,
        minPointLength: 0,
        color: controller.darkMode ? controller.tailwindColors.blue[800] : controller.tailwindColors.blue[200],
        states: {
          hover: {
            color: controller.darkMode ? controller.tailwindColors.blue[900] : controller.tailwindColors.blue[300]
          }
        },
        marker: { enabled: false }
      }
    ]

    // if the total is 0, then add it to the zeroes series for min point height
    for (let i = 0; i < seriesData.length; i++) {
      if (seriesData[i].y === 0) {
        if (series.length === 1) {
          // add the zeroes series for min point height
          this.addNoDataSeriesToSeries(series)
        }
        series[1].data.push({
          x: series[0].data[i].x,
          y: 0
        })
      }
    }

    let plotLines = []
    if (total > 0) {
      plotLines = [
        {
          zIndex: 50,
          color: controller.darkMode ? controller.tailwindColors.blue[200] : controller.tailwindColors.blue[900],
          value: controller.parsedGraphData.weekly_avg,
          dashStyle: 'ShortDash',
          width: 2
        }
      ]
    }

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        title: { text: null },
        tickLength: 0,
        tickInterval: tickInterval,
        type: 'datetime',
        labels: {
          autoRotation: false,
          step: controller.parsedGraphData.data.length > 6 ? 3 : 1,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            return Highcharts.dateFormat(controller.parsedGraphData.date_format, this.value)
          }
        },
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        maxPadding: 0
      },
      yAxis: {
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        title: { text: null },
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && total === 0) {
              return '--'
            } else {
              return this.value
            }
          }
        },
        reversedStacks: false,
        startOnTick: false,
        plotLines,
        ...maxSettings
      },
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 1,
          borderColor: 'transparent',
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5
        },
        series: {
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          cursor: controller.optionsValue.metrics_url ? 'pointer' : undefined,
          stickyTracking: false,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(new Date(this.x).toISOString().split('T')[0], this.y)
                }
              }
            }
          }
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        stickOnContact: true,
        formatter: function () {
          const startOfWeek = new Date(this.x)
          const endOfWeek = startOfWeek.getTime() + (24 * 60 * 60 * 1000 * 6)
          const date = `${Highcharts.dateFormat('%b %e', startOfWeek)} - ${Highcharts.dateFormat('%b %e', endOfWeek)}`

          return card({
            date,
            incomplete: this.points[0].point.index === maxIndex,
            footer: controller.optionsValue.metrics_url && `<button data-value="${this.x}" data-y-value="${Math.round(this.y)}" data-action="click->change-size-graph#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View pull requests</button>`,
            primaryValues: [
              primaryValue({ name: 'Pull Request Size', value: `${controller.getNumericDisplay(this.y)} lines` })
            ]
          })
        },
        style: {
          zIndex: 100
        }
      },
      series
    }
  }
}
