import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="sql-like-tag-matcher"
export default class extends Controller {
  static targets = ['option', 'selectedOption', 'searchSelectHeader']

  findMatches (event) {
    const sqlPattern = event.currentTarget.value.toLowerCase()

    const numberOfMatches = this.optionTargets.map((option) => {
      if (sqlPattern.length > 0 && option.dataset.searchValue.toLowerCase().includes(sqlPattern)) {
        option.classList.remove('hidden')
        return option
      }
      option.classList.add('hidden')
      return null
    }).filter(n => n).length

    if (this.hasSearchSelectHeaderTarget) {
      if (numberOfMatches === 1) {
        this.searchSelectHeaderTarget.innerText = `${numberOfMatches} matching tag`
      } else {
        this.searchSelectHeaderTarget.innerText = `${numberOfMatches} matching tags`
      }
    }
  }
}
