import { Controller } from '@hotwired/stimulus'

export default class GlossaryController extends Controller {
  static targets = ['button', 'content']

  static values = {
    // lift will increase the parent element's z-index to allow stacking to work properly
    lift: Boolean
  }

  initialize () {
    this.buttonTarget.addEventListener('mouseleave', (event) => {
      // only hide if not hovering over contentTarget and buttonTarget wasn't clicked/focused
      if (event.relatedTarget !== this.contentTarget && this.buttonTarget !== document.activeElement) {
        this.hide()
      }
    })

    this.buttonTarget.addEventListener('blur', (event) => {
      if (event.relatedTarget !== this.contentTarget) {
        this.hide()
      }
    })

    this.contentTarget.addEventListener('mouseleave', () => {
      if (this.buttonTarget !== document.activeElement) {
        this.hide()
      }
    })
  }

  setButtonFocus () {
    // Safari browser doesn't default support focus on button
    // Focus it on click and let blur event listener handle removing it
    this.buttonTarget.focus()
  }

  show () {
    setTimeout(() => {
      this.contentTarget.classList.remove('hidden')
      if (this.hasLiftValue) {
        this.element.parentElement.style.zIndex = 100
      }
    }, 200)
  }

  hide () {
    setTimeout(() => {
      this.contentTarget.classList.add('hidden')
      if (this.hasLiftValue) {
        this.element.parentElement.style.zIndex = 1
      }
    }, 200)
  }
}
