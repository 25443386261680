import { Controller } from '@hotwired/stimulus'

export default class GraphLegendController extends Controller {
  static targets = ['chart']

  togglePlotLines () {
    if (!this.hasChartTarget) return

    const evt = new CustomEvent('toggle-plot-lines', {
      detail: {
        controller: this.controllerTargetValue
      }
    })
    this.chartTarget.dispatchEvent(evt)
  }

  legendMouseOver (event) {
    if (!this.hasChartTarget) return

    const name = event.currentTarget.dataset.seriesName
    if (!name) return

    const evt = new CustomEvent('legend-mouse-over', {
      detail: {
        controller: this.controllerTargetValue,
        name: name
      }
    })
    this.chartTarget.dispatchEvent(evt)
  }

  legendMouseOut () {
    if (!this.hasChartTarget) return

    const evt = new CustomEvent('legend-mouse-out', {
      detail: {
        controller: this.controllerTargetValue
      }
    })
    this.chartTarget.dispatchEvent(evt)
  }

  toggleSeries (event) {
    if (!this.hasChartTarget) return

    const name = event.currentTarget.dataset.seriesName
    if (!name) return

    const evt = new CustomEvent('toggle-series', {
      detail: {
        controller: this.controllerTargetValue,
        name: name
      }
    })

    event.currentTarget.classList.toggle('invisible-series')
    this.chartTarget.dispatchEvent(evt)
  }
}
