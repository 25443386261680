import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['start', 'end']

  connect () {
    this.startTarget.addEventListener('change', (evt) => this.handleTimeConstraints('start', evt))
    this.endTarget.addEventListener('change', (evt) => this.handleTimeConstraints('end', evt))
    this.startTarget.addEventListener('keydown', (evt) => this.handleArrows('start', evt))
    this.endTarget.addEventListener('keydown', (evt) => this.handleArrows('end', evt))
  }

  handleArrows (input, evt) {
    if (evt.code === 'ArrowUp') {
      evt.preventDefault()
      evt.target.stepUp()
      // call this manually since the event will not bubble to the change listener after being prevented.
      this.handleTimeConstraints(input, evt)
    } else if (evt.code === 'ArrowDown') {
      evt.preventDefault()
      evt.target.stepDown()
      // call this manually since the event will not bubble to the change listener after being prevented.
      this.handleTimeConstraints(input, evt)
    }
  }

  handleTimeConstraints (input, evt) {
    if (input === 'end') {
      // set min and max to contain the time options
      this.startTarget.setAttribute('max', evt.target.value)
    } else {
      // set min and max to contain the time options
      this.endTarget.setAttribute('min', evt.target.value)
    }
  }
}
