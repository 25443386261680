import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['initialSubmitForm']

  initialize () {
    this.initialSubmitFormTarget.requestSubmit()
  }

  buttonClicked (event) {
    const selectedClasses = ['bg-blue-500/10', 'text-blue-500', 'hover:bg-blue-500/20']
    const unselectedClasses = ['hover:bg-gray-100', 'dark:hover:bg-gray-700']
    // set all buttons to tertiary without borders
    const elements = document.getElementsByClassName('breakdown-button')
    for (const element of elements) {
      element.classList.add(...unselectedClasses)
      element.classList.remove(...selectedClasses)
    }

    // show check icon for current element that was clicked
    event.currentTarget.classList.remove(...unselectedClasses)
    event.currentTarget.classList.add(...selectedClasses)

    // hide data and show loading
    document.getElementById('chart-data').classList.add('hidden')
    document.getElementById('chart-loading').classList.remove('hidden')
    document.getElementById('table-data').classList.add('hidden')
    document.getElementById('table-loading').classList.remove('hidden')

    const searchParams = new URLSearchParams(window.location.search)
    // add new state to url
    if (event.currentTarget.dataset.urlParam) {
      // the breakdown exists
      searchParams.set('breakdown_by', event.currentTarget.dataset.urlParam)
    } else {
      // None case
      searchParams.delete('breakdown_by')
    }
    const url = [
      window.location.protocol,
      '//',
      window.location.host,
      window.location.pathname,
      '?',
      searchParams
    ].join('')

    // front arrow won't work
    history.replaceState({}, null, url)

    // update breakdown_by hidden field tag
    if (event.currentTarget.dataset.urlParam) {
      document.getElementById('breakdown_by').value = event.currentTarget.dataset.urlParam
    } else {
      document.getElementById('breakdown_by').value = null
    }

    document.getElementById('coverage_card').innerHTML = ''
  }
}
