import BaseGraph from './base_graph_controller'

export default class ActiveContributorHistoryGraph extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    return {
      chart: {
        type: 'area',
        backgroundColor: controller.colorTheme.backgroundColor,
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 10,
        events: {
          // just display the 2nd last point's value
          load: function () {
            this.series.forEach(function (series) {
              series.points[series.points.length - 2]?.update({
                dataLabels: {
                  enabled: true,
                  color: controller.tailwindColors.blue[500],
                  style: {
                    textOutline: '0px'
                  },
                  x: -15,
                  borderWidth: 0,
                  formatter: () => {
                    if (series.points) {
                      return `<div class="text-blue text-sm font-medium">${series.points[series.points.length - 2].y
                        }</div>`
                    } else {
                      return 0
                    }
                  }
                },
                marker: {
                  enabled: true,
                  fillColor: controller.tailwindColors.blue[500]
                }
              })
            })
          }
        }
      },
      plotOptions: {
        series: {
          step: 'right',
          pointPlacement: 'on'
        }
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: controller.parsedGraphData.data.map((d) => d[0]),
        title: { text: null },
        tickLength: 0,
        labels: {
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            // just show 'mmm dd' rather than 'mmm dd, yyyy'
            return this.value.split(',')[0]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[500]
      },
      yAxis: {
        title: {
          text: null
        },
        gridLineWidth: 0,
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        plotLines: [
          {
            zIndex: 10,
            color: controller.tailwindColors.blue[500],
            value: controller.optionsValue.current_plan_limit,
            dashStyle: 'Dash',
            width: 2,
            label: {
              style: {
                height: '24px'
              },
              x: 0,
              y: 2,
              verticalAlign: 'middle',
              useHTML: true,
              text: '<div class="text-xs font-semibold px-2 py-0.5 text-blue border-blue-500 bg-white dark:bg-gray-800 border rounded-full">Plan limit</div>'
            }
          }
        ],
        max: Math.max(...controller.parsedGraphData.data.map((d) => d[1]), controller.optionsValue.current_plan_limit) + 2
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'Active contributors',
          data: [...controller.parsedGraphData.data], // assign parsedGraphData.data instead of shallow clone
          zoneAxis: 'x',
          zones: [{
            value: controller.parsedGraphData.data.length - 2
          }, {
            dashStyle: 'ShortDash'
          }],
          lineColor: controller.tailwindColors.blue[500],
          color: controller.tailwindColors.blue[500],
          marker: {
            enabled: false
          },
          type: 'area',
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [
                0,
                Highcharts.color(controller.tailwindColors.blue[500])
                  .setOpacity(controller.darkMode ? 0.3 : 0.2)
                  .get('rgba')
              ],
              [
                1,
                Highcharts.color(controller.tailwindColors.blue[500])
                  .setOpacity(0)
                  .get('rgba')
              ]
            ]
          }
        }
      ],
      tooltip: {
        ...controller.baseToolTipConfig,
        hideDelay: 1000,
        snap: 50,
        style: {
          pointerEvents: 'auto'
        },
        formatter: function () {
          return controller.toolTipWrapper(`
            <p class="text-gray-500 dark:text-gray-300 font-semibold">${this.x}</p>
            <p class="text-black dark:text-white font-semibold text-sm">${this.y} active contributors</p>
            <a
              href="${controller.optionsValue.active_contributor_list_url}?billing_date=${encodeURIComponent(this.x)}"
              class="btn-primary mt-3"
              data-controller="billing"
              data-flyout-id='active_contributor_list'
              data-turbo-frame="active_contributor_list"
              data-action="click->billing#resetActiveContributorListTurboFrameSrc click->application#openFlyout"
            >View active contributors</a>
              `)
        }
      }
    }
  }
}
