import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['orgSelector']

  connect () {
    this.updateSelectedForm()
  }

  updateSelectedForm (event) {
    this.selectedOrgForm = document.getElementById(`connectSlack_${this.orgSelectorTarget.value}`)
  }

  submitOrgSelection (event) {
    this.selectedOrgForm.click()
  }
}
