import { Controller } from '@hotwired/stimulus'

export default class EpicsNavigationController extends Controller {
  navigate (event) {
    const filters = JSON.parse(event.currentTarget.dataset.filters)
    this.updateWindow(filters)
  }

  updateWindow (filters) {
    window.location.href = this.buildUrl(window.location, filters)
  }

  updateNavBarClasses (element, classes) {
    const classArray = classes.split(' ')
    document.querySelectorAll(`[data-active-classes="${classes}"]`).forEach((elem) => {
      elem.classList.remove(...classArray)
    })
    element.classList.add(...classArray)
  }

  buildUrl (url, filters) {
    const urlObj = new URL(url)
    Object.keys(filters).forEach((key) => {
      urlObj.searchParams.set(key, filters[key])
    })
    return urlObj.href
  }
}
