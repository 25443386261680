import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="manage-git-user-group-members"
export default class extends Controller {
  static targets = [
    'checkbox',
    'checkboxAll',
    'countSelected',
    'filteredCount',
    'filteredCountSelected',
    'table',
    'noSearchResultsRow',
    // group selection targets
    'groupSelectButtonText',
    'groupSelectInButton',
    'groupSelectNotInButton',
    'groupSelectSearchInput',
    'groupSelectOption',
    'groupSelectCheckbox'
  ]

  static values = {
    groupMemberIds: Object
  }

  initialize () {
    this.rowCount = 0
    this.checkedCount = 0
    this.filteredAllCount = 0
    this.filteredCheckedCount = 0
    this.initCheckedCount = 0
    this.showAllRowsState = true
    this.searchText = ''
    this.groupSelectionInclusionState = true
    this.setOfAllGitUserIds = new Set()
    this.groupSelectionGitUserIds = new Set()
    this.groupSelectionGroupNames = []
    this.rowStates = {}
  }

  // main table setup and update methods
  tableTargetConnected () {
    const rows = Array.from(this.tableTarget.querySelector('tbody').rows)
    this.rowCount = rows.length - 1 // -1 for the no search results row
    this.filteredAllCount = this.rowCount

    rows.forEach(row => {
      const dataset = row.dataset
      if (!dataset.gitUserId) return // no data row

      const checked = dataset.checked === 'true'

      // Store the state of the row in the rowStates object
      this.rowStates[dataset.gitUserId] = {
        gitUserId: dataset.gitUserId,
        visible: true,
        searchable: dataset.searchable.toLowerCase(),
        checked,
        row
      }
      this.setOfAllGitUserIds.add(dataset.gitUserId)

      if (checked) this.checkedCount++
    })

    this.initCheckedCount = this.checkedCount
    this.filteredCheckedCount = this.checkedCount
    this.groupSelectionGitUserIds = new Set(this.setOfAllGitUserIds)

    this.setCountText()
  }

  setCountText () {
    if (this.hasCountSelectedTarget) this.countSelectedTargets.forEach(t => { t.innerText = this.checkedCount })
    if (this.hasFilteredCountTarget) this.filteredCountTarget.innerText = this.filteredAllCount
    if (this.hasFilteredCountSelectedTarget) this.filteredCountSelectedTarget.innerText = this.filteredCheckedCount
  }

  checkBoxClicked (event) {
    const checked = event.currentTarget.checked

    // If the checkbox is checked, increment the checkedCount and filteredCheckedCount else decrement them
    this.checkedCount += checked ? 1 : -1
    this.filteredCheckedCount += checked ? 1 : -1

    // checkbox.value is the gitUserId
    this.rowStates[event.currentTarget.value].checked = checked

    this.setCountText()
  }

  selectAllClicked (event) {
    const checkAll = event.currentTarget.checked
    for (const checkbox of this.checkboxTargets) {
      // checkbox.value is the gitUserId
      const rowState = this.rowStates[checkbox.value]
      // only update visible rows
      if (!rowState.visible) continue

      // only update the checked count and state if the checkbox state is different from the checkAll state
      if (checkbox.checked !== checkAll) {
        checkbox.checked = checkAll
        rowState.checked = checkAll
        this.checkedCount += checkAll ? 1 : -1
        this.filteredCheckedCount += checkAll ? 1 : -1
      }
    }

    this.setCountText()
  }

  resetCheckedCount () {
    this.checkedCount = this.initCheckedCount
    this.filteredCheckedCount = this.initCheckedCount
    this.filteredAllCount = this.rowCount
    this.setCountText()
  }

  resetCountsToZeros () {
    this.rowCount = 0
    this.checkedCount = 0
    this.filteredAllCount = 0
    this.filteredCheckedCount = 0
    this.initCheckedCount = 0
    this.showAllRowsState = true
    this.searchText = ''
    this.groupSelectionInclusionState = true
    this.groupSelectionGroupNames = []
  }

  // filter methods
  searchFilter (event) {
    this.searchText = event.target.value.toLowerCase()
    this.applyFilters()
  }

  setStateFilter (event) {
    this.showAllRowsState = event.target.value === 'all'
    this.applyFilters()
  }

  setInclusionStateFilter (event) {
    this.groupSelectionInclusionState = event.currentTarget.dataset.state === 'in'
    if (this.groupSelectionInclusionState) {
      this.groupSelectInButtonTarget.classList.add('checked')
      this.groupSelectNotInButtonTarget.classList.remove('checked')
    } else {
      this.groupSelectInButtonTarget.classList.remove('checked')
      this.groupSelectNotInButtonTarget.classList.add('checked')
    }
  }

  groupSelectionFilter () {
    this.groupSelectionGitUserIds = new Set()
    this.groupSelectionGroupNames = []
    // go through each checkbox and add the group's git_user_ids to the set if the checkbox is checked
    this.groupSelectCheckboxTargets.forEach(c => {
      if (c.checked) {
        const groupId = c.value
        // just in case the groupMemberIdsValue is not set
        if (this.hasGroupMemberIdsValue && this.groupMemberIdsValue[groupId]) {
          // add the group's git_user_ids to the set
          this.groupMemberIdsValue[groupId].git_user_ids.forEach(id => this.groupSelectionGitUserIds.add(id))
          // add the group's name to the groupSelectionGroupNames array
          this.groupSelectionGroupNames.push(this.groupMemberIdsValue[groupId].name)
        }
      }
    })

    // zero state: if no groups are selected, select all members
    if (this.groupSelectionGroupNames.length === 0) { this.groupSelectionGitUserIds = new Set(this.setOfAllGitUserIds) }

    // update the group selection text
    this.setGroupSelectButtonText()

    this.applyFilters()
  }

  applyFilters () {
    if (this.hasCheckboxAllTarget) this.checkboxAllTarget.checked = !this.showAllRowsState
    this.filteredCheckedCount = 0
    this.filteredAllCount = 0

    let hiddenCount = 0
    for (const [, rowState] of Object.entries(this.rowStates)) {
      const { show, showInFilteredAllCount } = this.showRowState(rowState) // passed by reference so no extra memory allocation

      rowState.visible = show

      // add hidden if show is false
      rowState.row.classList.toggle('hidden', !show)

      // if the row can be shown just based on the filters regardless of checked state, add it to the all count
      if (showInFilteredAllCount) { this.filteredAllCount++ }

      if (show && rowState.checked) {
        // If the row is shown and checked, increment the count of selected rows
        this.filteredCheckedCount++
      } else if (!show) {
        // If the row is not shown, increment the count of hidden rows
        hiddenCount++
      }
    }

    // If all rows are hidden, show the no search results row
    this.noSearchResultsRowTarget.classList.toggle('hidden', hiddenCount !== this.rowCount)

    this.setCountText()
  }

  showRowState (rowState) {
    // If there's no search text, or if the row's searchable text includes the search text ->  show the row
    const searchBasedDecision = this.searchText.length === 0 || rowState.searchable.includes(this.searchText)

    // If all rows are shown, or if only the checked rows are shown and the row is checked -> show the row
    const stateBasedDecision = this.showAllRowsState || rowState.checked

    // If we are to include all the selected groups, show the row if the row's gitUserId is in the groupSelectionGitUserIds set
    // If we are to exclude all the selected groups, show the row if the row's gitUserId is not in the groupSelectionGitUserIds set
    const groupSelectionBasedDecision = this.groupSelectionInclusionState
      ? this.groupSelectionGitUserIds.has(rowState.gitUserId)
      : !this.groupSelectionGitUserIds.has(rowState.gitUserId)

    // The row should be shown if both the search-based decision and the state-based decision are true.
    return {
      show: searchBasedDecision && stateBasedDecision && groupSelectionBasedDecision,
      showInFilteredAllCount: searchBasedDecision && groupSelectionBasedDecision
    }
  }

  // Group selection methods
  searchGroupSelection (event) {
    const searchValue = event.currentTarget.value.toLowerCase()
    if (!searchValue) {
      this.groupSelectOptionTargets.forEach(t => t.classList.remove('hidden'))
      return
    }

    // Iterate over each group select option target
    this.groupSelectOptionTargets.forEach(target => {
      // Toggle the 'hidden' class based on whether there is a match
      target.classList.toggle('hidden', !target.dataset.searchable.includes(searchValue))
    })
  }

  resetGroupSelectionFilter () {
    // Reset the group inclusion state to 'In'
    this.groupSelectInButtonTarget.classList.add('checked')
    this.groupSelectNotInButtonTarget.classList.remove('checked')
    this.groupSelectionInclusionState = true

    // Reset the group selection checkboxes
    this.groupSelectionGitUserIds = new Set(this.setOfAllGitUserIds)
    this.groupSelectionGroupNames = []
    this.groupSelectOptionTargets.forEach(t => t.classList.remove('hidden'))
    this.groupSelectCheckboxTargets.forEach(c => { c.checked = false })
    this.groupSelectSearchInputTarget.value = ''

    // set the group select button text
    this.setGroupSelectButtonText()

    // apply the filters
    this.applyFilters()
  }

  setGroupSelectButtonText () {
    let groupNamesText = ''
    if (this.groupSelectionGroupNames.length === 0) {
      groupNamesText = 'All groups'
    } else if (this.groupSelectionGroupNames.length === 1) {
      groupNamesText = this.groupSelectionGroupNames[0]
    } else {
      groupNamesText = `${this.groupSelectionGroupNames[0]}, +${this.groupSelectionGroupNames.length - 1} more`
    }

    this.groupSelectButtonTextTarget.innerText = `${this.groupSelectionInclusionState ? 'In' : 'Not in'}: ${groupNamesText}`
  }

  resetFilters () {
    for (const checkbox of this.checkboxTargets) {
      checkbox.checked = false
      this.rowStates[checkbox.value].checked = false
    }
    this.showAllRowsState = true
    this.resetCheckedCount()
    this.resetGroupSelectionFilter()

    this.applyFilters()
  }
}
