import { Controller } from '@hotwired/stimulus'

export default class MainNavigationController extends Controller {
  static targets = [
    'mobileNav',
    'menuIcon',
    'xIcon',
    'bgOverlay'
  ]

  initialize () {
    window.announcekit = {
      queue: [],
      on: function (n, x) {
        window.announcekit.queue.push([n, x])
      },
      push: function (x) {
        window.announcekit.queue.push(x)
      }
    }

    window.announcekit.push({
      widget: 'https://changelog.software.com/widgets/v2/1MbF3q',
      selector: '#announcekit-widget',
      // Style config for badge launcher:
      badge: {
        style: {
          display: '',
          width: '0',
          height: '0',
          padding: '0',
          margin: '0'
        }
      }
    })
  }

  toggleMobileNav () {
    this.mobileNavTarget.classList.toggle('hidden')
    this.mobileNavTarget.classList.toggle('flex')
    this.menuIconTarget.classList.toggle('hidden')
    this.xIconTarget.classList.toggle('hidden')
    this.bgOverlayTarget.classList.toggle('hidden')
  }
}
