import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['toggle', 'toggleButtonText', 'chevron']
  static classes = ['addRemove', 'toggle', 'toggleButton']
  static values = {
    isVisible: { type: Boolean, default: false },
    openText: String,
    closeText: String,
    clickOutsideEnabled: { type: Boolean, default: false },
    clickOutsidePreventDefaultEnabled: { type: Boolean, default: true }
  }

  connect () {
    if (this.clickOutsideEnabledValue) {
      useClickOutside(this, { events: ['mousedown'] })
    }
  }

  clickOutside (event) {
    if (this.isVisibleValue) {
      if (this.clickOutsidePreventDefaultEnabled) {
        event.preventDefault()
      }
      this.isVisibleValue = false
      this.render(event)
    }
  }

  toggle (event) {
    this.isVisibleValue = !this.isVisibleValue
    this.render(event)
  }

  hide (event) {
    this.isVisibleValue = false
    this.render(event)
  }

  show (event) {
    this.isVisibleValue = true
    this.render(event)
  }

  render (event) {
    if (this.isVisibleValue) {
      this.toggleTarget.classList.remove(...this.toggleClasses)
      this.toggleTarget.classList.remove(...this.addRemoveClasses)
      if (this.hasCloseTextValue) {
        this.toggleButtonTextTarget.innerText = this.closeTextValue
      }
    } else {
      this.toggleTarget.classList.add(...this.toggleClasses)
      this.toggleTarget.classList.add(...this.addRemoveClasses)
      if (this.hasOpenTextValue) {
        this.toggleButtonTextTarget.innerText = this.openTextValue
      }
    }

    if (this.hasToggleButtonClass) event.currentTarget.classList.toggle(...this.toggleButtonClasses)
    if (this.hasChevronTarget) this.chevronTarget.classList.toggle('rotate-180')
  }
}
