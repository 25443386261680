import { Controller } from '@hotwired/stimulus'

export default class Billing extends Controller {
  resetActiveContributorListTurboFrameSrc () {
    document.getElementById('active_contributor_list').innerHTML = '<p class="animate-pulse">Loading</p>'
  }

  addLoadingToButton (event) {
    event.target.classList.add('animate-pulse', 'pointer-events-none')
    event.target.innerHTML = 'Upgrading... &#128640;'
  }
}
