import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class FeaturesDeliveredGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this
    const seriesKeys = [
      {
        key: 'total_features',
        name: 'Total features',
        color: controller.darkMode
          ? controller.tailwindColors.blue[800]
          : controller.tailwindColors.blue[200],
        type: 'column',
        yAxis: 0,
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5
      },
      {
        key: 'features_per_engineer',
        name: 'Features per developer',
        color: controller.tailwindColors.blue[500],
        type: 'spline',
        yAxis: 1
      }
    ]

    this.tooltipValues = {}
    controller.parsedGraphData.data.forEach((data) => {
      this.tooltipValues[Highcharts.dateFormat('%b \'%y', data[0])] = data[0]
    })
    const emptySeriesData = []
    let fpeTotal = 0
    let totalFeaturesTotal = 0
    const series = seriesKeys.map((config, i) => {
      const data = controller.parsedGraphData.data.map((data) => [Highcharts.dateFormat('%b \'%y', data[0]), data[1][config.key]])
      if (config.key === 'total_features') {
        for (let i = 0; i < data.length; i++) {
          if (!data[i][1]) {
            emptySeriesData.push([data[i][0], 0])
          }
        }
      }

      if (config.key === 'features_per_engineer') {
        fpeTotal = data.reduce((acc, curr) => acc + curr[1], 0)
      } else {
        totalFeaturesTotal = data.reduce((acc, curr) => acc + curr[1], 0)
      }

      return {
        name: config.name,
        data,
        color: config.color,
        lineColor: config.color,
        zIndex: 1,
        marker: {
          enabled: false
        },
        type: config.type,
        stacking: 'normal',
        yAxis: config.yAxis
      }
    })

    this.addNoDataSeriesToSeries(series, emptySeriesData)
    const fpeMaxSettings = this.getYAxisMaxAndTickAmountSettings(fpeTotal)
    const totalFeaturesMaxSettings = this.getYAxisMaxAndTickAmountSettings(totalFeaturesTotal)
    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 30,
        spacingRight: 3,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: controller.parsedGraphData.data.map((data) => Highcharts.dateFormat('%b \'%y', data[0])),
        title: { text: null },
        tickLength: 0,
        labels: {
          step: controller.parsedGraphData.data.length > 6 ? 2 : 1,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        minPadding: 0,
        maxPadding: 0,
        plotBands: [
          controller.optionsValue.total_previous_value && {
            color: controller.darkMode
              ? 'rgba(229,231,235, 0.05)'
              : 'rgba(243,244,246, 0.5)',
            from: -0.5,
            to: 2.51,
            label: {
              align: 'center',
              y: -30,
              useHtml: true,
              formatter: function () {
                let labelText = 'Previous period'
                if (controller.optionsValue.date_range === 'year') {
                  labelText = 'Same period last year'
                }
                const featuresValue = Number(controller.optionsValue.total_previous_value) === 0 ? '--' : controller.optionsValue.total_previous_value
                return `
                  <div class="text-xs" style="color: ${controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500]};">${labelText}</div>
                  <br>
                  <div class="text-xs" <div style="color: ${controller.darkMode ? 'white' : 'black'};">${featuresValue} features</div>
                `
              }
            }
          },
          controller.optionsValue.total_current_value && {
            color: controller.darkMode
              ? 'rgba(147,197,253, 0.1)'
              : 'rgba(225,242,255)',
            from: controller.parsedGraphData.data.length - 3.5,
            to: controller.parsedGraphData.data.length - 0.5,
            label: {
              align: 'center',
              y: -30,
              useHtml: true,
              formatter: function () {
                let percentStyle = 'padding: 8px; border-radius: 10px; '
                if (controller.optionsValue.percentage_change === 0) {
                  percentStyle += `color: ${controller.darkMode ? controller.tailwindColors.gray[200] : controller.tailwindColors.gray[500]};`
                } else if (controller.optionsValue.percentage_change > 0) {
                  percentStyle += `color: ${controller.tailwindColors.teal[500]};`
                } else {
                  percentStyle += `color: ${controller.tailwindColors.red[600]};`
                }
                const totalCurrentValue = Number(controller.optionsValue.total_current_value) === 0 ? '--' : controller.optionsValue.total_current_value
                return `
                  <div class="text-xs" style="color: ${controller.darkMode ? controller.tailwindColors.gray[400] : controller.tailwindColors.gray[500]};">Last 3 months</div>
                  <br>
                  <div class="text-xs" style="color: ${controller.darkMode ? 'white' : 'black'};">
                    ${totalCurrentValue} features
                    <div style="${percentStyle}">(${controller.optionsValue.percentage_change}%)</div>
                  </div>
                `
              }
            }
          }
        ].filter(p => p)
      },
      yAxis: [
        {
          title: { text: null },
          gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && totalFeaturesTotal === 0) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          ...totalFeaturesMaxSettings
        },
        {
          title: { text: null },
          gridLineWidth: 0,
          labels: {
            enabled: true,
            style: {
              color: controller.darkMode
                ? controller.tailwindColors.gray[400]
                : controller.tailwindColors.gray[500]
            },
            formatter: function () {
              if (this.value !== 0 && fpeTotal === 0) {
                return '--'
              } else {
                return this.value
              }
            }
          },
          reversedStacks: false,
          opposite: true,
          ...fpeMaxSettings
        }
      ],
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.025,
          groupPadding: 0,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5
        },
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          states: {
            hover: {
              color: controller.darkMode
                ? controller.tailwindColors.blue[900]
                : controller.tailwindColors.blue[300]
            }
          },
          stickyTracking: false,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(new Date(controller.tooltipValues[this.name]).toISOString().split('T')[0])
                }
              }
            }
          }
        }
      },
      colors: [
        controller.tailwindColors.blue[200],
        controller.tailwindColors.orange[200],
        controller.tailwindColors.teal[200]
      ],
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: true,
        shared: true,
        formatter: function () {
          const breakDownValues = this.points.reverse().map((p) => {
            return breakDownValue({
              name: p.series.name,
              style: `background-color: ${p.color};`,
              value: p.series.name === 'New features per developer' ? p.y.toFixed(1) : p.y,
              type: p.series.name === 'New features per developer' ? 'line' : 'box'
            })
          }).reverse()

          return card({
            date: this.x,
            breakDownValues,
            footer: controller.optionsValue.metrics_url && `<button data-value="${controller.tooltipValues[this.x]}" data-action="click->features-delivered-graph#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View pull requests</button>`
          })
        }
      }
    }
  }
}
