import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="avatar-fallback"
export default class extends Controller {
  static classes = ['avatar']
  static values = {
    abbreviation: String,
    type: { type: String, default: 'organization' }
  }

  connect () {
    // The images onerror can fire before stimulus even gets a chance to connect, so we
    // need to check if the image load is already complete with a width of 0. Zero width
    // image means the load failed.
    if (this.element.complete && this.element.naturalWidth === 0) {
      this.handleError()
    }
  }

  handleError () {
    this.element.onerror = null
    if (this.hasAbbreviationValue) {
      this.element.outerHTML = `<span class="${this.avatarClasses.join(' ')}">${this.abbreviationValue}</span>`
    } else if (this.typeValue === 'user') {
      this.element.outerHTML = `
        <svg id="YCXSYgRnjdknjn0L" class="p-1 ${this.avatarClasses.join(' ')}" viewBox="0 0 24 24" data-icon="user" fill="none" role="img">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"></path>
        </svg>
      `
    } else if (this.typeValue === 'organization') {
      this.element.outerHTML = `
        <svg id="YCXSYgRnjdknjn0L" class="p-1 ${this.avatarClasses.join(' ')}" viewBox="0 0 24 24" data-icon="user" fill="none" role="img">
        <path stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"></path>
        </svg>
      `
    }
  }
}
