import { Controller } from '@hotwired/stimulus'

export default class ReadyToDeployController extends Controller {
  setDeploymentsFlyoutRepoName (e) {
    const flyout = document.getElementById('deployments_flyout')
    const turboSrc = e.currentTarget.dataset.turboSrc
    const flyoutContent = document.getElementById('deployments_flyout_content')
    if (flyoutContent) {
      flyoutContent.parentElement.innerHTML = `<div class="flex items-center justify-center h-screen mx-auto -mt-16">
        <div class="relative w-20 h-4">
          <div class="h-3 w-3 absolute left-0 animate-enter animation-delay-400"></div>
          <div class="h-3 w-3 absolute left-4 animate-enter animation-delay-300"></div>
          <div class="h-3 w-3 absolute left-8 animate-enter animation-delay-200"></div>
          <div class="h-3 w-3 absolute left-12 animate-enter animation-delay-100"></div>
          <div class="h-3 w-3 absolute left-16 animate-enter "></div>
        </div>
      </div>`
    }
    flyout.src = turboSrc
  }
}
