import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  useCompanySetting (event) {
    const inputField = document.getElementById(`cpe_override_port_org_${event.target.dataset.portOrgId}`)
    inputField.value = inputField.dataset.companyValue
    inputField.disabled = true
  }

  useOverride (event) {
    const inputField = document.getElementById(`cpe_override_port_org_${event.target.dataset.portOrgId}`)
    inputField.disabled = false
    inputField.focus()
  }
}
