import { theme } from '../../../tailwind.config'

export default {
  tailwind: theme.colors,
  dark: {
    text: '#FFFFFF',
    primary: '#0896FC',
    secondary: '#A7A7A7',
    trendline: '#FFA800',
    trendlineBackground: '#FFF1D6',
    gridLine: '#65717D',
    legend: '#FFFFFF',
    barPrimary: 'rgba(8, 150, 252, .25)',
    barPrimaryLight: '#C1E5FE',
    barSecondary: '#FFA800',
    barSecondaryLight: '#FFE9C6',
    quadrantLight: '#2C2C2C',
    quadrantDark: '#1F1F1F',
    legendHidden: '#6C6C6C',
    meetingTime: theme.colors.gray[500],
    activeCodeTime: theme.colors.blue[500],
    codeTime: theme.colors.blue[200]
  },
  light: {
    text: '#000000',
    primary: '#0896FC',
    secondary: '#EDEDED',
    trendline: '#FFA800',
    trendlineBackground: '#FFF1D6',
    gridLine: '#E6E6E6',
    legend: '#000000',
    barPrimary: 'rgba(8, 150, 252, .15)',
    barPrimaryLight: '#C1E5FE',
    barSecondary: '#FFA800',
    barSecondaryLight: '#FFE9C6',
    quadrantLight: '#FFFFFF',
    quadrantDark: '#F8F8F8',
    legendHidden: '#CCCCCC',
    meetingTime: theme.colors.gray[300],
    activeCodeTime: theme.colors.blue[500],
    codeTime: theme.colors.blue[200]
  }
}
