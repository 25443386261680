import BaseGraph from './base_graph_controller'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class BenchmarksHistogramController extends BaseGraph {
  setPlotLines () {
    const useGlobalBenchmark = this.parsedGraphData.company_benchmark === null
    const benchmark = useGlobalBenchmark ? this.parsedGraphData.global_benchmark : this.parsedGraphData.company_benchmark
    const categoryMaxVal = parseFloat(this.parsedGraphData.max_category_value)
    // calculate how much each bar step represents
    const stepInterval = categoryMaxVal / (this.categories.length - 1)
    // plot the value in terms of bars
    const plotValue = benchmark / stepInterval

    const roundedValue = Math.round(benchmark * 10) / 10
    const label = useGlobalBenchmark ? 'Median' : `${this.optionsValue.company_name}`

    if (roundedValue > 0) {
      return {
        color: this.darkMode ? this.tailwindColors.gray[400] : this.tailwindColors.gray[500],
        width: 2,
        value: plotValue,
        zIndex: 200,
        label: {
          useHTML: true,
          text: `<div class="bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200 text-xs font-semibold">${label}:  ${roundedValue}${this.optionsValue.label_unit}</div>`,
          align: 'center',
          rotation: 0,
          y: 3
        }
      }
    }
    return {}
  }

  prepareGraphSchema () {
    const controller = this
    this.tooltipValues = {}
    const chartData = []
    this.categories = []
    const values = []
    controller.parsedGraphData.histogram.forEach((data) => {
      this.tooltipValues[data.category] = data
      chartData.push([data.category, data.percent_of_companies])
      this.categories.push(data.category)
      values.push(data.percent_of_companies)
    })
    const max = Math.max(...values)
    const series = [{
      name: '% of companies globally',
      data: chartData,
      color: controller.darkMode
        ? controller.tailwindColors.blue[800]
        : controller.tailwindColors.blue[200],
      type: 'column',
      yAxis: 0,
      zIndex: 1,
      marker: {
        enabled: false
      },
      stacking: 'normal',
      borderRadiusTopLeft: 5,
      borderRadiusTopRight: 5
    }]

    const plotLines = this.setPlotLines()

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 3,
        spacingBottom: 0,
        spacingLeft: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: this.categories,
        title: {
          text: controller.optionsValue.x_axis_label,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        tickLength: 0,
        tickmarkPlacement: 'on',
        labels: {
          step: 2,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        plotLines: [plotLines],
        gridLineColor: 'transparent',
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        minPadding: 0,
        maxPadding: 0
      },
      yAxis: {
        title: {
          text: '% of companies globally',
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          }
        },
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            return controller.parsedGraphData.max === 0 ? '' : `${this.value}%`
          }
        },
        reversedStacks: false,
        min: 0,
        max: max || 2
      },
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.025,
          groupPadding: 0,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          pointPlacement: 'between'
        },
        series: {
          cursor: 'pointer',
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          stacking: 'normal',
          states: {
            hover: {
              color: controller.darkMode
                ? controller.tailwindColors.blue[900]
                : controller.tailwindColors.blue[300]
            }
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          return card({
            breakDownValues: [
              breakDownValue({
                name: controller.optionsValue.x_axis_unit,
                style: 'background-color: grey;',
                value: controller.tooltipValues[this.x].category_label,
                type: 'none'
              }), ...this.points.map((p) => {
                return breakDownValue({
                  name: '% of companies',
                  style: `background-color: ${p.color};`,
                  value: `${p.y.toFixed(1)}%`,
                  type: 'none'
                })
              })
            ]
          })
        }
      }
    }
  }
}
