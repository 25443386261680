import { Controller } from '@hotwired/stimulus'

export default class DataPageHeaderFiltersController extends Controller {
  preventSubmit (e) {
    e.preventDefault()
  }

  submitForm () {
    if (this.element.submit) {
      this.element.submit()
    }
  }

  debouncedSubmitForm () {
    if (this.submitFormTimeout) {
      clearTimeout(this.submitFormTimeout)
    }

    this.submitFormTimeout = setTimeout(() => {
      this.submitForm()
    }, 500)
  }

  resetRepositoryFilterForGroup () {
    const checkboxes = document.querySelectorAll('input[name="repository_names[]"]')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false
    })
  }
}
