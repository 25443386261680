import { Controller } from '@hotwired/stimulus'
import { trackPageViewEvent } from '../helpers/analytics_helper'

export default class extends Controller {
  connect () {
    document.querySelectorAll('input[data-submit-on-change="true"]').forEach((input) => {
      input.addEventListener('change', function (evt) {
        const form = this.closest('form')
        if (form) {
          const checkboxValue = evt.target.value
          document.querySelector(`#${checkboxValue}`).value = evt.target.checked
          form.submit()
        }
        evt.stopImmediatePropagation()
      })
    })
  }

  openModal (event) {
    event.preventDefault()
    event.stopPropagation()
    const evt = new CustomEvent('open-modal', {
      detail: {
        modalId: event.currentTarget.dataset.modalId,
        title: event.currentTarget.dataset.modalTitle,
        params: event.currentTarget.dataset.modalParams
      }
    })
    window.dispatchEvent(evt)
  }

  closeModal (event) {
    const evt = new CustomEvent('close-modal', {
      name: event.currentTarget.dataset.modal
    })
    window.dispatchEvent(evt)
  }

  openFlyout (event) {
    const evt = new CustomEvent('open-flyout', {
      detail: {
        flyoutId: event.currentTarget.dataset.flyoutId
      }
    })
    window.dispatchEvent(evt)
  }

  openBeacon () {
    window.Beacon('open')
  }

  sendButtonClickedAnalyticsEvent (event) {
    trackPageViewEvent('button_clicked', {
      button_id: event.currentTarget.dataset.buttonId,
      button_text: event.currentTarget.dataset.buttonText,
      location: event.currentTarget.dataset.buttonLocation,
      organization_slug: event.currentTarget.dataset.organizationSlug
    })
  }
}
