import { Controller } from '@hotwired/stimulus'

export default class AccountController extends Controller {
  static values = {
    links: Array
  }

  select (event) {
    this.linksValue.forEach(id => {
      const link = document.getElementById(id)
      link?.classList?.remove('border-b-blue-500', 'text-gray-900')
      link?.classList?.add('text-gray-500')
    })

    event.target.classList.add('border-b-blue-500', 'text-gray-900')
  }

  scrollToTop () {
    window.scrollTo(0, 0)
  }
}
