import { Controller } from '@hotwired/stimulus'
import { domToPng } from 'modern-screenshot'

export default class extends Controller {
  static targets = ['button', 'captureElement']

  static values = {
    logoOptions: Object,
    showLogo: { type: Boolean, default: true }
  }

  connect () {
    this.addUI()

    // get child turbo-frame and observe it for changes, to enable button when it's loaded
    const childTurboFrames = Array.from(this.element.querySelectorAll('turbo-frame'))
    const childTurboFrame = childTurboFrames.filter((frame) => {
      return frame.attributes['aria-busy'] !== undefined
    })[0]
    if (childTurboFrame && this.hasButtonTarget && childTurboFrame?.attributes['aria-busy']) {
      this.buttonTarget.disabled = true
      this.buttonTarget.classList.add('!cursor-not-allowed')
      this.observer = new MutationObserver((mutationList) => {
        if (mutationList[0].attributeName === 'complete') {
          this.buttonTarget.disabled = null
          this.buttonTarget.classList.remove('!cursor-not-allowed')
          this.observer.disconnect()
        }
      })
      this.observer.observe(childTurboFrame, { attributes: true })
    }
  }

  disconnect () {
    if (this.observer) {
      this.observer.disconnect()
    }
  }

  addUI () {
    // add the button dynamically if it doesn't exist
    if (!this.hasButtonTarget) {
      this.element.classList.add('relative')
      const button = document.createElement('button')
      button.dataset.action = 'click->screen-grab#capture'
      button.dataset.hideOnRender = true
      button.classList.add('absolute', 'inline-block', 'top-2', 'right-2', 'p-1', 'rounded-md', 'dark:hover:bg-gray-700', 'hover:bg-gray-100', 'hover:text-blue-500', 'text-gray-500', 'text-sm')
      button.innerHTML = this.downloadIcon()
      button.title = 'Download report as .png'

      this.element.appendChild(button)
    }
  }

  capture (event) {
    const currentTarget = event.currentTarget
    currentTarget.disabled = true
    currentTarget.classList.add('!cursor-wait')
    document.dispatchEvent(new Event('close_tool_tips'))

    const controller = this
    const capturable = this.hasCaptureElementTarget ? this.captureElementTarget : this.element
    const elementsToHide = Array.from(this.element.querySelectorAll('[data-screen-grab-exclusion]')).map(e => {
      return e.checked === false ? e.dataset.screenGrabExclusion : null
    }).filter(e => e)

    domToPng(capturable, {
      scale: 2,
      features: {
        removeControlCharacter: false
      },
      onCloneNode: (node) => {
        // this allows modification of the cloned node before it is rendered to canvas
        node.querySelectorAll('[data-on-render="hide"]').forEach((el) => {
          el.style.display = 'none'
        })
        node.querySelectorAll('[data-on-render="show"]').forEach((el) => {
          el.style.display = 'initial'
        })
        // remove shadows as they don't render well in Safari
        node.querySelectorAll('.shadow-xl').forEach((el) => {
          el.style.boxShadow = 'none'
          el.style.webkitBoxShadow = 'none'
        })
        if (elementsToHide.length > 0) {
          elementsToHide.forEach((name) => {
            node.querySelectorAll(`[data-screenshot-element=${name}]`).forEach((el) => {
              el.remove()
            })
          })
        }
        if (controller.showLogoValue) {
          const chartLogo = document.createElement('div')
          chartLogo.style.position = 'absolute'
          chartLogo.style.width = '60px'
          chartLogo.style.paddingRight = '14px'
          chartLogo.style.top = '28px'
          chartLogo.style.right = '14px'
          chartLogo.style.zIndex = '1000'
          if (controller.hasLogoOptionsValue) {
            Object.assign(chartLogo.style, controller.logoOptionsValue)
          }
          chartLogo.innerHTML = controller.logo()
          node.appendChild(chartLogo)
        }
      }
    }).then(dataUrl => {
      const link = document.createElement('a')
      const title = controller.element.dataset.reportTitle || 'Development Report'
      link.download = title + '.png'
      link.href = dataUrl
      link.click()
    }).finally(() => {
      // reenable the button even when an error occurs
      currentTarget.disabled = false
      currentTarget.classList.remove('!cursor-wait')
    })
  }

  logo () {
    // #1F2937 is gray-800
    const textColor = localStorage.theme === 'dark' ? 'white' : '#1F2937'
    return `
      <svg viewBox="0 0 301 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M92.3 53.3C93 53.6 93.8 53.9 94.7 54.2C95.6 54.5 96.7 54.7 97.9 54.9C99.1 55.1 100.4 55.2 101.8 55.2C106 55.2 109.3 54.3 111.7 52.5C114.1 50.7 115.3 48 115.3 44.6C115.3 42.8 115 41.2 114.5 39.9C114 38.6 113.2 37.5 112.2 36.6C111.2 35.7 110.1 34.9 108.8 34.2C107.6 33.6 106.1 32.9 104.6 32.3C103.5 31.9 102.6 31.5 101.7 31C100.8 30.6 100.1 30.1 99.4 29.6C98.8 29.1 98.3 28.6 98 28C97.7 27.4 97.5 26.7 97.5 25.8C97.5 25 97.7 24.3 98 23.7C98.3 23.1 98.7 22.6 99.3 22.3C99.9 21.9 100.5 21.6 101.3 21.4C102.1 21.2 102.9 21.1 103.8 21.1C105.6 21.1 107.2 21.3 108.5 21.7C109.9 22.1 110.9 22.6 111.5 23L112.1 23.4L114.1 18.2L113.8 18C112.9 17.4 111.6 16.8 109.8 16.3C108 15.8 106 15.6 103.8 15.6C100 15.6 97 16.6 94.8 18.5C92.6 20.4 91.5 23.1 91.5 26.5C91.5 28.1 91.8 29.6 92.3 30.7C92.8 31.9 93.5 32.9 94.4 33.7C95.3 34.5 96.3 35.3 97.5 35.9C98.7 36.5 99.9 37 101.2 37.5C102.3 37.9 103.4 38.4 104.4 38.8C105.4 39.2 106.2 39.7 107 40.3C107.7 40.8 108.3 41.5 108.7 42.2C109.1 42.9 109.3 43.8 109.3 44.8C109.3 48.2 106.9 49.8 102.1 49.8C99.9 49.8 98 49.5 96.4 49C94.8 48.4 93.7 47.9 92.9 47.5L92.3 47.2L90.5 52.5L90.9 52.7C91.1 52.8 91.7 53 92.3 53.3Z" fill="${textColor}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M137.8 26.2C139.5 26.9 140.9 28 142.1 29.3C143.3 30.6 144.2 32.2 144.9 34.1C145.6 36 145.9 38 145.9 40.2C145.9 42.4 145.6 44.5 144.9 46.3C144.2 48.1 143.3 49.7 142.1 51C140.9 52.4 139.5 53.4 137.8 54.1C136.1 54.8 134.3 55.2 132.4 55.2C130.5 55.2 128.6 54.8 127 54.1C125.3 53.3 123.9 52.3 122.7 51C121.5 49.7 120.6 48.1 119.9 46.3C119.2 44.5 118.9 42.4 118.9 40.2C118.9 38 119.2 35.9 119.9 34.1C120.6 32.2 121.5 30.6 122.7 29.3C123.9 27.9 125.4 26.9 127 26.2C128.7 25.5 130.5 25.1 132.4 25.1C134.3 25.1 136.2 25.5 137.8 26.2ZM132.4 49.7C134.7 49.7 136.4 48.9 137.7 47.2C139 45.5 139.7 43.1 139.7 40.2C139.7 37.3 139 34.9 137.7 33.2C136.4 31.5 134.6 30.7 132.4 30.7C130.1 30.7 128.4 31.5 127.1 33.2C125.8 34.9 125.1 37.2 125.1 40.2C125.1 43.1 125.8 45.5 127.1 47.2C128.4 48.9 130.2 49.7 132.4 49.7Z" fill="${textColor}"/>
      <path d="M167.3 13.3C166.8 13.1 166.1 12.9 165 12.7C163.9 12.5 162.6 12.4 161.2 12.4C157.4 12.4 154.5 13.4 152.7 15.5C150.9 17.5 150 20.4 150 24V60L156 59V31.1H166.6V25.9H156V24C156 22 156.4 20.4 157.3 19.3C158.1 18.2 159.6 17.6 161.7 17.6C162.8 17.6 163.7 17.7 164.4 17.9C165.1 18.1 165.7 18.3 166.1 18.5L166.7 18.8L167.8 13.5L167.3 13.3Z" fill="${textColor}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M232.6 50C232.1 48.8 231.8 47.4 231.8 45.7C231.8 44.1 232.2 42.7 233 41.8C233.7 40.7 234.6 39.7 235.7 39C236.8 38.3 238.1 37.7 239.6 37.4C241.1 37.1 242.5 36.9 243.9 36.9H243.9C244.5 36.9 245.1 36.9 245.7 37C246.2 37 246.7 37.1 247.2 37.2C247.419 37.2438 247.619 37.2685 247.8 37.2907C248.032 37.3192 248.232 37.3438 248.4 37.4H248.5V36.7C248.5 35.9 248.4 35.1 248.2 34.4C248 33.7 247.7 33 247.3 32.5C246.9 32 246.3 31.5 245.6 31.2C244.8 30.9 243.9 30.7 242.7 30.7C241.1 30.7 239.7 30.8 238.5 31C237.444 31.1761 236.697 31.4296 236.124 31.6243C236.046 31.6507 235.972 31.6761 235.9 31.7L235.3 32L234.6 26.8L235 26.6C235.7 26.3 236.8 26 238.3 25.7C239.8 25.4 241.5 25.3 243.2 25.3C245.2 25.3 247 25.6 248.4 26.1C249.8 26.6 251 27.4 251.9 28.4C252.8 29.4 253.4 30.5 253.8 31.9C254.1 33.2 254.3 34.7 254.3 36.3V53.9L253.8 54C253.4 54.1 252.8 54.2 252 54.3C251.3 54.4 250.4 54.5 249.4 54.6C248.5 54.7 247.4 54.8 246.3 54.9C245.2 55 244.1 55 243 55C241.4 55 239.9 54.8 238.6 54.5C237.2 54.2 236 53.6 235 52.9C234 52.2 233.2 51.2 232.6 50ZM240 42.5C239.4 42.8 238.9 43.2 238.5 43.7C238.1 44.2 237.9 44.8 237.8 45.7C237.8 47.3 238.2 48.3 239.2 48.9C240.2 49.6 241.7 49.9 243.5 49.9H243.5C244.7 49.9 245.7 49.9 246.6 49.8C246.915 49.7549 247.19 49.7302 247.443 49.7074C247.75 49.6797 248.025 49.6549 248.3 49.6H248.4V42.2C248.1 42.1 247.7 42 247.2 41.9C246.4 41.8 245.5 41.7 244.4 41.7C243.7 41.7 242.9 41.8 242.1 41.9C241.3 42 240.6 42.2 240 42.5Z" fill="${textColor}"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M297.9 28.9C300 31.3 301 35 301 39.8V40.3C301 40.5143 300.971 40.7 300.945 40.8723C300.922 41.0215 300.9 41.1608 300.9 41.3L300.8 41.8H282.1V41.9C282.4 44.3 283.2 46.1 284.4 47.4C285.8 48.8 287.9 49.5 290.8 49.5C292.4 49.5 293.9 49.4 295 49.1C296.2 48.8 297 48.6 297.6 48.3L298.2 48L299.1 53.2L298.8 53.4C298.1 53.8 297.1 54.1 295.5 54.5C294 54.8 292.2 55 290.3 55C287.9 55 285.7 54.6 283.9 53.9C282.1 53.2 280.6 52.1 279.4 50.8C278.2 49.5 277.4 47.9 276.8 46.1C276.3 44.3 276 42.3 276 40.2C276 37.7 276.4 35.5 277.1 33.6C277.8 31.7 278.8 30.1 280 28.9C281.2 27.7 282.6 26.7 284.2 26.1C285.7 25.5 287.4 25.2 289 25.2C292.8 25.2 295.8 26.5 297.9 28.9ZM282.315 36.9C282.31 36.9333 282.305 36.9666 282.3 37V36.9H282.315ZM282.8 34.9C282.61 35.5667 282.419 36.2333 282.315 36.9H294.6V36.8C294.6 35 294.1 33.5 293.1 32.3C292.1 31 290.7 30.4 288.8 30.4C287.8 30.4 286.9 30.6 286.1 31C285.3 31.4 284.6 32 284.1 32.6C283.6 33.3 283.1 34.1 282.8 34.9L282.8 34.9Z" fill="${textColor}"/>
      <path d="M273.8 30.7H274.1V25.2H273.9C265.9 25.2 262.6 26 259.8 27.4L259.7 27.5V54.5H265.7V31.8C267.5 31 272 30.6 273.8 30.7Z" fill="${textColor}"/>
      <path d="M225.9 41.6C227.4 37 228.9 31.9 230.3 26.5L230.5 25.8H224.3L224.2 26.2C223.8 27.9 223.4 29.7 222.9 31.6C222.4 33.5 221.9 35.5 221.3 37.4C220.7 39.3 220.2 41.2 219.6 43C219.2 44.1 218.9 45.2 218.5 46.1L218.4 46.3L218.3 46.1C217.9 44.9 217.5 43.7 217.1 42.4C216.5 40.6 216 38.7 215.4 36.8C214.9 34.9 214.4 33.1 213.9 31.3C213.4 29.5 213.1 27.8 212.7 26.2L212.6 25.8H207.7L207.6 26.2C207.2 27.8 206.8 29.5 206.4 31.3C205.9 33.1 205.4 34.9 204.9 36.8C204.4 38.7 203.8 40.6 203.2 42.4C202.8 43.6 202.4 44.9 202 46.1L201.9 46.3L201.8 46.1C201.5 45.2 201.1 44.2 200.7 43C200.1 41.2 199.5 39.3 199 37.4C198.4 35.4 197.9 33.5 197.4 31.6C196.9 29.7 196.5 27.9 196.1 26.2L196 25.8H189.6L189.8 26.5C191.2 31.9 192.7 37 194.2 41.6C195.7 46.2 197.4 50.4 199 54.1L199.1 54.4H204.1L204.2 54.1C205.2 51.5 206.3 48.6 207.3 45.4C208.2 42.8 209 40.1 209.7 37.4L209.8 37.2L209.9 37.4C210.7 40.2 211.5 42.9 212.4 45.4C213.5 48.7 214.6 51.6 215.6 54.1L215.7 54.4H220.7L220.8 54.1C222.7 50.5 224.3 46.3 225.9 41.6Z" fill="${textColor}"/>
      <path d="M182.1 55.1C183.6 55.1 184.9 55 185.9 54.8C187 54.6 187.7 54.4 188.2 54.2L188.6 54L187.5 48.7L186.9 49C186.5 49.2 186 49.4 185.2 49.6C184.5 49.8 183.5 49.9 182.5 49.9C180.4 49.9 178.9 49.3 178.1 48.2C177.3 47.1 176.8 45.5 176.8 43.5V31.1H187.2V25.9H176.8V17.3L170.8 18.3V43.5C170.8 47.1 171.7 50 173.5 52C175.4 54.1 178.3 55.1 182.1 55.1Z" fill="${textColor}"/>
      <path d="M14.2 62.4C12.6 60.8 11.4 59 10.6 57C8.19999 51.1 9.39999 44.2 14.2 39.4L27.1 42.4L25.7 50.9L27.5 61.9L14.2 62.4Z" fill="#00C0FA"/>
      <path d="M14.2 62.4L25.7 50.9L34 52.6L37.1 62.4C33.3 66.2 28.2 67.7 23.3 67C20 66.5 16.8 65 14.2 62.4Z" fill="#00b4ee"/>
      <path d="M25.9 18.3C30.7049 18.3 34.6 14.4049 34.6 9.59999C34.6 4.79512 30.7049 0.899994 25.9 0.899994C21.0951 0.899994 17.2 4.79512 17.2 9.59999C17.2 14.4049 21.0951 18.3 25.9 18.3Z" fill="#00CEFC"/>
      <path d="M48.3 18.3C53.1049 18.3 57 14.4049 57 9.59999C57 4.79512 53.1049 0.899994 48.3 0.899994C43.4951 0.899994 39.6 4.79512 39.6 9.59999C39.6 14.4049 43.4951 18.3 48.3 18.3Z" fill="#00CEFC"/>
      <path d="M65.2 33C70.0049 33 73.9 29.1049 73.9 24.3C73.9 19.4951 70.0049 15.6 65.2 15.6C60.3951 15.6 56.5 19.4951 56.5 24.3C56.5 29.1049 60.3951 33 65.2 33Z" fill="#00CEFC"/>
      <path d="M9.09999 33C13.9049 33 17.8 29.1049 17.8 24.3C17.8 19.4951 13.9049 15.6 9.09999 15.6C4.29512 15.6 0.399994 19.4951 0.399994 24.3C0.399994 29.1049 4.29512 33 9.09999 33Z" fill="#00CEFC"/>
      <path d="M48.6 50.9L40.9 49L34 39.7L41.2 31.4L48.6 28L60.1 39.5L57.4 48.9L48.6 50.9Z" fill="#00CEFC"/>
      <path d="M37.2 62.4L38.9 53.2L48.7 51L56.8 54.3L60.2 62.5C56.9 65.8 52.4 67.4 48.1 67.2C44 67 40.2 65.4 37.2 62.4Z" fill="#00b4ee"/>
      <path d="M37.2 39.5L28.4 37.8L25.8 28C29.3 24.5 33.9 23 38.4 23.3C42.2 23.6 45.8 25.1 48.7 28L37.2 39.5Z" fill="#00E2FF"/>
      <path d="M25.7 50.9L14.2 39.5L25.7 28L37.2 39.5L34.6 47.5L25.7 50.9Z" fill="#00CEFC"/>
      <path d="M60.1 62.4L48.6 50.9L60.1 39.4C63.6 42.9 65.2 47.7 64.8 52.3C64.5 56 62.9 59.6 60.1 62.4Z" fill="#00C0FA"/>
      <rect x="37.1518" y="39.414" width="16.1998" height="16.1998" transform="rotate(45 37.1518 39.414)" fill="#00C0FA"/>
      </svg>
    `
  }

  downloadIcon () {
    return '<svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-4"> <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" /> </svg>'
  }
}
