import { Controller } from '@hotwired/stimulus'

export default class FlyoutController extends Controller {
  static targets = ['flyoutWrapper', 'flyoutInner', 'form']
  static values = {
    name: String,
    open: { type: Boolean, default: false },
    addToUrl: { type: Boolean, default: false }
  }

  connect () {
    if (this.openValue) {
      this.visible = true
      this.render()
    }
  }

  openFlyout (evt) {
    // so we open the right flyout if there are more on the page
    if (evt.detail && evt.detail.flyoutId === this.nameValue) {
      this.visible = true
      this.render()
      if (this.addToUrlValue) {
        const url = new URL(window.location.href)
        url.searchParams.set('flyout', this.nameValue)
        window.history.pushState({}, '', url)
      }
    }
  }

  resetFlyout () {
    if (this.hasFormTarget) {
      this.formTarget.reset()
    }
    this.closeFlyout()
  }

  closeWithEsc (evt) {
    if (evt.key === 'Escape') this.closeFlyout()
  }

  closeFlyout () {
    // dispatch an event that this flyout is closing in case specific
    // controllers need to perform post cleanup
    const closingEvent = new CustomEvent(`closed-flyout-${this.nameValue}`)
    window.dispatchEvent(closingEvent)

    this.visible = false
    this.render()

    if (this.addToUrlValue) {
      const url = new URL(window.location.href)
      url.searchParams.delete('flyout')
      window.history.pushState({}, '', url)
    }
  }

  closeExternalFlyout (evt) {
    // prevents the flyout from closing if the user clicks from within the flyout then outside,
    // but will still allow outside clicks to close theflyout
    if (evt.target.closest('div.flyout-parent')) {
      if (evt.type === 'mousedown' || evt.type === 'click') {
        return
      }
    }

    this.closeFlyout()
  }

  render () {
    const visibleClasses = ['hidden']
    if (this.visible) {
      this.flyoutWrapperTarget.classList.remove(...visibleClasses)
    } else {
      this.flyoutWrapperTarget.classList.add(...visibleClasses)
    }
  }
}
