import BaseGraph from './base_graph_controller'
import { card, primaryValue } from '../helpers/tooltip_helper'

export default class ReadyToDeployColumnChartsController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    const borderRadius = 2
    const maxIndex = controller.parsedGraphData.data.length - 1

    let total = 0
    const seriesData = controller.parsedGraphData.data.map((data, i) => {
      if (data[1] === null) {
        data[1] = 0
      }
      total += data[1]
      if (i === maxIndex) {
        return {
          x: data[0],
          y: data[1],
          color: {
            pattern: {
              path: 'M-1,1 l2,-2 M0,4 l4,-4 M3,5 l2,-2',
              width: 4,
              height: 4,
              color: this.colorTheme.primary
            }
          }
        }
      } else {
        return {
          x: data[0],
          y: data[1]
        }
      }
    })

    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    const series = [
      {
        name: controller.optionsValue.name,
        data: seriesData,
        zIndex: 1,
        borderRadiusTopLeft: borderRadius,
        borderRadiusTopRight: borderRadius,
        minPointLength: 0,
        color: controller.tailwindColors.blue[500],
        marker: { enabled: false }
      }
    ]

    // if the total is 0, then add it to the zeroes series for min point height
    for (let i = 0; i < seriesData.length; i++) {
      if (seriesData[i].y === 0) {
        if (series.length === 1) {
          // add the zeroes series for min point height
          this.addNoDataSeriesToSeries(series)
        }
        series[1].data.push({
          x: series[0].data[i].x,
          y: 0
        })
      }
    }

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        title: { text: null },
        tickLength: 0,
        type: 'datetime',
        labels: { enabled: false },
        gridLineColor: 'transparent',
        lineWidth: 0,
        maxPadding: 0
      },
      yAxis: {
        title: { text: null },
        lineWidth: 0,
        gridLineWidth: 0,
        labels: {
          enabled: false
        },
        ...maxSettings
      },
      plotOptions: {
        column: {
          crisp: true,
          pointPadding: 0.1,
          groupPadding: 0,
          borderWidth: 1,
          borderColor: 'transparent',
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          stacking: 'normal'
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        formatter: function () {
          const startOfWeek = new Date(this.key)
          const endOfWeek = startOfWeek.getTime() + (24 * 60 * 60 * 1000 * 6)
          const date = `${Highcharts.dateFormat('%b %e', startOfWeek)} - ${Highcharts.dateFormat('%b %e', endOfWeek)}`

          return card({
            date: date,
            incomplete: this.point.index === maxIndex,
            primaryValues: [
              primaryValue({ name: controller.optionsValue.tooltip_title, value: `${controller.getNumericDisplay(this.y)} ${controller.optionsValue.tooltip_suffix}` })
            ]
          })
        },
        style: {
          zIndex: 100
        }
      },
      series: series
    }
  }
}
