/**
 * This is used to track events in the application.
 *
 * @param {string} event - The name of the event to track.
 * @param {string} [params] - Optional parameters to pass to the event.
 */
export function trackPageViewEvent (event, params = {}) {
  fetch('/events', {
    method: 'POST',
    headers: {
      'x-csrf-token': document
        .querySelector("meta[name='csrf-token']")
        .getAttribute('content'),
      'content-type': 'application/json'
    },
    body: JSON.stringify({ event, ...params })
  })
}
