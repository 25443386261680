import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="email-field-replicator"
export default class extends Controller {
  static targets = ['emailField']

  addEmailField () {
    const count = this.emailFieldTargets.length
    const newEmailField = this.emailFieldTargets[0].cloneNode(true)
    newEmailField.value = ''
    newEmailField.id = `email_${count + 1}`
    newEmailField.required = false
    this.emailFieldTargets[0].parentNode.appendChild(newEmailField)
  }
}
