import { Controller } from '@hotwired/stimulus'
import { FetchRequest } from '@rails/request.js'

export default class AnnotationsController extends Controller {
  static values = {
    saves: Array,
    newAnnotationPath: String
  }

  static targets = [
    'viewBlock',
    'editRow',
    'newAnnotationForm',
    'plusIcon'
  ]

  connect () {
    this.initialized = false
    this.lastXValue = null
    this.buttonHoverHistory = this.circularArray(2)
    this.popoverElementHistory = this.circularArray(2)
    this.popOverLocked = false
    this.editTemplate = document.importNode(this.viewBlockTarget, true)
    this.editRowTemplate = document.importNode(this.editRowTarget, true)
    this.newAnnotationFormTemplate = document.importNode(this.newAnnotationFormTarget, true)
    this.buttonMap = {}
  }

  async formSubmit (e) {
    e.preventDefault()
    if (this.submitting) return

    this.submitting = true
    const formData = new FormData(e.target)
    const request = new FetchRequest(
      e.target.getAttribute('method'),
      e.target.getAttribute('action'),
      {
        body: formData
      }
    )
    const response = await request.perform()
    if (response.ok) {
      const body = await response.json
      this.submitting = false
      this.savesValue = body.annotations
      this.groupAnnotations()
      this.drawAnnotationButtons()
      this.buttonMap[this.lastXValue].classList.add('!border-green-500', '!text-green-500', 'animate-[pulse_0.5s_2]')
      this.cancelFixedPopover()
      this.renderPopOver()
      if (this.buttonMap[this.lastXValue].innerText !== '+') {
        this.showPreviewAnnotation(null, this.lastXValue)
        this.editAnnotation()
      }
      setTimeout(() => {
        this.buttonMap[this.lastXValue].classList.remove('!border-green-500', '!text-green-500', 'animate-[pulse_0.5s_2]')
      }, 600)
    }
  }

  /**
   * Captures pixel values and refs from chart controller.
   *
   * @param {object} chartData
   * @param {object} chartData.chart Highcharts chart instance
   * @param {object} chartData.chartBBox chart bounding box
   * @param {array} chartData.xPoints list of x axis categories with pixel values
   * @memberof AnnotationsController
   */
  setChartData (chartData) {
    this.chartData = chartData
    this.groupAnnotations()
    this.drawAnnotationButtons()
  }

  /**
   * Draws annotation buttons from the given data
   *
   * @memberof AnnotationsController
   */
  drawAnnotationButtons () {
    const uiWrapper = this.element.querySelector('[name="annotationWrapper"]')
    uiWrapper.innerHTML = ''
    this.buttonMap = {}
    this.chartData.xPoints.forEach(({ value, pixels }) => {
      let node
      if (this.formattedAnnotations[value]) {
        node = this.buildActiveAnnotationButton(value)
        node.innerHTML = this.formattedAnnotations[value].length
        node.dataset.id = this.formattedAnnotations[value].id
      } else {
        node = this.buildEmptyAnnotationButton(value)
      }
      node.style.left = `${pixels - 12}px`
      node.dataset.left = pixels - 12
      uiWrapper.appendChild(node)
      this.buttonMap[value] = node
    })
    this.initialized = true
  }

  /**
   * Groups annotations by category (date timestamp)
   *
   * @memberof AnnotationsController
   */
  groupAnnotations () {
    if (this.savesValue.length) {
      let shouldBucketDates = false
      const chartXValues = this.chartData.xPoints.map(p => p.value)
      this.formattedAnnotations = this.savesValue.reduce((map, annotation) => {
        // if the annotation is not on a chart x point, we need to bucket them by date
        if (!chartXValues.includes(parseInt(annotation.point_x, 10)) && shouldBucketDates === false) {
          shouldBucketDates = true
        }
        if (!map[annotation.point_x]) map[annotation.point_x] = []
        map[annotation.point_x].push(annotation)
        return map
      }, {})
      if (shouldBucketDates) this.bucketDates()
    } else {
      this.formattedAnnotations = {}
    }
  }

  /**
   * Buckets annotations by date, so that annotations that are not on a chart x point are still shown
   *
   * @memberof AnnotationsController
   */
  bucketDates () {
    const chartXValues = this.chartData.xPoints.map(p => p.value)
    const newAnnotations = {}
    // Find the closest x point to the annotation date, and group them together
    Object.entries(this.formattedAnnotations).forEach(([key, value]) => {
      key = parseInt(key, 10)
      if (isNaN(key)) return
      const closestDate = chartXValues.reduce((prev, curr) => {
        return (Math.abs(curr - key) < Math.abs(prev - key) ? curr : prev)
      })
      if (!newAnnotations[closestDate]) newAnnotations[closestDate] = []
      newAnnotations[closestDate] = [...newAnnotations[closestDate], ...value]
    })
    this.formattedAnnotations = newAnnotations
  }

  /**
   * Renders a form to create a new annotation based on data from event
   *
   * @param {Event} event polymorphic event, sent by chart, or button click
   * @memberof AnnotationsController
   */
  showNewAnnotationForm (event) {
    let xVal
    if (event.xAxis) {
      // from click on chart bg
      const actualClickValue = event.xAxis[0].value
      // this mess is because bg chart clicks return the actual value, and not the nearest x point, so we go find the closest one...
      xVal = parseInt(this.chartData.xPoints.map(xp => xp.value).reduce((prev, curr) => {
        return (Math.abs(curr - actualClickValue) < Math.abs(prev - actualClickValue) ? curr : prev)
      }), 10)
    } else if (event.point) {
      // from click on chart point
      xVal = parseInt(event.point.x, 10)
    } else {
      // from click on button
      xVal = parseInt(event.target.dataset.value, 10)
    }
    this.lastXValue = xVal
    if (event.target.dataset.override) this.popOverLocked = false
    this.popoverElementHistory.push(this.buildNewAnnotationForm(event, xVal))
    this.popOverLocked = true
    this.chartData.chart.tooltip.update({ enabled: false })
    this.renderPopOver()
    this.element.querySelector('[name="body"]')?.focus()
  }

  /**
   * Shows a compact preview of a categories annotations
   * Triggered on hover
   *
   * @param {Event} event mouseover event
   * @memberof AnnotationsController
   */
  showPreviewAnnotation (event, xVal) {
    const value = event?.target?.dataset?.value || xVal
    const previewElement = this.buildEditBlock(parseInt(value, 10))
    previewElement.style.left = this.makePopOverPosition(event, value)
    this.lastXValue = parseInt(value, 10)
    this.chartData.chart.tooltip.hide(0)
    this.popoverElementHistory.push(previewElement)
    this.renderPopOver()
  }

  /**
   * Expands the open annotation to show the full text, as well as action buttons
   *
   * @memberof AnnotationsController
   */
  editAnnotation () {
    this.popOverLocked = true
    this.chartData.chart.tooltip.update({ enabled: false })
    if (this.popoverElementHistory[1]) {
      this.popoverElementHistory[1]?.querySelector('[name="actionBar"]')?.classList.remove('hidden')
      for (const commentWrapper of this.popoverElementHistory[1]?.querySelectorAll('[name="bodyField"]')) {
        commentWrapper.classList.remove(...'overflow-hidden text-ellipsis whitespace-nowrap'.split(' '))
      }
      for (const editButtons of this.popoverElementHistory[1]?.querySelectorAll('.jsEditButtons')) {
        editButtons?.classList.remove('hidden')
      }
      for (const avatar of this.popoverElementHistory[1]?.querySelectorAll('[name="avatar"]')) {
        avatar?.classList.add('hidden')
      }
      for (const wrappers of this.popoverElementHistory[1]?.querySelectorAll('.col-span-10')) {
        wrappers.classList.remove('col-span-10')
        wrappers.classList.add('col-span-12')
      }
    }
  }

  /**
   * Shows edit form for a single annotation row within the preview popover
   * Locks the popover to block others from opening
   *
   * @param {Event} event click event
   * @memberof AnnotationsController
   */
  editSingleAnnotation (event) {
    const annotationElement = event.target.closest('.jsAnnotationDetail')
    annotationElement.querySelector('[name="bodyField"]').classList.add('hidden')
    const editForm = annotationElement.querySelector('[name="annotationUpdateForm"]')
    editForm.classList.remove('hidden')
    editForm.querySelector('textarea')?.focus()

    const actionBar = this.element.querySelector('[name="popOverWrapper"]').querySelector('[name="actionBar"]')
    actionBar?.classList.replace('flex', 'hidden')
  }

  /**
   * Removes the popover lock and closes it
   *
   * @memberof AnnotationsController
   */
  cancelFixedPopover () {
    this.popOverLocked = false
    this.chartData.chart.tooltip.update({ enabled: true })
    this.closePopOver()
  }

  /**
   * Removes any open popover
   *
   * @memberof AnnotationsController
   */
  closePopOver () {
    this.popoverElementHistory.push(null)
    this.renderPopOver()
  }

  /**
   * Renders a popover based on the state of popoverElementHistory
   *
   * @memberof AnnotationsController
   */
  renderPopOver () {
    this.popoverElementHistory[0]?.remove()
    if (this.popoverElementHistory[1]) {
      this.element.querySelector('[name="popOverWrapper"]').appendChild(this.popoverElementHistory[1])
      setTimeout(() => {
        this.popoverElementHistory[1]?.classList.remove('opacity-0', 'translate-y-4')
      }, 10)
    }
  }

  /**
   * Triggers a vertical line on the chart
   *
   * @param {Event} event mouseover event
   * @memberof AnnotationsController
   */
  drawCrosshair (event) {
    const points = this.chartData.chart.series[0].points
    const xAxis = this.chartData.chart.xAxis[0]
    points.forEach((p) => {
      if (parseInt(event.target.dataset.value, 10) === p.category) {
        xAxis.drawCrosshair({}, p)
      }
    })
  }

  /**
   * Removes the programmatically set crosshair from the chart
   *
   * @param {Event} event mouseover event
   * @memberof AnnotationsController
   */
  clearCrosshair (event) {
    this.chartData.chart.xAxis[0].hideCrosshair()
  }

  /**
   * Calculates the position of the popover, so that it doesn't extend beyond the chart edges
   *
   * @param {Event} event click or mouseover event
   * @param {Integer} xVal x-axis category value
   * @return {String} css px value for the left edge of the popover
   * @memberof AnnotationsController
   */
  makePopOverPosition (event, xVal) {
    const leftPx = event?.target?.dataset?.left || this.chartData.chart.series[0].xAxis.toPixels(xVal)
    const { x: leftEdge, width } = this.chartData.chartBBox
    const rightEdge = leftEdge + width - 384
    const left = leftPx - 180
    if (left < leftEdge) return `${leftEdge}px`
    if (left > rightEdge) return `${rightEdge}px`
    return `${left}px`
  }

  /**
   * Constructs and populates DOM nodes for the new annotation form from a <template> element
   *
   * @param {Event} event click or mouseover event
   * @param {Integer} xVal x-axis category value
   * @return {Node} DOM node
   * @memberof AnnotationsController
   */
  buildNewAnnotationForm (event, xVal) {
    const newItemElement = this.newAnnotationFormTemplate.content.firstElementChild.cloneNode(true)
    newItemElement.querySelector('[name="addAnnotationDateField"]').innerHTML = Highcharts.dateFormat('%b %e', xVal)
    newItemElement.querySelector('[name="point_x"]').value = xVal
    newItemElement.style.left = this.makePopOverPosition(event, xVal)
    return newItemElement
  }

  /**
   * Constructs and populates DOM nodes for the annotation preview from a <template> element
   *
   * @param {*} id
   * @return {Node} DOM node
   * @memberof AnnotationsController
   */
  buildEditBlock (id) {
    const annotations = this.formattedAnnotations[id]
    if (!annotations.length) return

    const editTemplate = this.editTemplate.content.firstElementChild.cloneNode(true)
    editTemplate.querySelector('[name="titleField"]').innerHTML = Highcharts.dateFormat('%b %e', id)
    editTemplate.querySelector('[name="countField"]').innerHTML = `${annotations.length} annotation${annotations.length > 1 ? 's' : ''}`
    editTemplate.querySelector('[data-action="click->annotations#showNewAnnotationForm"]').dataset.value = id
    annotations.forEach((annotation) => {
      const editRowTemplate = this.editRowTemplate.content.firstElementChild.cloneNode(true)
      editRowTemplate.querySelector('[name="nameField"]').innerHTML = annotation.user_name
      editRowTemplate.querySelector('[name="editDateField"]').innerHTML = `${annotation.time_ago} ago`
      editRowTemplate.querySelector('[name="bodyField"]').innerHTML = this.sanitizeHtml(annotation.body)
      editRowTemplate.querySelector('[name="avatar"]').innerHTML = annotation.user_name.slice(0, 2)
      const updateForm = editRowTemplate.querySelector('[name="annotationUpdateForm"]')
      updateForm.action = updateForm.action.replace(':id', annotation.id)
      editRowTemplate.querySelector('[name="body"]').value = annotation.body
      const deleteForm = editRowTemplate.querySelector('[name="annotationDeleteForm"]')
      deleteForm.action = deleteForm.action.replace(':id', annotation.id)
      editTemplate.querySelector('[name="rowWrapper"]').appendChild(editRowTemplate)
    })
    return editTemplate
  }

  /**
   * Constructs and populates DOM nodes for a basic annotation button
   *
   * @param {Integer} value x-axis category value
   * @return {Node} DOM node
   * @memberof AnnotationsController
   */
  buildAnnotationButton (value) {
    const baseAnnotationClasses = 'absolute bg-gray-100 dark:bg-gray-800 hover:shadow-md flex items-center justify-center border border-solid transition-all duration-200 font-semibold w-6 h-6 rounded-md cursor-pointer'
    const node = document.createElement('div')
    node.classList.add(...baseAnnotationClasses.split(' '))
    node.dataset.value = value
    return node
  }

  /**
   * Constructs and populates DOM nodes for an active annotation button
   *
   * @param {Integer} value x-axis category value
   * @return {Node} DOM node
   * @memberof AnnotationsController
   */
  buildActiveAnnotationButton (value) {
    const node = this.buildAnnotationButton(value)
    const classes = 'hover:bg-blue-500 hover:z-10 hover:text-white text-gray-400 border-gray-400 hover:border-blue-500 text-xs'
    node.classList.add(...classes.split(' '))
    node.dataset.action = [
      'click->annotations#editAnnotation',
      'mouseover->annotations#showPreviewAnnotation',
      'mouseout->annotations#closePopOver',
      'mouseover->annotations#drawCrosshair',
      'mouseout->annotations#clearCrosshair'
    ].join(' ')
    node.title = 'Click to view and edit annotations'
    return node
  }

  /**
   * Constructs and populates DOM nodes for an empty annotation button
   *
   * @param {Integer} value x-axis category value
   * @return {Node} DOM node
   * @memberof AnnotationsController
   */
  buildEmptyAnnotationButton (value) {
    const node = this.buildAnnotationButton(value)
    const plusIcon = this.plusIconTarget.content.firstElementChild.cloneNode(true)
    node.appendChild(plusIcon)
    node.title = `New annotation for ${Highcharts.dateFormat('%b %e', value)}`
    const classes = 'hover:opacity-100 hover:bg-blue-500 hover:border-blue-500 hover:text-white opacity-0 text-blue-500 border-blue-500'
    node.classList.add(...classes.split(' '))
    node.dataset.action = [
      'click->annotations#showNewAnnotationForm',
      'mouseover->annotations#drawCrosshair',
      'mouseout->annotations#clearCrosshair'
    ].join(' ')
    return node
  }

  /**
   * handles mouseout events from the chart
   * used to trigger hover states on the buttons from within the chart
   *
   * @memberof AnnotationsController
   */
  categoryMouseOut () {
    this.buttonHoverHistory.push(null)
    this.renderButtonHoverState()
  }

  /**
   * handles mouseover events from the chart
   * used to trigger hover states on the buttons from within the chart
   *
   * @param {Integer} xValue x-axis category value
   * @memberof AnnotationsController
   */
  categoryMouseOver (xValue) {
    this.buttonHoverHistory.push(xValue)
    this.renderButtonHoverState()
  }

  /**
   * Renders the annotation buttons' hover states based on buttonHoverHistory
   *
   * @memberof AnnotationsController
   */
  renderButtonHoverState () {
    const hoverClasses = 'opacity-100 !border-blue-500 !text-blue-500 z-20 shadow-md'
    const [previous, current] = this.buttonHoverHistory
    if (previous && this.buttonMap[previous]) {
      this.buttonMap[previous].classList.remove(...hoverClasses.split(' '))
    }
    if (current && this.buttonMap[current]) {
      this.buttonMap[current].classList.add(...hoverClasses.split(' '))
    }
  }

  sanitizeHtml (htmlStr) {
    return (htmlStr + '')
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
  }

  /**
   * Creates a 2 element fixed length FIFO array with a lock on pushing when the popover is open
   * Used for tracking state in the popovers and button hovers
   *
   * @param {Integer} length number of elements to create
   * @return {Array} Fixed length circular array
   * @memberof AnnotationsController
   */
  circularArray (length) {
    const array = [null, null]
    const controller = this
    array.push = function () {
      if (!controller.popOverLocked) {
        if (this.length >= length) {
          this.shift()
        }
        return Array.prototype.push.apply(this, arguments)
      }
    }
    return array
  }
}
