import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static debounces = ['submit']

  static targets = ['searchBox', 'submittingSearch']

  connect () {
    this.timeoutHandler = null
    useDebounce(this, { wait: 900 })
    if (this.getParams().search) {
      this.searchBoxTarget.focus()
      setTimeout(() => {
        this.searchBoxTarget.selectionStart = this.searchBoxTarget.selectionEnd = 10000
      }, 0)
    }
    // A listener to submit the form when the enter key is pressed,
    // also capturing any characters entered before the enter key is pressed.
    this.keypressListener = this.keypressListener.bind(this)
    document.addEventListener(
      'keypress',
      this.keypressListener
    )
  }

  keypressListener (e) {
    if (e.keyCode === 13) {
      // 13 is the enter key
      e.preventDefault()
      this.submit(e)
    }
  }

  submit (e) {
    e.preventDefault()

    if (!this.timeoutHandler) {
      this.submittingSearchTarget.classList.remove('hidden')
      this.submittingSearchTarget.classList.add('flex', 'items-center')
    }

    setTimeout(() => {
      if (this.timeoutHandler) {
        // start the timer again as another character as been entered
        clearTimeout(this.timeoutHandler)
      }

      this.timeoutHandler = setTimeout(() => {
        this.setParam(this.searchBoxTarget.value)
        this.element.requestSubmit()
        this.submitting = null
      }, 250)
    }, 0)
  }

  setParam (searchTerm) {
    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search)
      searchParams.set('search', searchTerm)
      const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString()
      history.pushState(null, '', newRelativePathQuery)
    }
  }

  getParams () {
    const paramsArray = window.location.search.substring(1).split('&')
    const params = {}
    for (let i = 0; i < paramsArray.length; ++i) {
      const param = paramsArray[i].split('=', 2)
      if (param.length !== 2) continue
      params[param[0]] = decodeURIComponent(param[1].replace(/\+/g, ' '))
    }
    return params
  }
}
