import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'
export default class PortfolioFiltersFormController extends Controller {
  static targets = ['form']
  static debounces = [{
    name: 'debouncedSubmitForm',
    wait: 500
  }]

  connect () {
    useDebounce(this)
  }

  submitForm () {
    this.formTarget.requestSubmit()
  }

  debouncedSubmitForm () {
    this.submitForm()
  }

  portfolioSelected (event) {
    // find the last value (portfolio org id) after the / in the form url and replace it with the new id
    const url = new URL(this.formTarget.action)
    const path = url.pathname.split('/')
    path[path.length - 1] = event.target.value
    url.pathname = path.join('/')
    this.formTarget.action = url.toString()

    // submit the form
    this.submitForm()
  }
}
