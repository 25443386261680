import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static debounces = [
    {
      name: 'submitSearchForm',
      wait: 500
    }
  ]

  connect () {
    useDebounce(this)
  }

  submitSearchForm () {
    this.element.requestSubmit()
  }

  updateRole (e) {
    e.preventDefault()
    e.target.form.requestSubmit()
  }
}
