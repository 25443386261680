import BaseGraph from './base_graph_controller'
import { dateToHoursMinutesDisplay } from '../helpers/highcharts_helper'
import { breakDownValue, card } from '../helpers/tooltip_helper'

export default class ActiveCodeTimeGraph extends BaseGraph {
  pointFormatter () {
    const tooltipValue = this.tooltip_y_value || this.y

    const date = new Date(0, 0)
    date.setSeconds(tooltipValue * 60 * 60)
    const displayValue = dateToHoursMinutesDisplay(date)

    return `
      <tr>
        <td style="color:black;padding:4px;">${this.series.name}: </td>
        <td style="padding:4px;"><b>${displayValue}</b></td>
      </tr>
    `
  }

  prepareGraphSchema () {
    const controller = this

    let tickPositions
    if (this.parsedGraphData.code_time.length < 25) {
      tickPositions = this.parsedGraphData.code_time.map((d) => d.x)
    }

    return {
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 10,
        spacingBottom: 10
      },
      ...this.baseConfigOptions,
      xAxis: {
        tickPositions,
        type: 'datetime',
        lineColor: this.colorTheme.gridLine,
        tickLength: 0,
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%b %d, %Y', this.value)
          },
          style: {
            color: '#848C99'
          }
        }
      },
      yAxis: {
        min: 0,
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        title: {
          text: ''
        },
        labels: {
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            const hours = Math.floor(this.value / 60)
            const minutes = this.value % 60
            if (hours > 0 && minutes > 0) {
              return `${hours}h ${minutes}m`
            } else if (hours > 0) {
              return `${hours}h`
            } else {
              return `${minutes}m`
            }
          }
        }
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          pointPadding: 0.1,
          groupPadding: 0.1
        }
      },
      series: [
        {
          name: 'Code Time',
          data: this.parsedGraphData.code_time,
          color: this.colorTheme.codeTime,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          borderColor: 'transparent'
        },
        {
          name: 'Active Code Time',
          data: this.parsedGraphData.active_code_time,
          color: this.colorTheme.activeCodeTime,
          borderColor: 'transparent'
        }
      ],
      legend: {
        align: 'left',
        verticalAlign: 'top',
        x: -10,
        y: -10,
        itemStyle: {
          color: this.colorTheme.legend
        },
        itemHoverStyle: {
          color: this.colorTheme.legend
        },
        itemHiddenStyle: {
          color: this.colorTheme.legendHidden
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        shared: true,
        formatter: function () {
          const breakDownValues = this.points.map((p) => {
            const value = parseFloat(p.point.tooltip_y_value) || p.y
            const date = new Date(0, 0)
            date.setSeconds(value * 60)
            return breakDownValue({
              name: p.series.name,
              value: dateToHoursMinutesDisplay(date),
              style: `background-color: ${p.color};`,
              type: 'box'
            })
          })

          return card({ date: Highcharts.dateFormat('%b %d, %Y', this.x), breakDownValues })
        }
      }
    }
  }
}
