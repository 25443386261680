import { Controller } from '@hotwired/stimulus'
import { useDebounce } from 'stimulus-use'

export default class DebouncedSearchController extends Controller {
  static targets = ['searchForm', 'searchInput']
  static debounces = [
    {
      name: 'submitForm',
      wait: 300
    }
  ]

  connect () {
    useDebounce(this)
    if (this.hasSearchInputTarget) {
      this.searchInputTarget.focus()
      this.searchInputTarget.setSelectionRange(this.searchInputTarget.value.length, this.searchInputTarget.value.length)
    }
  }

  submitForm (event) {
    event.preventDefault()
    this.searchFormTarget.requestSubmit()
  }

  submitFormImmediately (event) {
    event.preventDefault()
    this.searchFormTarget.requestSubmit()
  }
}
