import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'label']

  toggle () {
    this.formTarget.classList.toggle('hidden')
    this.labelTarget.classList.toggle('hidden')
  }
}
