import BaseGraph from './base_graph_controller'
import { dateToHoursMinutesDisplay } from '../helpers/highcharts_helper'
import { card, breakDownValue } from '../helpers/tooltip_helper'

export default class ActiveCodeTimeGraph extends BaseGraph {
  prepareGraphSchema () {
    const controller = this

    return {
      credits: {
        enabled: false
      },
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        events: {
          render () {
            controller.renderDashedJumpLines(this)
          }
        },
        spacingLeft: 0,
        spacingRight: 0,
        spacingTop: 10,
        spacingBottom: 10
      },
      ...this.baseConfigOptions,
      xAxis: {
        categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
        lineColor: this.colorTheme.gridLine,
        labels: {
          style: {
            color: '#848C99'
          }
        }
      },
      yAxis: {
        tickInterval: this.parsedGraphData.max_y > 120 ? 60 : 30,
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[700] : controller.tailwindColors.gray[200],
        title: { text: null },
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            const hours = Math.floor(this.value / 60)
            const minutes = this.value % 60
            if (hours > 0 && minutes > 0) {
              return `${hours}h ${minutes}m`
            } else if (hours > 0) {
              return `${hours}h`
            } else {
              return `${minutes}m`
            }
          }
        }
      },
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: false,
        shared: true,
        formatter: function () {
          const breakDownValues = this.points.reverse().map((p) => {
            const yValue = p.point.tooltip_y_value || p.y

            const color = p.color.pattern ? p.color.pattern.color : p.color
            const date = new Date(0, 0)
            date.setSeconds(yValue * 60)
            const displayValue = dateToHoursMinutesDisplay(date)
            return breakDownValue({
              name: p.series.name,
              value: displayValue,
              style: `background-color: ${color};`,
              type: 'box'
            })
          }).reverse()

          return card({
            date: this.key,
            breakDownValues
          })
        }
      },
      plotOptions: {
        series: {
          stacking: 'normal',
          pointPadding: 0.1,
          groupPadding: 0.1
        }
      },
      series: [
        {
          name: 'Code Time',
          data: this.parsedGraphData.code_time,
          color: this.colorTheme.codeTime,
          borderRadiusTopLeft: 5,
          borderRadiusTopRight: 5,
          borderColor: 'transparent'
        },
        {
          name: 'Active Code Time',
          data: this.parsedGraphData.active_code_time,
          color: this.colorTheme.activeCodeTime,
          borderColor: 'transparent'
        },
        {
          name: 'Flow Time',
          id: 'flowtime',
          type: 'solid-jump-line',
          data: this.parsedGraphData.flow_time.map((point) => {
            if (point > 0) {
              return point
            } else {
              return null
            }
          }),
          color: this.tailwindColors.indigo[500]
        },
        {
          type: 'line',
          width: 2,
          dashStyle: 'Dash',
          name: 'Global 90-day avg',
          id: 'global_90_day_avg',
          marker: { enabled: false },
          color: this.tailwindColors.teal[500],
          data: this.parsedGraphData.global_avg.map((point) => {
            if (point > 0) {
              return point
            } else {
              return null
            }
          })
        },
        {
          type: 'jump-line',
          grouping: false,
          name: 'Your 90-day avg',
          color: this.tailwindColors.yellow[500],
          data: this.parsedGraphData.avg_90_day.map((point) => {
            if (point > 0) {
              return point
            } else {
              return 0
            }
          })
        }
      ],
      legend: false
    }
  }
}
