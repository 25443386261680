import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['customDateFields']

  onTimeRangeSelected (event) {
    if (event.target.value === 'custom_range') {
      this.customDateFieldsTarget.classList.remove('hidden')
    } else {
      event.target.closest('form').submit()
    }
  }
}
