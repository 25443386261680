import { Application } from '@hotwired/stimulus'

const application = Application.start()

// Configure Stimulus development experience
application.warnings = true
application.debug = false // Use `Stimulus.debug = true` in the browser console to enable
window.Stimulus = application

export { application }
