import BaseGraph from './base_graph_controller'
import { card, primaryValue, breakDownValue } from '../helpers/tooltip_helper'

export default class ChangesDeliveredGraphController extends BaseGraph {
  prepareGraphSchema () {
    const controller = this
    const emptySeriesData = []

    // index data by x value
    const types = ['new', 'churn', 'refactor', 'other']
    const indexData = { new: {}, churn: {}, refactor: {}, other: {} }
    const seriesData = { new: [], churn: [], refactor: [], other: [] }

    let total = 0
    const seriesLength = controller.parsedGraphData.new.length
    const categories = []
    for (let i = 0; i < seriesLength; i++) {
      const seriesTotal = controller.parsedGraphData.new[i].y + controller.parsedGraphData.churn[i].y + controller.parsedGraphData.refactor[i].y + controller.parsedGraphData.other[i].y
      categories.push(controller.parsedGraphData.new[i].x)
      if (seriesTotal === 0) {
        emptySeriesData.push({
          x: controller.parsedGraphData.new[i].x,
          y: 0,
          percent: 100
        })
      }
      total += seriesTotal
    }

    types.forEach((type) => {
      controller.parsedGraphData[type].forEach((datapoint) => {
        const formattedDate = Highcharts.dateFormat('%b \'%y', datapoint.x)
        indexData[type][formattedDate] = datapoint
        seriesData[type].push(indexData[type][formattedDate])
      })
    })

    const series = [
      {
        name: 'Features',
        data: seriesData.new,
        color: this.categoricalColors[1]
      },
      {
        name: 'Churn',
        data: seriesData.churn,
        color: this.categoricalColors[2]
      },
      {
        name: 'Refactor',
        data: seriesData.refactor,
        color: this.categoricalColors[3]
      },
      {
        name: 'Other',
        data: seriesData.other,
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
        color: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[300]
      }
    ]

    this.addNoDataSeriesToSeries(series, emptySeriesData)
    const maxSettings = this.getYAxisMaxAndTickAmountSettings(total)

    return {
      chart: {
        type: 'column',
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          overflow: 'visible'
        },
        spacingTop: 10,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0
      },
      ...this.baseConfigOptions,
      xAxis: {
        title: { text: null },
        tickLength: 0,
        tickPositions: categories,
        type: 'datetime',
        labels: {
          autoRotation: false,
          step: seriesLength > 6 ? 3 : 1,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            return Highcharts.dateFormat('%b \'%y', this.value)
          }
        },
        lineColor: controller.darkMode
          ? controller.tailwindColors.gray[600]
          : controller.tailwindColors.gray[300],
        maxPadding: 0
      },
      yAxis: {
        gridLineColor: controller.darkMode ? controller.tailwindColors.gray[600] : controller.tailwindColors.gray[200],
        title: { text: null },
        labels: {
          enabled: true,
          style: {
            color: controller.darkMode
              ? controller.tailwindColors.gray[400]
              : controller.tailwindColors.gray[500]
          },
          formatter: function () {
            if (this.value !== 0 && total === 0) {
              return '--'
            } else {
              return `${this.value}`
            }
          }
        },
        reversedStacks: false,
        ...maxSettings
      },
      plotOptions: {
        column: {
          crisp: false,
          pointPadding: 0.04,
          groupPadding: 0,
          borderWidth: 2,
          borderColor: controller.darkMode ? controller.tailwindColors.gray[800] : controller.tailwindColors.white
        },
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: false
          },
          marker: {
            enabled: false
          },
          stacking: 'normal',
          cursor: 'pointer',
          stickyTracking: false,
          point: {
            events: {
              click: function () {
                if (controller.optionsValue.metrics_url) {
                  controller.openMetricDrilldown(new Date(series[0].data[this.index].x).toISOString().split('T')[0], this.total)
                }
              }
            }
          }
        }
      },
      series,
      tooltip: {
        ...this.baseToolTipConfig,
        stickOnContact: true,
        shared: true,
        formatter: function () {
          const total = this.points[0].total
          const breakDownValues = this.points.reverse().map((p) => {
            return breakDownValue({
              name: p.series.name,
              value: `${Math.round(p.y)} <span class="text-gray-600 dark:text-gray-400">(${p.point.percent}%)</span>`,
              style: `background-color: ${p.color};`,
              type: 'box'
            })
          }).reverse()

          return card({
            date: Highcharts.dateFormat('%b \'%y', this.x),
            footer: controller.optionsValue.metrics_url && `<button data-value="${this.x}" data-y-value="${total}" data-action="click->changes-delivered-graph#openFlyout" class="btn-tertiary text-xs mt-2 w-full">View pull requests</button>`,
            primaryValues: [
              primaryValue({ name: 'Pull requests', value: total })
            ],
            breakDownValues
          })
        }
      }
    }
  }
}
