import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="modern-text-input-field"
export default class extends Controller {
  static values = {
    autoFocus: { type: Boolean, default: false }
  }

  connect () {
    if (this.autoFocusValue) {
      this.element.focus()
    }
  }

  focusInputField () {
    // select all the text within the input box
    this.element.select()
  }
}
