import { Controller } from '@hotwired/stimulus'

export default class PortfolioOrganizationSearchController extends Controller {
  static values = {
    items: Array
  }

  static targets = ['list', 'search']

  onArrowDown (event) {
    event.preventDefault()

    if (this.resultIndex > -1) {
      document.getElementById(`dropdown_port_org_${this.results[this.resultIndex]}`).classList.remove('bg-gray-100')
    }

    if (this.resultIndex < this.results.length - 1) {
      this.resultIndex += 1
    }

    document.getElementById(`dropdown_port_org_${this.results[this.resultIndex]}`).classList.add('bg-gray-100')
  }

  onArrowUp (event) {
    event.preventDefault()

    document.getElementById(`dropdown_port_org_${this.results[this.resultIndex]}`).classList.remove('bg-gray-100')

    if (this.resultIndex > 0) {
      this.resultIndex -= 1
    }

    document.getElementById(`dropdown_port_org_${this.results[this.resultIndex]}`).classList.add('bg-gray-100')
  }

  onEnter (event) {
    event.preventDefault()

    if (this.resultIndex > -1) {
      document.getElementById(`dropdown_port_org_${this.results[this.resultIndex]}`).click()
    }
  }

  search (event) {
    this.results = []
    this.resultIndex = -1

    if (event.target.value.length) {
      this.listTarget.classList.remove('hidden')
      const query = event.target.value

      this.results = this.itemsValue.filter(item => {
        return item.display_name.toLowerCase().includes(query.toLowerCase())
      }).map(item => { return String(item.id) })

      for (let i = 0; i < this.listTarget.children.length; i++) {
        if (this.results.includes(this.listTarget.children[i].dataset.itemId)) {
          this.listTarget.children[i].classList.remove('hidden')
        } else {
          this.listTarget.children[i].classList.add('hidden')
        }
      }
    } else {
      for (let i = 0; i < this.listTarget.children.length; i++) {
        this.listTarget.children[i].classList.remove('bg-gray-100')
      }

      this.listTarget.classList.add('hidden')
    }
  }

  onItemClick (event) {
    this.searchTarget.value = ''
    this.listTarget.classList.add('hidden')
    document.getElementById(`portfolio_organization_${event.currentTarget.dataset.itemId}`)?.scrollIntoView()
    for (let i = 0; i < this.listTarget.children.length; i++) {
      this.listTarget.children[i].classList.remove('bg-gray-100')
    }
  }
}
